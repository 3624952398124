import { Pipe, PipeTransform } from '@angular/core';
import { AllamiJegy } from 'api';


@Pipe({
  name: 'allamiJegyStatusz'
})
export class AllamiJegyStatuszPipe implements PipeTransform {
  transform(value: AllamiJegy.StatuszEnum, args?: any): any {
    switch (value) {
      case AllamiJegy.StatuszEnum.ERVENYES: return 'Érvényes';
      case AllamiJegy.StatuszEnum.LEJART: return 'Lejárt';
      case AllamiJegy.StatuszEnum.VEGLEGESENSTORNOZOTT: return 'Véglegesen sztornózott';
      case AllamiJegy.StatuszEnum.ERVENYESSZTORNOJOVAHAGYASRAVAR: return 'Sztornó jóváhagyásra vár';
      case AllamiJegy.StatuszEnum.ELVESZETT: return 'Elveszett';
      case AllamiJegy.StatuszEnum.ERVENYESELVESZETTJOVAHAGYASRAVAR: return 'Elveszett, jóváhagyásra vár';
      case AllamiJegy.StatuszEnum.ERVENYESMEGSEMMISULTJOVAHAGYASRAVAR: return 'Megsemmisült, jóváhagyásra vár';
      case AllamiJegy.StatuszEnum.MEGSEMMISULT: return 'Megsemmisült';
      case AllamiJegy.StatuszEnum.VISSZAVONT: return 'Visszavont';
      case AllamiJegy.StatuszEnum.LEJARTELVESZETTJOVAHAGYASRAVAR: return 'Lejárt, elveszett, jóváhagyásra vár';
      case AllamiJegy.StatuszEnum.LEJARTMEGSEMMISULTJOVAHAGYASRAVAR: return 'Lejárt, megsemmisült, jóváhagyásra vár';
      case AllamiJegy.StatuszEnum.UJRAFELHASZNALHATOSZTORNOZOTT: return 'Újrafelhasználható sztornózott';
      case AllamiJegy.StatuszEnum.LEJARTLEADOTTFN: return 'Lejárt (leadott fogási napló)';
      default: return value;
    }
  }
}
