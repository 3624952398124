import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { VedettVizteruletPageRoutingModule } from './vedett-vizterulet-routing.module';
import { VedettVizteruletPage } from './vedett-vizterulet-page.component';
import { CoreModule } from 'app/core/core.module';
import { SharedModule } from 'app/shared/shared.module';
import {ScrollingModule} from '@angular/cdk/scrolling';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CoreModule,
    SharedModule,
    VedettVizteruletPageRoutingModule,
    ScrollingModule,
  ],
  declarations: [
    VedettVizteruletPage
  ]
})
export class VedettVizteruletPageModule {}
