/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FizetesiKonstrukcioNapidijBefizetes } from './fizetesiKonstrukcioNapidijBefizetes';

export interface FizetesiKonstrukcio { 
    aktivaciosDijBefizetesDatuma?: Date;
    aktualisAr?: number;
    allapot?: FizetesiKonstrukcio.AllapotEnum;
    napidijBefizetesList?: Array<FizetesiKonstrukcioNapidijBefizetes>;
    napidijErvenyessegVege?: string;
    tipus?: FizetesiKonstrukcio.TipusEnum;
}
export namespace FizetesiKonstrukcio {
    export type AllapotEnum = 'BEALLITVA' | 'FIZETES_ALATT' | 'FIZETES_KESZ';
    export const AllapotEnum = {
        BEALLITVA: 'BEALLITVA' as AllapotEnum,
        FIZETESALATT: 'FIZETES_ALATT' as AllapotEnum,
        FIZETESKESZ: 'FIZETES_KESZ' as AllapotEnum
    };
    export type TipusEnum = 'AKTIVACIOS' | 'NAPIDIJAS' | 'RENDSZERHASZNALATI';
    export const TipusEnum = {
        AKTIVACIOS: 'AKTIVACIOS' as TipusEnum,
        NAPIDIJAS: 'NAPIDIJAS' as TipusEnum,
        RENDSZERHASZNALATI: 'RENDSZERHASZNALATI' as TipusEnum
    };
}