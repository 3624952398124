/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MegbizoDetails { 
    megbizoNev?: string;
    megbizoSzereploId?: number;
    normalizedNev?: string;
    sajatNevebenEljaro?: boolean;
    szereploTipus?: MegbizoDetails.SzereploTipusEnum;
}
export namespace MegbizoDetails {
    export type SzereploTipusEnum = 'SZEMELY' | 'SZERVEZET' | 'MOHOSZ' | 'TAGEGYESULET' | 'TAGSZOVETSEG' | 'BOLT' | 'HALGAZDALKODO' | 'ALTALANOS' | 'SZOLGALTATO';
    export const SzereploTipusEnum = {
        SZEMELY: 'SZEMELY' as SzereploTipusEnum,
        SZERVEZET: 'SZERVEZET' as SzereploTipusEnum,
        MOHOSZ: 'MOHOSZ' as SzereploTipusEnum,
        TAGEGYESULET: 'TAGEGYESULET' as SzereploTipusEnum,
        TAGSZOVETSEG: 'TAGSZOVETSEG' as SzereploTipusEnum,
        BOLT: 'BOLT' as SzereploTipusEnum,
        HALGAZDALKODO: 'HALGAZDALKODO' as SzereploTipusEnum,
        ALTALANOS: 'ALTALANOS' as SzereploTipusEnum,
        SZOLGALTATO: 'SZOLGALTATO' as SzereploTipusEnum
    };
}