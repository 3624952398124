import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { IonicModule } from '@ionic/angular';
import {
  FormFieldErrorContentComponent
} from 'app/material/form-field-error-content/form-field-error-content.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatNativeDateModule,
    MatDatepickerModule,
    IonicModule,
    ImageCropperModule,
    MatAutocompleteModule,
    PdfViewerModule
  ],
  declarations: [
    FormFieldErrorContentComponent,
  ],
  exports: [
    MatDialogModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FormFieldErrorContentComponent,
    ImageCropperModule,
    MatAutocompleteModule,
    PdfViewerModule
  ]
})
export class MaterialModule {
  public static forRoot(): ModuleWithProviders<MaterialModule> {
    return {
      ngModule: MaterialModule,
    };
  }
}
