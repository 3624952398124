import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { CoreModule } from '../../core/core.module';
import { SharedModule } from '../../shared/shared.module';

import { FogasokPageRoutingModule } from './fogasok-routing.module';
import { FogasokPage } from './fogasok.page';
import { FogasCardComponent } from 'app/fogasi-naplo/fogasok/fogas-card/fogas-card.component';
import { FogasDatePipe } from 'app/fogasi-naplo/fogasok/fogas-date.pipe';
import {
  FigyelmeztetesDialogComponent
} from 'app/fogasi-naplo/figyelmeztetes-dialog/figyelmeztetes-dialog.component';
import { MaterialModule } from 'app/material/material.module';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from 'app/core/custom-date-adapter.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    FogasokPageRoutingModule,
    MaterialModule,
    CoreModule,
    SharedModule,
  ],
  declarations: [
    FogasokPage,
    FogasCardComponent,
    FogasDatePipe,
    FigyelmeztetesDialogComponent
  ],
  providers: [
    {provide: DateAdapter, useClass: CustomDateAdapter}
  ]
})
export class FogasokPageModule {
}
