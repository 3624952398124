import { Environment } from './model';

export const environment: Environment = {
  release: 'DEV',
  production: false,
  hostUrl: 'https://mohosz-dev.plensys.cloud',
  serverUrl: '',
  geolocationConfig: {
    distanceFilter: 1, //1 meterenkent kuldunk lokaciot
    interval: 120000, //2 percenkent - tul sok adat lesz igy is
  }
};
