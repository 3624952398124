import { Pipe, PipeTransform } from '@angular/core';
import { TeruletiJegy } from 'api';

@Pipe({
  name: 'teruletiJegyStatusz'
})
export class TeruletiJegyStatuszPipe implements PipeTransform {

  transform(value: unknown, ...args: Array<unknown>): unknown {
    switch (value) {
      case TeruletiJegy.StatuszEnum.ERVENYES: return 'Érvényes';
      case TeruletiJegy.StatuszEnum.LEJART: return 'Lejárt';
      case TeruletiJegy.StatuszEnum.MEGVASAROLT: return 'Megvásárolt';
      case TeruletiJegy.StatuszEnum.VISSZAVONT: return 'Visszavont';
      case TeruletiJegy.StatuszEnum.SZTORNOZOTT: return 'Sztornózott';
      case TeruletiJegy.StatuszEnum.RKSZTORNOZOTT: return 'Érvénytelenített';
      default: return value;
    }
  }

}
