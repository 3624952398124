<div class="main">
    <mat-form-field class="form-field">
        <input type="text" class="search-field" matInput [formControl]="variableInput" [matAutocomplete]="auto" > <!-- placeholder="Keresés"-->
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onSelectionChanged($event)" [displayWith]="displayOptionNev">
            <mat-option *ngFor="let option of filteredRegPontok | async | slice:0:25;" [value]="option">
                <div class="optionNev">{{option.nev}}</div>
                <div class="optionCim">{{option.iranyitoSzam}}, {{option.varos}} {{option.kozteruletNev}} {{option.kozteruletTipus}} {{option.hazSzam}}.</div>
              </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>

