import { Mutex, MutexInterface } from 'async-mutex';
import { Utils } from 'app/core/utils';

export class TimerMutex {
  private mutex = new Mutex();
  private executed: Date;
  private readonly interval: number; //in seconds

  constructor(interval?: number) {
    //defaultbol 3 masdoperc
    this.interval = interval ?? 3;
  }

  runExclusive<T>(executableFunction: MutexInterface.Worker<T>) {
    this.mutex.runExclusive(() => {
      if (this.isRunEnabled()) {
        executableFunction();
      }
    });
  }

  private isRunEnabled(): boolean {
    const isRunEnabled = !this.executed || Utils.secondsBetween(new Date(), this.executed) >= this.interval;
    this.executed = isRunEnabled ? new Date() : this.executed;
    return  isRunEnabled;
  }
}
