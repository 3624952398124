
import { Pipe, PipeTransform } from '@angular/core';
import { VersenyzoiEngedely } from 'api';


@Pipe({
    name: 'versenyzoiEngedelyStatusz'
})
export class VersenyzoiEngedelyStatuszPipe implements PipeTransform {
    transform(value: VersenyzoiEngedely.StatuszEnum, args?: any): any {
        switch (value) {
            case VersenyzoiEngedely.StatuszEnum.ERVENYES: return 'Érvényes';
            case VersenyzoiEngedely.StatuszEnum.LEJART: return 'Lejárt';
            default: return value;
        }
    }
}
