import { AfterViewInit, Component, ElementRef, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Utils } from 'app/core/utils';

@Component({
  selector: 'mohosz-text-card',
  templateUrl: './text-card.component.html',
  styleUrls: ['../card.component.scss', './text-card.component.scss'],
})
export class TextCardComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {

  @Input() title: string;
  @Input() content: string;
  @Input() maxTextHeight: number;
  @ViewChild('textRef') textRef: ElementRef;

  isShowMore: boolean;
  textContainerHeight: number;
  observer: ResizeObserver;
  element: Element;

  showMoreTextLabel = 'Mutass többet';

  constructor(
    private zone: NgZone
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    if (Utils.hasValue(this.maxTextHeight)) {
      this.element = document.getElementById('container');
      this.observeHeight();
    }
  };

  ngAfterViewInit() {
    if (Utils.hasValue(this.maxTextHeight)) {
      this.element = document.getElementById('container');
      this.observeHeight();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.maxTextHeight) {
      this.textContainerHeight = this.maxTextHeight;
    }
  }

  ngOnDestroy() {
    if (Utils.hasValue(this.maxTextHeight)) {
      this.observer.unobserve(this.element);
    }
  }

  onShowTextToggleClick() {
    if (this.isShowMore && this.textContainerHeight === this.textRef?.nativeElement?.offsetHeight) {
      this.textContainerHeight = this.maxTextHeight;
      this.showMoreTextLabel = 'Mutass többet';
    } else {
      this.textContainerHeight = this.textRef?.nativeElement?.offsetHeight;
      this.showMoreTextLabel = 'Mutass kevesebbet';
    }
  }

  private observeHeight() {
    this.observer = new ResizeObserver(() => {
      this.zone.run(() => {
        this.isShowMore = this.textRef?.nativeElement?.offsetHeight > this.maxTextHeight;
      });
    });
    this.observer?.observe(this.element);
  }
}
