/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Jogosultsag { 
    authorities: Array<Jogosultsag.AuthoritiesEnum>;
    megbizoSzereploId: number;
    megbizottSzemelyId: number;
}
export namespace Jogosultsag {
    export type AuthoritiesEnum = 'ONLINE_HORGASZREGISZTRACIO' | 'UGYFELSZOLGALATI_GODMODE' | 'SUPER_ADMIN' | 'ELSZAMOLOHAZ' | 'UGYINTEZOI_HORGASZREGISZTRACIO' | 'UGYINTEZOI_JOVAHAGYAS' | 'UGYINTEZOI_FIZETESMGMT' | 'SZERVEZETI_JOGOSULTSAG_BEALLITAS' | 'UGYFELSZOLGALATI_JOVAHAGYAS' | 'UGYFELSZOLGALATI_FIZETESMGMT' | 'ADOKARTYA_UTOLAGOS_ELLENORZES' | 'UGYFELSZOLGALATI_JUTALEK' | 'UGYFELSZOLGALATI_HORGASZREGISZTRACIO' | 'UGYFELSZOLGALATI_TORZSADATMGMT' | 'UGYFELSZOLGALATI_USERMGMT' | 'UGYFELSZOLGALATI_ELTILTASMGMT' | 'ELTILTAS_FELTOLTES' | 'ERTEKESITESI_RIPORT' | 'FOGASNYILVATTARTAS_RIPORT' | 'FOGASINAPLOLEADAS_RIPORT' | 'EGYESULETITAGSAG_RIPORT' | 'TERULETIJEGY_RIPORT' | 'VIZSGABIZOTTSAG_RIPORT' | 'FORGALOM_RIPORT' | 'UZENET_MGMNT' | 'VIZTERULET_MGMT' | 'ELLENOR' | 'ELLENOR_MGMT' | 'ALLAMIJEGY_ARLISTA_MGMT' | 'ALLAMI_JEGY_ERTEKESITES' | 'ALLAMI_JEGY_ATADAS' | 'TAGDIJ_ARLISTA_MGMT' | 'TERULETI_JEGY_ERTEKESITES' | 'TERULETI_JEGY_TERMEK_MGMT' | 'VIZSGABIZOTTSAG_MGMT' | 'VIZSGABIZTOS' | 'SZERZODES_KOTES' | 'KESZLETEZO_MGMT' | 'FELADATSOR_MGMT' | 'HORGASZKARTYA_IGENYLES_MGMT' | 'DOKUMENTUMTAR_MGMT' | 'MAHOR_ELOFIZETES_MGMT' | 'VERSENY_MGMT' | 'PSYS_ADMIN' | 'TECHU' | 'SZOLGALTATASMGMT' | 'SZOLGALTATAS_FELUGYELO' | 'PUBLIKUS_BEJEGYZES_FELTOLTES' | 'BEJEGYZES_MGMT' | 'ALTALANOS_RIPORT' | 'ALTALANOS_RIPORT_MGMT' | 'GATHASZNALATI_ENGEDELY_MGMT' | 'GATHASZNALATI_ENGEDELY_ERTEKESITES' | 'GATHASZNALATI_ENGEDELY_RIPORT' | 'BANNER_MGMT';
    export const AuthoritiesEnum = {
        ONLINEHORGASZREGISZTRACIO: 'ONLINE_HORGASZREGISZTRACIO' as AuthoritiesEnum,
        UGYFELSZOLGALATIGODMODE: 'UGYFELSZOLGALATI_GODMODE' as AuthoritiesEnum,
        SUPERADMIN: 'SUPER_ADMIN' as AuthoritiesEnum,
        ELSZAMOLOHAZ: 'ELSZAMOLOHAZ' as AuthoritiesEnum,
        UGYINTEZOIHORGASZREGISZTRACIO: 'UGYINTEZOI_HORGASZREGISZTRACIO' as AuthoritiesEnum,
        UGYINTEZOIJOVAHAGYAS: 'UGYINTEZOI_JOVAHAGYAS' as AuthoritiesEnum,
        UGYINTEZOIFIZETESMGMT: 'UGYINTEZOI_FIZETESMGMT' as AuthoritiesEnum,
        SZERVEZETIJOGOSULTSAGBEALLITAS: 'SZERVEZETI_JOGOSULTSAG_BEALLITAS' as AuthoritiesEnum,
        UGYFELSZOLGALATIJOVAHAGYAS: 'UGYFELSZOLGALATI_JOVAHAGYAS' as AuthoritiesEnum,
        UGYFELSZOLGALATIFIZETESMGMT: 'UGYFELSZOLGALATI_FIZETESMGMT' as AuthoritiesEnum,
        ADOKARTYAUTOLAGOSELLENORZES: 'ADOKARTYA_UTOLAGOS_ELLENORZES' as AuthoritiesEnum,
        UGYFELSZOLGALATIJUTALEK: 'UGYFELSZOLGALATI_JUTALEK' as AuthoritiesEnum,
        UGYFELSZOLGALATIHORGASZREGISZTRACIO: 'UGYFELSZOLGALATI_HORGASZREGISZTRACIO' as AuthoritiesEnum,
        UGYFELSZOLGALATITORZSADATMGMT: 'UGYFELSZOLGALATI_TORZSADATMGMT' as AuthoritiesEnum,
        UGYFELSZOLGALATIUSERMGMT: 'UGYFELSZOLGALATI_USERMGMT' as AuthoritiesEnum,
        UGYFELSZOLGALATIELTILTASMGMT: 'UGYFELSZOLGALATI_ELTILTASMGMT' as AuthoritiesEnum,
        ELTILTASFELTOLTES: 'ELTILTAS_FELTOLTES' as AuthoritiesEnum,
        ERTEKESITESIRIPORT: 'ERTEKESITESI_RIPORT' as AuthoritiesEnum,
        FOGASNYILVATTARTASRIPORT: 'FOGASNYILVATTARTAS_RIPORT' as AuthoritiesEnum,
        FOGASINAPLOLEADASRIPORT: 'FOGASINAPLOLEADAS_RIPORT' as AuthoritiesEnum,
        EGYESULETITAGSAGRIPORT: 'EGYESULETITAGSAG_RIPORT' as AuthoritiesEnum,
        TERULETIJEGYRIPORT: 'TERULETIJEGY_RIPORT' as AuthoritiesEnum,
        VIZSGABIZOTTSAGRIPORT: 'VIZSGABIZOTTSAG_RIPORT' as AuthoritiesEnum,
        FORGALOMRIPORT: 'FORGALOM_RIPORT' as AuthoritiesEnum,
        UZENETMGMNT: 'UZENET_MGMNT' as AuthoritiesEnum,
        VIZTERULETMGMT: 'VIZTERULET_MGMT' as AuthoritiesEnum,
        ELLENOR: 'ELLENOR' as AuthoritiesEnum,
        ELLENORMGMT: 'ELLENOR_MGMT' as AuthoritiesEnum,
        ALLAMIJEGYARLISTAMGMT: 'ALLAMIJEGY_ARLISTA_MGMT' as AuthoritiesEnum,
        ALLAMIJEGYERTEKESITES: 'ALLAMI_JEGY_ERTEKESITES' as AuthoritiesEnum,
        ALLAMIJEGYATADAS: 'ALLAMI_JEGY_ATADAS' as AuthoritiesEnum,
        TAGDIJARLISTAMGMT: 'TAGDIJ_ARLISTA_MGMT' as AuthoritiesEnum,
        TERULETIJEGYERTEKESITES: 'TERULETI_JEGY_ERTEKESITES' as AuthoritiesEnum,
        TERULETIJEGYTERMEKMGMT: 'TERULETI_JEGY_TERMEK_MGMT' as AuthoritiesEnum,
        VIZSGABIZOTTSAGMGMT: 'VIZSGABIZOTTSAG_MGMT' as AuthoritiesEnum,
        VIZSGABIZTOS: 'VIZSGABIZTOS' as AuthoritiesEnum,
        SZERZODESKOTES: 'SZERZODES_KOTES' as AuthoritiesEnum,
        KESZLETEZOMGMT: 'KESZLETEZO_MGMT' as AuthoritiesEnum,
        FELADATSORMGMT: 'FELADATSOR_MGMT' as AuthoritiesEnum,
        HORGASZKARTYAIGENYLESMGMT: 'HORGASZKARTYA_IGENYLES_MGMT' as AuthoritiesEnum,
        DOKUMENTUMTARMGMT: 'DOKUMENTUMTAR_MGMT' as AuthoritiesEnum,
        MAHORELOFIZETESMGMT: 'MAHOR_ELOFIZETES_MGMT' as AuthoritiesEnum,
        VERSENYMGMT: 'VERSENY_MGMT' as AuthoritiesEnum,
        PSYSADMIN: 'PSYS_ADMIN' as AuthoritiesEnum,
        TECHU: 'TECHU' as AuthoritiesEnum,
        SZOLGALTATASMGMT: 'SZOLGALTATASMGMT' as AuthoritiesEnum,
        SZOLGALTATASFELUGYELO: 'SZOLGALTATAS_FELUGYELO' as AuthoritiesEnum,
        PUBLIKUSBEJEGYZESFELTOLTES: 'PUBLIKUS_BEJEGYZES_FELTOLTES' as AuthoritiesEnum,
        BEJEGYZESMGMT: 'BEJEGYZES_MGMT' as AuthoritiesEnum,
        ALTALANOSRIPORT: 'ALTALANOS_RIPORT' as AuthoritiesEnum,
        ALTALANOSRIPORTMGMT: 'ALTALANOS_RIPORT_MGMT' as AuthoritiesEnum,
        GATHASZNALATIENGEDELYMGMT: 'GATHASZNALATI_ENGEDELY_MGMT' as AuthoritiesEnum,
        GATHASZNALATIENGEDELYERTEKESITES: 'GATHASZNALATI_ENGEDELY_ERTEKESITES' as AuthoritiesEnum,
        GATHASZNALATIENGEDELYRIPORT: 'GATHASZNALATI_ENGEDELY_RIPORT' as AuthoritiesEnum,
        BANNERMGMT: 'BANNER_MGMT' as AuthoritiesEnum
    };
}