import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FigyelmeztetesDialogService } from './figyelmeztetes-dialog.service';
import { FogasiNaploPageRoutingModule } from './fogasi-naplo-routing.module';
import { FogasiNaploPage } from './fogasi-naplo.page';
import { CoreModule } from 'app/core/core.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CoreModule,
    FogasiNaploPageRoutingModule
  ],
  declarations: [FogasiNaploPage],
  providers: [
    FigyelmeztetesDialogService
  ]
})
export class FogasiNaploPageModule {}
