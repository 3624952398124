import {environment} from 'environments/environment';
import {ExtConfiguration} from './ext-configuration';

export class ApiModuleConfiguration {
  static default(): ExtConfiguration {
    console.log('apiModuleConfiguration: environment', environment);
    let basePath = environment.serverUrl;
    if (!environment.serverUrl) {
      const l = window.location;
      basePath = l.protocol + '//' + l.host;
    }
    console.log('apiModuleConfiguration: basePath', basePath);
    const extConfig = new ExtConfiguration({
      withCredentials: true,
      basePath
    });
    extConfig.hostUrl = environment.hostUrl;
    return extConfig;
  }
}
