<ion-content class="moh-bg-light">
  <ion-grid>
    <ion-row>
      <ion-col size-md="6" offset-md="3">
        <ion-card class="inline-calendar-card">
          <mat-calendar
            class="moh-calendar"
            [(selected)]="selectedDate"
            (selectedChange)="dateChanged($event)"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [dateClass]="dateClass()">
          </mat-calendar>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<mohosz-last-modified-refresher
  (refresh)="refreshList()"
  [lastModifiedType]="LastModifiedType.FOGASINAPLO">
</mohosz-last-modified-refresher>

