import { MobilAppFelhasznalo, SssUser } from 'api';
import { Utils } from '../core/utils';
import { AuthenticationState } from 'app/auth/authentication-state';

export class Authentication {
  /**
   * isAuthenticationChecked: Azt mutatja meg, hogy egyáltalán lefutott-e már bármilyen ellenőrzés vagy még csak initial value állapotban vagyunk.
   * Azért kell, hogy az AuthGuarService bevárja az async szervertől jövő jogosultság/authentikáció ellenőrzést
   */
  isAuthenticationChecked = false;
  felhasznalo: MobilAppFelhasznalo;
  private sssUser: SssUser;

  constructor() {}

  update(sssUser: SssUser, felhasznalo: MobilAppFelhasznalo): Authentication {
    this.isAuthenticationChecked = true;
    this.sssUser = sssUser;
    this.felhasznalo = felhasznalo;
    return this;
  }

  isAuthenticated(): boolean {
    return !!this.sssUser;
  }

  logout(): Authentication {
    this.isAuthenticationChecked = true;
    this.sssUser = null;
    this.felhasznalo = null;
    return this;
  }

  getState(): AuthenticationState {
    if (!this.isAuthenticationChecked) {
      return AuthenticationState.INIT;
    } else if (!this.isAuthenticated()) {
      return AuthenticationState.UNAUTHENTICATED;//logout
    } else if (this.isAuthenticated() && !Utils.hasValue(this.sssUser?.authorityChannel)) {
      return AuthenticationState.AUTHENTICATED;//nincs hasznalva, mindig van megbizo valasztas
    } else {
      return AuthenticationState.COMPLETED;
    }
  }
}
