import { Breakpoints } from '@angular/cdk/layout';

export class DisplaySize {

  breakpoint: string;

  constructor(breakpoint?: string) {
    this.breakpoint = breakpoint;
  }

  update(breakpoint: string): DisplaySize {
    this.breakpoint = breakpoint;
    return this;
  }

  isMobile() {
    return this.getSize() === DisplayEnum.MOBILE;
  }

  isSmall() {
    return this.getSize() === DisplayEnum.MOBILE || this.getSize() === DisplayEnum.TABLET_SMALL;
  }

  getSize() {
    switch (this.breakpoint) {
      case Breakpoints.XSmall: return DisplayEnum.MOBILE;
      case Breakpoints.Small: return DisplayEnum.TABLET_SMALL;
      case Breakpoints.Medium: return DisplayEnum.TABLET;
      case Breakpoints.Large: return DisplayEnum.DESKTOP;
      case Breakpoints.XLarge: return DisplayEnum.DESKTOP;
      default: return DisplayEnum.UNKNOWN;
    }
  }
}

export enum DisplayEnum {
  UNKNOWN,
  DESKTOP,
  TABLET,
  TABLET_SMALL,
  MOBILE
}
