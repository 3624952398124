import {TeruletiJegy} from 'api';
import {Utils} from 'app/core/utils';

export class ErvenyessegDatumPipe {

  private NAP_KOTELEZO_SET = [
    TeruletiJegy.IdoszakEnum.NAP10,
    TeruletiJegy.IdoszakEnum.NAP7,
    TeruletiJegy.IdoszakEnum.NAP3,
    TeruletiJegy.IdoszakEnum.NAP2,
    TeruletiJegy.IdoszakEnum.NAPI,
    TeruletiJegy.IdoszakEnum.EJSZAKAI
  ];

  private IDOPONT_KOTELEZO_SET = [
    TeruletiJegy.IdoszakEnum.ORA72,
    TeruletiJegy.IdoszakEnum.ORA48,
    TeruletiJegy.IdoszakEnum.ORA24
  ];

  private NEMKOTELEZO_SET = [
    TeruletiJegy.IdoszakEnum.EVES,
    TeruletiJegy.IdoszakEnum.EVESRESZLET1,
    TeruletiJegy.IdoszakEnum.EVESRESZLET2,
    TeruletiJegy.IdoszakEnum.FELEVES
  ];

  constructor() {}

  transformIdoszakErvenyesseg(idoszak: TeruletiJegy.IdoszakEnum, ervenyessegDatum: Date, dateTimestampSeparation?: boolean) {
    if(this.IDOPONT_KOTELEZO_SET.includes(idoszak)){
      return Utils.formatDateTime(ervenyessegDatum);
    } else if(this.NAP_KOTELEZO_SET.includes(idoszak) || this.NEMKOTELEZO_SET.includes(idoszak)){
      return dateTimestampSeparation ? Utils.formatDate(ervenyessegDatum) : Utils.formatDateTime(ervenyessegDatum);
    } else {
      throw new Error('Ismeretlen időszak érvényesség');
    }
  }
}
