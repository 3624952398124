import { Component, Input, OnInit } from '@angular/core';
import { HorgasztatasSzolgaltatasRegisztracio } from 'api';

@Component({
  selector: 'mohosz-felszereltseg-card',
  templateUrl: './felszereltseg-card.component.html',
  styleUrls: ['../card.component.scss', './felszereltseg-card.component.scss'],
})
export class FelszereltsegCardComponent implements OnInit {

  @Input() felszereltsegList: Array<HorgasztatasSzolgaltatasRegisztracio.FelszereltsegListEnum>;

  constructor() { }

  ngOnInit() {}

}
