import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'mohosz-form-field-error-content',
  templateUrl: './form-field-error-content.component.html',
  styleUrls: ['./form-field-error-content.component.scss']
})
export class FormFieldErrorContentComponent implements OnInit {

  @Input() control: AbstractControl;
  @Input() requiredError: 'display' | 'ignore' | 'dirty or touched' = 'dirty or touched';
  @Input() serverError = true;
  @Input() minError: string;
  @Input() maxError: string;
  @Input() minLengthError: string;
  @Input() maxLengthError: string;
  @Input() mismatchError: string;

  constructor() { }

  ngOnInit(): void {
  }

}
