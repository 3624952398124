import { Component } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { HorgaszturizmusPoiItem, MobilAppControllerService } from 'api';
import { TimerMutex } from 'app/core/timer-mutex';
import { LocationService } from 'app/services/location.service';
import { ToastService } from 'app/services/toast.service';
import { ConnectionStateService } from '../../services/connection-state.service';


@Component({
  selector: 'mohosz-lista-nezet',
  templateUrl: './lista-nezet.page.html',
  styleUrls: ['./lista-nezet.page.scss'],
})
export class ListaNezetPage extends OnDestroyMixin {

  poiList: Array<HorgaszturizmusPoiItem>;
  isLoading = true;

  private timerMutex = new TimerMutex(1);

  constructor(
    private connectionStateService: ConnectionStateService,
    private locationService: LocationService,
    private mobilAppControllerService: MobilAppControllerService,
    private toastService: ToastService
  ){
    super();
    this.connectionStateService.onlineState.pipe(untilComponentDestroyed(this)).subscribe(state =>
      this.refreshContent()
    );
  }

  ionViewDidEnter(){
    this.poiList = null;
    this.refreshContent();
  }

  refreshContent(){
    this.timerMutex.runExclusive(() => {
      this.loadPoiList();
    });
  }

  loadPoiList() {
    if(this.connectionStateService.isOnline()){
      this.isLoading = true;
      this.locationService.getLocation()
        .then(locationPos => {
          this.mobilAppControllerService.findNearbyPoiItems(locationPos).toPromise()
            .then(result => this.poiList = result)
            .catch(error => this.toastService.httpError(error))
            .finally(() => this.isLoading = false);
        })
        .catch(error => this.isLoading = false);
    } else {
      this.isLoading = false;
    }
  }
}
