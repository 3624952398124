import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { HorgaszNapokPageRoutingModule } from './horgasz-napok-routing.module';

import { HorgaszNapokPage } from './horgasz-napok.page';
import { SharedModule } from 'app/shared/shared.module';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from 'app/core/custom-date-adapter.service';
import { MaterialModule } from 'app/material/material.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    HorgaszNapokPageRoutingModule,
    MaterialModule,
    SharedModule
  ],
  declarations: [
    HorgaszNapokPage
  ],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter},
  ]
})
export class HorgaszNapokPageModule {}
