import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgSelectModule } from '@ng-select/ng-select';

import { CoreModule } from 'app/core/core.module';
import { FileUploaderModule } from 'app/file-uploader/file-uploader.module';
import { MaterialModule } from 'app/material/material.module';
import { SharedModule } from 'app/shared/shared.module';
import { FogasSzerkesztesePageRoutingModule } from './fogas-szerkesztese-routing.module';
import { FogasSzerkesztesePage } from './fogas-szerkesztese.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    FogasSzerkesztesePageRoutingModule,
    CoreModule,
    MaterialModule,
    NgSelectModule,
    ReactiveFormsModule,
    SharedModule,
    FileUploaderModule
  ],
  declarations: [FogasSzerkesztesePage]
})
export class FogasSzerkesztesePageModule {
}
