/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VizteruletCsoportLeiras { 
    nyelv?: VizteruletCsoportLeiras.NyelvEnum;
    szoveg?: string;
}
export namespace VizteruletCsoportLeiras {
    export type NyelvEnum = 'MAGYAR' | 'ANGOL' | 'NEMET' | 'EGYEB';
    export const NyelvEnum = {
        MAGYAR: 'MAGYAR' as NyelvEnum,
        ANGOL: 'ANGOL' as NyelvEnum,
        NEMET: 'NEMET' as NyelvEnum,
        EGYEB: 'EGYEB' as NyelvEnum
    };
}