import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonList } from '@ionic/angular';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Flog } from 'api';
import { ConnectionStateService } from 'app/services/connection-state.service';
import { routesConfig } from 'config/routesConfig';
import { FogasaimService } from './fogasaim.service';

@Component({
  selector: 'mohosz-fogasaim',
  templateUrl: './fogasaim.page.html',
  styleUrls: ['./fogasaim.page.scss'],
})
export class FogasaimPage extends OnDestroyMixin implements OnInit {

  @ViewChild(IonList) list: IonList;

  isLoading = true;
  fogasaimList: Array<Flog>;
  filteredFogasaimList: Array<Flog>;

  constructor(
    private connectionStateService: ConnectionStateService,
    private fogasaimService: FogasaimService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.fogasaimService.bundleLoading.pipe(untilComponentDestroyed(this)).subscribe(result => this.isLoading = result);
    this.fogasaimService.fogasaimList.pipe(untilComponentDestroyed(this)).subscribe(result => {
      this.fogasaimList = result;
      this.filteredFogasaimList = this.fogasaimList;
    });
  }

  onSzerkesztesClick(flog: Flog) {
    if(this.connectionStateService.isOnline()){
      this.fogasaimService.setKivalasztottFogas(flog);
      this.router.navigateByUrl(`/${routesConfig.tabs}/${routesConfig.fogasaim}/${routesConfig.szerkesztes}`);
    }
  }

  handleSearchChange(event) {
    const query = event.target.value.toLowerCase();
    this.filteredFogasaimList = this.fogasaimList.filter(d => d.details.halfajNev.toLowerCase().indexOf(query) > -1 ||
      d.details.vizteruletNev.toLowerCase().indexOf(query) > -1);
  }

  onAddFogasClick() {
    if(this.connectionStateService.isOnline()){
      this.fogasaimService.setKivalasztottFogas(undefined);
      this.router.navigateByUrl(`/${routesConfig.tabs}/${routesConfig.fogasaim}/${routesConfig.szerkesztes}`);
    }
  }

}
