import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HorgaszNapRequest } from 'api';
import { ToastService } from 'app/services/toast.service';
import { Utils } from 'app/core/utils';
import { HorgaszNapRogzitesModalData } from 'app/fogasi-naplo/horgasz-napok/rogzites-modal/horgasz-nap-rogzites-modal-data';
import { ConnectionStateService } from '../../../services/connection-state.service';
import { FogasiNaploService } from '../../../services/fogasi-naplo.service';
import { FigyelmeztetesDialogService } from '../../figyelmeztetes-dialog.service';

@Component({
  selector: 'mohosz-rogzites-modal',
  templateUrl: './horgasz-nap-rogzites-modal-page.html',
  styleUrls: ['../../rogzites-modal.scss'],
})
export class HorgaszNapRogzitesModalPage implements OnInit {

  data: HorgaszNapRogzitesModalData;

  fogasiNaploId: string;
  selectedNap: string;
  isTorles: boolean;

  constructor(
    public modalController: ModalController,
    private fogasiNaploService: FogasiNaploService,
    private toastService: ToastService,
    private connectionStateService: ConnectionStateService,
    private confirmDialogService: FigyelmeztetesDialogService
  ) { }

  ngOnInit() {
    this.fogasiNaploId = this.data.fogasiNaploId;
    this.isTorles = this.data.isTorles;
    this.selectedNap = this.data.selectedNap;
  }

  async onNapRogzitesClick() {
    if(await this.confirmRogzites()){
      const request: HorgaszNapRequest = {
        fogasiNaploId: this.fogasiNaploId,
        datum: Utils.toServerDate(this.selectedNap)
      };
      this.fogasiNaploService.eFogasiNaploAddHorgaszNap(request).toPromise()
        .then(result => {
          this.toastService.successMessage('Sikeres nap rögzítés');
          this.modalController.dismiss({
            dismissed: true,
            fogasNyilvantartas: result
          });
        })
        .catch(error => this.toastService.httpError(error));
    }
  }

  async onNapTorlesClick() {
    if(await this.confirmRogzites()){
      const request: HorgaszNapRequest = {
        fogasiNaploId: this.fogasiNaploId,
        datum: Utils.toServerDate(this.selectedNap)
      };
      this.fogasiNaploService.eFogasiNaploRemoveHorgaszNap(request).toPromise()
        .then(result => {
          this.toastService.successMessage('Sikeres nap törlés');
          this.modalController.dismiss({
            dismissed: true,
            fogasNyilvantartas: result
          });
        })
        .catch(error => this.toastService.httpError(error));
    }
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }


  private async confirmRogzites(){
    let rogzitesConfirmed = true;
    if(!this.connectionStateService.isOnline()){
      const dialogData = {
        figyelmeztetesek: ['Offline módban vagyunk, a horgászattal töltött napokat a későbbiekben szinkronizáljuk.']
      };
      rogzitesConfirmed = await this.confirmDialogService.openFigyelmeztetesDialog(dialogData, 'moh-notification');
    }
    return rogzitesConfirmed;
  }

}
