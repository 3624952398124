<ion-content class="moh-bg-light" *ngIf="horgaszkartya">

  <div [ngClass]="!horgaszkartya.horgaszAzonosito ? 'vertical-center' : ''">

    <ng-container *ngIf="vanHorgaszkartya; else noContentTpl">
      <ion-grid>
        <ion-row>
          <ion-col size-md="6" offset-md="3">
            <ion-card class="horgaszkartya">
              <ion-card-header>
                <ion-card-title>Magyar Horgászkártya</ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <img alt="" src="assets/images/horgaszkartya.png" class="horgaszkartya-image"/>
                <div class="horgaszkartya-data">
                  <div class="font-14">Azonosító:</div>
                  <div><strong>{{ horgaszkartya.horgaszAzonosito || '-' }}</strong></div>
                  <div class="font-14">Érvényesség kezdete:</div>
                  <div><strong>{{ horgaszkartya.ervenyessegKezdete || '-' }}</strong></div>
                  <div class="font-14">Érvényesség vége:</div>
                  <div><strong>{{ horgaszkartya.ervenyessegVege || '-'  }}</strong></div>
                </div>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>

    </ng-container>

    <ng-template #noContentTpl>
      <ion-card class="no-bg ion-text-center">
        <img alt="Figyelem" src="assets/icon/warning.svg" class="moh-warning"/>
        <ion-card-header>
          <ion-card-title>
            <ng-container *ngIf="vanHorgaszkartyaFizetesAlatt; else nincsHorgaszkartyaTpl">
              A  Magyar Horgászkártya igénylése fizetésre vár. Kérjük a horgaszjegy.hu oldalon lépj be a profilodba,
              vagy keresd fel horgászszervezeted és ellenőrizd a fizetés sikerességét.
            </ng-container>
            <ng-template #nincsHorgaszkartyaTpl>
              Még nem rendelkezel horgászkártyával. A kiváltás szükségességéről, annak felteleiről a horgaszjegy.hu oldalon tájékozódhatsz.
            </ng-template>
          </ion-card-title>
        </ion-card-header>
      </ion-card>
    </ng-template>

  </div>

</ion-content>
