import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { AltalanosModule } from '../altalanos/altalanos.module';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    IonicModule,
    AltalanosModule,
    LeafletModule,
    LeafletDrawModule,
    LeafletMarkerClusterModule,
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    IonicModule,
    LeafletModule,
    LeafletDrawModule,
    LeafletMarkerClusterModule,
  ],
  declarations: [
  ]
})
export class TerkepModule {
}
