import { PipeTransform } from '@angular/core';
import { SelectableItem } from 'app/core/apienum/selectable-item';

export class ApiEnumHelper {

  static getEnumValues(apiEnumType) {
    return [...Object.keys(apiEnumType).map(key => apiEnumType[key])] as Array<any>;
  }
  static getSelectableItems(apiEnumType, pipe: PipeTransform): Array<SelectableItem> {
    return ApiEnumHelper.getEnumValues(apiEnumType).map(etv => ApiEnumHelper.mapToSelectableItem(etv, pipe));
  }
  static mapToSelectableItem(value, pipe: PipeTransform) {
    return value ? new SelectableItem(value, pipe) : null;
  }
  static mapToSelectableItems(values: Array<any>, pipe: PipeTransform) {
    return values.map(value => ApiEnumHelper.mapToSelectableItem(value, pipe));
  }

  static convertToMap(apiMap: { [key: string]: any }): Map<string, any> {
    const retMap: Map<string, any> = new Map();
    Object.keys(apiMap).forEach (key => retMap.set(key, apiMap[key]));
    return retMap;
  }
}
