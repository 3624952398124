import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VedettVizterulet } from 'api';
import { UiVedettVizterulet } from 'app/vedett-vizterulet/ui-vedett.vizterulet';


@Component({
  selector: 'mohosz-vedett-vizterulet-terkep-popup',
  templateUrl: './vedett-vizterulet-terkep-popup.component.html',
  styleUrls: ['./vedett-vizterulet-terkep-popup.component.scss']
})
export class VedettVizteruletTerkepPopupComponent implements OnInit {

  @Input() vedettVizterulet: UiVedettVizterulet;
  @Output() closePoiPopup: EventEmitter<any> = new EventEmitter();

  VedettVizteruletTipusEnum = VedettVizterulet.TipusEnum;

  constructor() { }

  ngOnInit(): void {
  }

  onCloseClick(): void {
    this.closePoiPopup.emit();
  }

}
