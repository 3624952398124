<ion-card>

  <ion-card-header>
    <ion-item>
      <ion-avatar slot="start" class="icon-moh-teruleti-jegy">
        <ion-icon src="assets/icon/ticket-outline.svg"></ion-icon>
      </ion-avatar>
      <ion-label class="ion-text-wrap">
        <h6>{{teruletiJegy?.teruletiJegyTermekNev}} - <span>{{teruletiJegy?.botokSzama}} botos</span></h6>
        <p class="allapot" [ngClass]="teruletiJegy?.ervenyes ? 'active' : 'inactive'">
          {{teruletiJegy?.statusz | teruletiJegyStatusz}}
        </p>
      </ion-label>
    </ion-item>
  </ion-card-header>

  <ion-card-content class="no-padding-top">
    <ion-list lines="none">
      <ion-item>
        <span slot="start">Sorszám:</span>
        <ion-label class="ion-text-wrap"><strong>{{teruletiJegy?.sorszam}}</strong></ion-label>
      </ion-item>
      <ion-item>
        <span slot="start">Érvényes:</span>
        <ion-label class="ion-text-wrap">
          <strong>
            {{ervenyessegDatumPipe?.transformIdoszakErvenyesseg(teruletiJegy.idoszak, teruletiJegy.ervenyessegKezdete)}}-tól
            <br>
            {{ervenyessegDatumPipe?.transformIdoszakErvenyesseg(teruletiJegy.idoszak, teruletiJegy.ervenyessegVege)}}-ig
          </strong>
        </ion-label>
      </ion-item>
    </ion-list>

    <!-- kiegeszito jegy -->
    <div *ngIf="teruletiJegy?.teruletiKiegeszitoJegyList && teruletiJegy?.teruletiKiegeszitoJegyList.length > 0"
         class="inside-cards">
      <ion-card *ngFor="let kiegeszitoJegy of teruletiJegy?.teruletiKiegeszitoJegyList">
        <ion-card-header>
          <ion-item>
            <ion-avatar slot="start">
              <ion-icon name="add-outline" color="primary"></ion-icon>
            </ion-avatar>
            <ion-label class="ion-text-wrap">
              <h3>{{kiegeszitoJegy.tipus | teruletiJegyKiegeszitoTipus}} {{kiegeszitoJegy.idoszak | termekIdoszak}},
                kiegészítő jegy</h3>
              <p class="allapot" [ngClass]="kiegeszitoJegy.ervenyes ? 'active' : 'inactive'">
                {{kiegeszitoJegy?.statusz | teruletiJegyStatusz}}
              </p>
            </ion-label>
          </ion-item>
        </ion-card-header>
        <ion-card-content class="no-padding-top">
          <ion-list lines="none">
            <!--
            <ion-item>
              <span slot="start">Sorszám:</span>
              <ion-label><strong>{{kiegeszitoJegy?.sorszam}}</strong></ion-label>
            </ion-item>
            -->
            <ion-item>
              <span slot="start" class="ion-text-wrap">Érvényes:</span>
              <ion-label class="ion-text-wrap">
                <strong>
                  {{ervenyessegDatumPipe?.transformIdoszakErvenyesseg(kiegeszitoJegy.idoszak, kiegeszitoJegy.ervenyessegKezdete)}}-tól
                  <br>
                  {{ervenyessegDatumPipe?.transformIdoszakErvenyesseg(kiegeszitoJegy.idoszak, kiegeszitoJegy.ervenyessegVege)}}-ig
                </strong>
              </ion-label>
            </ion-item>
            <ion-item *ngIf="kiegeszitoJegy.vizterulet">
              <ion-label>{{kiegeszitoJegy.vizterulet.nev}} vízterületre</ion-label>
            </ion-item>
          </ion-list>
        </ion-card-content>
      </ion-card>
    </div>
    <!-- kiegeszito jegy vege -->

  </ion-card-content>

</ion-card>
