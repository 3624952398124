import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { ConnectionStatus } from '@capacitor/network/dist/esm/definitions';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export enum ConnectionStateEnum {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE'
}

@Injectable({
  providedIn: 'root'
})
export class ConnectionStateService extends OnDestroyMixin{

  readonly state: Observable<ConnectionStateEnum>;
  readonly onlineState: Observable<ConnectionStateEnum>;

  private isConnectionStateChanged = false;
  private readonly stateSubject: BehaviorSubject<ConnectionStateEnum>;

  constructor(
  ) {
    super();
    //az elso nem erdekes, az mindig online lesz...
    this.stateSubject = new BehaviorSubject<ConnectionStateEnum>(ConnectionStateEnum.ONLINE);
    this.state = this.stateSubject.asObservable();

    this.onlineState = this.stateSubject.asObservable().pipe(
      filter(state => state && this.isConnectionStateChanged),
      filter(state => state === ConnectionStateEnum.ONLINE)
    );

    Network.getStatus().then(connectionStatus => this.setState(connectionStatus));
    Network.addListener('networkStatusChange', connectionStatus => {
      console.log('Network status changed', connectionStatus);
      this.setState(connectionStatus);
      this.isConnectionStateChanged = true;
    });
  }

  switchState(){
    if(!Capacitor.isNativePlatform()){
      this.isConnectionStateChanged = true;
      this.stateSubject.next(this.isOnline() ? ConnectionStateEnum.OFFLINE: ConnectionStateEnum.ONLINE);
    }
  }

  switchToOnline(){
    if(!this.isOnline()){
      this.stateSubject.next(ConnectionStateEnum.ONLINE);
    }
  }

  switchToOffline(){
    if(this.isOnline()){
      this.stateSubject.next(ConnectionStateEnum.OFFLINE);
    }
  }

  isOnline(){
    return this.stateSubject.value !== ConnectionStateEnum.OFFLINE;
  }

  private setState(conStatus: ConnectionStatus) {
    this.stateSubject.next(conStatus.connected ? ConnectionStateEnum.ONLINE : ConnectionStateEnum.OFFLINE);
  }

}
