import { GPSKoordinata, HorgaszturizmusMapPoi, HorgaszturizmusPoiItem, KedvencHorgaszhely, SzolgaltatasListItem } from 'api';
import { Utils } from 'app/core/utils';
import { DivIcon, Icon, LatLng, LatLngBounds, Map as LeafletMap, Marker, MarkerClusterGroupOptions, Point } from 'leaflet';
import { TerkepConstants } from './terkep-constants';

export class TerkepHelper {

  private static poiTypeMap: Map<SzolgaltatasListItem.TipusEnum, HorgaszturizmusPoiItem.TypeEnum> = new Map([
    [SzolgaltatasListItem.TipusEnum.HORGASZTATAS, HorgaszturizmusPoiItem.TypeEnum.VIZTERULET],
    [SzolgaltatasListItem.TipusEnum.BERBEADAS, HorgaszturizmusPoiItem.TypeEnum.BERBEADAS],
    [SzolgaltatasListItem.TipusEnum.SZOLGALTATOIADATLAP, HorgaszturizmusPoiItem.TypeEnum.SZOLGALTATO]
  ]);

  static disableMap(map: LeafletMap) {
    map?.dragging?.disable();
    map?.tap?.disable();
    map?.scrollWheelZoom?.disable();
    map?.doubleClickZoom?.disable();
    map?.zoomControl?.remove();
  }

  static enableMap(map: LeafletMap) {
    map?.dragging?.enable();
    map?.tap?.enable();
    map?.scrollWheelZoom?.enable();
    map?.doubleClickZoom?.enable();
    map?.zoomControl?.addTo(map);
  }

  static invalidateSize(map: LeafletMap){
    // todo fix leaflet map kirajzolas bug (css animation delay miatt van)
    // workaround
    if(map){
      setTimeout(() => map.invalidateSize(), 450);
    }
    // resize esemeny kivaltasaval
    // window.dispatchEvent(new Event('resize'));
  }

  static koordinataToLatLng(koordinata: GPSKoordinata): LatLng {
    return new LatLng(koordinata.szelessegiFok, koordinata.hosszusagiFok);
  }

  static koordinataListToLatLng(koordiataList: Array<GPSKoordinata>): Array<LatLng> {
    return koordiataList.map(koordinata => TerkepHelper.koordinataToLatLng(koordinata));
  }

  static createNewHorgaszturizmusPoi(
    szelessegiFok: number, hosszusagiFok: number, tipus: SzolgaltatasListItem.TipusEnum, megnevezes: string): HorgaszturizmusMapPoi {
    return {
      id: undefined,
      koordinata: {
        szelessegiFok,
        hosszusagiFok
      },
      megnevezes,
      type: TerkepHelper.poiTypeMap.get(tipus)
    };
  }

  static createNewKedvencHorgaszhely(szelessegiFok: number, hosszusagiFok: number): KedvencHorgaszhely {
    return {
      id: undefined,
      koordinata: {
        szelessegiFok,
        hosszusagiFok
      }
    };
  }

  static createBounds(koordiataList: Array<GPSKoordinata>): LatLngBounds {
    if (koordiataList && koordiataList.length > 0) {
      const bounds = new LatLngBounds(TerkepHelper.koordinataToLatLng(koordiataList[0]), TerkepHelper.koordinataToLatLng(koordiataList[0]));
      koordiataList.forEach(koordiata => {
        bounds.extend(TerkepHelper.koordinataToLatLng(koordiata));
      });
      return bounds;
    } else {
      return undefined;
    }
  }

  static isEqualGPSKoorinatak(gpk1: GPSKoordinata, gpk2: GPSKoordinata): boolean {
    return gpk1.hosszusagiFok === gpk2.hosszusagiFok && gpk1.szelessegiFok === gpk2.szelessegiFok;
  }

  static isValidGPSKoorinata(koordinata: GPSKoordinata): boolean {
    return koordinata && Utils.hasValue(koordinata.szelessegiFok) && Utils.hasValue(koordinata.hosszusagiFok);
  }

  static getHorgaszturizmusMarkerClusterOptions(disableClusteringAtZoom?: number): MarkerClusterGroupOptions {
    return {
      disableClusteringAtZoom,
      iconCreateFunction: cluster => {
        const childCount = cluster.getChildCount();
        let c = ' marker-cluster-';
        if (childCount < 10) {
          c += 'small';
        }
        else if (childCount < 100) {
          c += 'medium';
        }
        else {
          c += 'large';
        }
        return new DivIcon({ html: '<div><span>' + childCount + '</span></div>',
          className: 'marker-cluster' + c, iconSize: new Point(40, 40) });
      }
    };
  }

  static  createLocationMarker(locationPos: GPSKoordinata) {
    const locationIcon = new Icon({iconUrl: TerkepConstants.LOCATION_MARKER,iconSize: [32, 32]});
    const locLatLng = new LatLng(locationPos.szelessegiFok, locationPos.hosszusagiFok);
    return new Marker(locLatLng, {icon: locationIcon});
  }
}
