import {ElektronikusFogas} from 'api';

export class FogasokByMonth {
  pkDate: number;
  fogasok: Array<ElektronikusFogas>;

  static fromDomain(fogas: ElektronikusFogas){
    const fbm = new FogasokByMonth();
    fbm.pkDate = FogasokByMonth.buildPk(fogas);
    fbm.fogasok = [fogas];
    return fbm;
  }

  static buildPk(fogas: ElektronikusFogas): number{
    const date = new Date(fogas.idopont);
    date.setDate(10);
    date.setHours(10);
    date.setMinutes(10);
    return date.getTime();
  }

  static sort(fogasok: Array<ElektronikusFogas>): Array<ElektronikusFogas> {
      const retFogasok = JSON.parse(JSON.stringify(fogasok));//klonozas
      retFogasok.sort((a,b) => (new Date(b.idopont).getTime() - new Date(a.idopont).getTime()));
      return retFogasok;
  }

  addFogas(fogas: ElektronikusFogas){
    this.fogasok.push(fogas);//lehet ellenorizni hogy az ev es a honap megegyezik/e
    this.fogasok = FogasokByMonth.sort(this.fogasok);
  }
}
