import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TerkepConstants } from '../../shared/terkep-constants';
import { RegPont } from './reg-pont';

@Injectable({
  providedIn: 'root'
})
export class RegPontService {
  readonly regPontokUrl: string = TerkepConstants.REG_PONT_URL;

  constructor(private http: HttpClient) {
  }

  static handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

  getRegpontObservable(): Observable<Array<RegPont>> {
    return this.http.get<Array<RegPont>>(this.regPontokUrl).pipe(
      catchError(RegPontService.handleError)
    );
  }
}
