/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Base64Content } from './base64Content';

export interface FlogDetails { 
    csali?: string;
    etetoanyag?: string;
    farasztasPerc?: number;
    fogasModszer?: FlogDetails.FogasModszerEnum;
    halfajNev?: string;
    hossz?: number;
    kep?: Base64Content;
    kepId?: number;
    kerulet?: number;
    profilKep?: Base64Content;
    suly?: number;
    szereploNev?: string;
    szolgaltatasbolLetrehozva?: boolean;
    visszaengedett?: boolean;
    vizteruletNev?: string;
}
export namespace FlogDetails {
    export type FogasModszerEnum = 'KLASSZIKUS_FENEKEZES' | 'BOJLIZAS_DOBASSAL_BEHUZASSAL' | 'FEEDER_BOTOS_HORGASZAT' | 'RAGADOZO_HALAS_FENEKEZOS_HORGASZAT' | 'SPICCBOTOS_RAKOSBOTOS_HORGASZAT' | 'KLASSZIKUS_USZOZAS' | 'HARCSAZAS_BEHUZASSAL' | 'MATCH_BOTOS_HORGASZAT' | 'USZOS_RAGADOZO_HALAS_HORGASZAT' | 'WOBBLEREZES' | 'VILLANTOZAS' | 'GUMIHALAZAS_TWISTEREZES' | 'MARTOGATAS_TAPOGATAS' | 'KUTTYOGATAS' | 'LEKHORGASZAT_MORMISKAZAS' | 'MULEGYEZO_HORGASZAT';
    export const FogasModszerEnum = {
        KLASSZIKUSFENEKEZES: 'KLASSZIKUS_FENEKEZES' as FogasModszerEnum,
        BOJLIZASDOBASSALBEHUZASSAL: 'BOJLIZAS_DOBASSAL_BEHUZASSAL' as FogasModszerEnum,
        FEEDERBOTOSHORGASZAT: 'FEEDER_BOTOS_HORGASZAT' as FogasModszerEnum,
        RAGADOZOHALASFENEKEZOSHORGASZAT: 'RAGADOZO_HALAS_FENEKEZOS_HORGASZAT' as FogasModszerEnum,
        SPICCBOTOSRAKOSBOTOSHORGASZAT: 'SPICCBOTOS_RAKOSBOTOS_HORGASZAT' as FogasModszerEnum,
        KLASSZIKUSUSZOZAS: 'KLASSZIKUS_USZOZAS' as FogasModszerEnum,
        HARCSAZASBEHUZASSAL: 'HARCSAZAS_BEHUZASSAL' as FogasModszerEnum,
        MATCHBOTOSHORGASZAT: 'MATCH_BOTOS_HORGASZAT' as FogasModszerEnum,
        USZOSRAGADOZOHALASHORGASZAT: 'USZOS_RAGADOZO_HALAS_HORGASZAT' as FogasModszerEnum,
        WOBBLEREZES: 'WOBBLEREZES' as FogasModszerEnum,
        VILLANTOZAS: 'VILLANTOZAS' as FogasModszerEnum,
        GUMIHALAZASTWISTEREZES: 'GUMIHALAZAS_TWISTEREZES' as FogasModszerEnum,
        MARTOGATASTAPOGATAS: 'MARTOGATAS_TAPOGATAS' as FogasModszerEnum,
        KUTTYOGATAS: 'KUTTYOGATAS' as FogasModszerEnum,
        LEKHORGASZATMORMISKAZAS: 'LEKHORGASZAT_MORMISKAZAS' as FogasModszerEnum,
        MULEGYEZOHORGASZAT: 'MULEGYEZO_HORGASZAT' as FogasModszerEnum
    };
}