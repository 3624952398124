import { Component } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { MobilAppHorgaszkartya } from 'api';
import { AuthService } from 'app/auth/auth.service';

@Component({
  selector: 'mohosz-horgaszkartya',
  templateUrl: './horgaszkartya.page.html',
  styleUrls: ['./horgaszkartya.page.scss'],
})
export class HorgaszkartyaPage extends OnDestroyMixin{

  horgaszkartya: MobilAppHorgaszkartya;
  vanHorgaszkartya: boolean;
  vanHorgaszkartyaFizetesAlatt: boolean;

  private readonly vanHorgaszkartyaStatusList: Array<MobilAppHorgaszkartya.IgenylesStatuszEnum> = [
    MobilAppHorgaszkartya.IgenylesStatuszEnum.GYARTASALATT, MobilAppHorgaszkartya.IgenylesStatuszEnum.KESZ
  ];

  private readonly vanHorgaszkartyaFizetesAlattStatusList: Array<MobilAppHorgaszkartya.IgenylesStatuszEnum> = [
    MobilAppHorgaszkartya.IgenylesStatuszEnum.HORGASZFIZETESREVAR, MobilAppHorgaszkartya.IgenylesStatuszEnum.HORGASZFIZETESFOLYAMATBAN
  ];

  constructor(
    private authService: AuthService,
  ){
    super();
    this.authService.authentication.pipe(untilComponentDestroyed(this)).subscribe(auth => {
       const horgaszkartyaItem = auth.felhasznalo?.horgaszkartya ?? {};
      this.vanHorgaszkartya = this.vanHorgaszkartyaStatusList.includes(horgaszkartyaItem.igenylesStatusz);
      this.vanHorgaszkartyaFizetesAlatt = this.vanHorgaszkartyaFizetesAlattStatusList.includes(horgaszkartyaItem.igenylesStatusz);
      this.horgaszkartya = horgaszkartyaItem;
    });
  }
}
