import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { BejovoUzenetekPageModule } from './bejovo-uzenetek/bejovo-uzenetek.module';
import { KimenoUzenetekPageModule } from './kimeno-uzenetek/kimeno-uzenetek.module';
import { PostafiokPage } from './postafiok.page';
import { UzenetReszletekComponent } from './uzenet-reszletek/uzenet-reszletek.component';


const routes: Routes = [
  {
    path: '',
    component: PostafiokPage,
    children: [
      {
        path: 'bejovo-uzenetek',
        children: [
          {
            path: '', loadChildren: () => BejovoUzenetekPageModule, canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'kimeno-uzenetek',
        children: [
          {
            path: '', loadChildren: () => KimenoUzenetekPageModule, canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: '',
        redirectTo: 'bejovo-uzenetek',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'uzenet/:id',
    component: UzenetReszletekComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PostafiokPageRoutingModule {}
