import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/auth.guard';
import { routesConfig } from 'config/routesConfig';
import { HorgaszrendComponent } from './horgasztatas-reszletek/horgaszrend/horgaszrend.component';
import { HorgasztatasReszletekPage } from './horgasztatas-reszletek/horgasztatas-reszletek-page.component';
import { JegyvasarlasPage } from './jegyvasarlas.page';
import { KeszComponent } from './kesz/kesz.component';
import { ListaNezetPageModule } from './lista-nezet/lista-nezet.module';
import { TerkepNezetPageModule } from './terkep-nezet/terkep-nezet.module';

const routes: Routes = [
  {
    path: '',
    component: JegyvasarlasPage,
    children: [
      {
        path: routesConfig.terkepNezet, loadChildren: () => TerkepNezetPageModule, canActivate: [AuthGuard]
      },
      {
        path: routesConfig.listaNezet, loadChildren: () => ListaNezetPageModule, canActivate: [AuthGuard]
      },
      {
        path: routesConfig.kesz, component: KeszComponent
      },
      {
        path: '', redirectTo: `${routesConfig.terkepNezet}`, pathMatch: 'prefix'
      }
    ],
  },
  {
    path: 'horgasztatas/horgaszrend/:szolgaltatasId', component: HorgaszrendComponent, canActivate: [AuthGuard]
  },
  {
    path: 'horgasztatas/:szolgaltatasId', component: HorgasztatasReszletekPage, canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class JegyVasarlasPageRoutingModule {}
