<ion-header>
  <ion-toolbar color="secondary">
    <ion-title>Turista fogási napló</ion-title>
    <ion-buttons slot="end" class="ion-margin-top">
      <mohosz-header-notification></mohosz-header-notification>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-tabs>
    <ion-tab-bar slot="top">
      <!-- tab -->
      <ion-tab-button tab="fogasok">
        <ion-label>Fogások</ion-label>
      </ion-tab-button>
      <!-- tab -->
      <ion-tab-button tab="horgasz-napok">
        <ion-label>Horgász napok</ion-label>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
</ion-content>
