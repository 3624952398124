<ng-container *ngIf="data">
<!--  <div class="close-button-container">-->
<!--    <ion-button fill="clear" color="secondary" class="close-button" [mat-dialog-close]="false">-->
<!--      <ion-icon slot="icon-only" name="close"></ion-icon>-->
<!--    </ion-button>-->
<!--  </div>-->

  <div class="confirm-dialog-container">
    <mat-dialog-content>
      <!-- icon -->
      <div class="uzenet-tipus-icon-container">
        <ion-icon src="/assets/icon/warning.svg"></ion-icon>
        <h1 mat-dialog-title class="ion-text-center">Figyelmeztetés{{data.figyelmeztetesek.length > 1 ? 'ek' : ''}}</h1>
      </div>

      <ng-container *ngIf="data.figyelmeztetesek.length > 0">
        <ng-container *ngFor="let figyelmeztetes of data.figyelmeztetesek">
          <p>{{figyelmeztetes}}</p>
        </ng-container>

        <p class="ion-padding-top"><strong>Mehet?</strong></p>

      </ng-container>
    </mat-dialog-content>

    <mat-dialog-actions class="ion-justify-content-center">
      <ion-button fill="outline" color="primary" [mat-dialog-close]="false">
        Mégsem
      </ion-button>
      <ion-button fill="solid" color="primary" [mat-dialog-close]="true">
        Igen
      </ion-button>
    </mat-dialog-actions>
  </div>
</ng-container>
