import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CoreModule } from 'app/core/core.module';
import { SharedModule } from 'app/shared/shared.module';
import { PostafiokPageRoutingModule } from './postafiok-routing.module';
import { PostafiokPage } from './postafiok.page';
import { UzenetReszletekComponent } from './uzenet-reszletek/uzenet-reszletek.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CoreModule,
    PostafiokPageRoutingModule,
    SharedModule
  ],
  declarations: [
    PostafiokPage,
    UzenetReszletekComponent
  ]
})
export class PostafiokPageModule {}
