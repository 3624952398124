import { DatePipe } from '@angular/common';
import { FormGroup } from '@angular/forms';
import {Browser} from '@capacitor/browser';
import * as moment from 'moment';
import {Base64Content} from '../../api';

export const nameofFactory = <T>() => (name: keyof T) => name;

export class Utils {

  static HTTP_ERROR_CODE_403_ACCESS_DENIED = 403;
  static HTTP_ERROR_CODE_401_UNATHORIZED = 401;
  static HTTP_ERROR_CODE_500_INTERNAL_SERVER_ERROR = 500;
  static HTTP_ERROR_CODE_502_MAINTENANCE = 502;
  static HTTP_ERROR_CODE_0_ERR_CONNECTION_REFUSE = 0;

  private static datePipe = new DatePipe('hu-HU');

  static parseServerError(form: FormGroup, err) {
    const globalErrors = [];
    try {
      const status = err.error.status;
      if ((400 <= status && status <= 499)) {
        if ((!err.error.errors || err.error.errors.length === 0)) {
          globalErrors.push(err.error.message);
        } else {
          err.error.errors.forEach((error) => {
            const control = form.get(error.field); // form.controls[error.field]
            if (control) {
              control.setErrors({
                server: { message: error.defaultMessage }
              });
              control.markAsTouched();
            } else {
              globalErrors.push(error.defaultMessage);
            }
          });
        }
      }
      if (status === this.HTTP_ERROR_CODE_500_INTERNAL_SERVER_ERROR) {
        console.error('500 Internal Server ERROR', err);
        globalErrors.push(err.error.message);
      }
      if (globalErrors.length > 0) {
        form.setErrors({
          server: { message: globalErrors.join(', ') }
        });
      }
    } catch (error) {
      console.error('Utils.parseServerError: caught exception:', error);
    }
  }

  static secondsBetween(date1: Date, date2: Date) {
    return Math.abs((date1.getTime() - date2.getTime()) / 1000);
  }

  static hasValue(object: any) {
    return object !== null && object !== undefined;
  }

  static isNonEmptyString(st: string) {
    return st !== null && st !== undefined && st.length > 0;
  }

  static toServerDate(date: any) {
    if (date instanceof Date) {
      return moment(date).format('YYYY-MM-DD');
    }
    return date;
  }

  static toServerDateTime(date: Date, ora: number, perc: number) {
    if (date && Utils.hasValue(ora) && Utils.hasValue(perc)) {
      const time = Utils.leftPadding(ora, 2) + ':' + Utils.leftPadding(perc, 2);
      const dateString: string = moment(date).format('YYYY-MM-DD') + 'T' + time;
      return Utils.toUTC(new Date(dateString));
    }
  }

  static toUTC(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0, 0));
  }

  static leftPadding(num: number, size: number): string {
    let str = num + '';
    while (str.length < size) {
      str = '0' + str;
    }
    return str;
  }


  static fromServerDate(date: string): Date {
    return date ? moment(date).toDate() : undefined;
  }

  static formatDate(date: Date): string {
    return this.hasValue(date) ? Utils.datePipe.transform(date, 'shortDate') : '';
  }

  static formatDateTime(date: Date): string {
    return this.hasValue(date) ? Utils.datePipe.transform(date, 'short') : '';
  }

  static formatDateYearMonth(date: string): string {
    return this.hasValue(date) ? Utils.datePipe.transform(date, 'YYYY. MMMM') : '';
  }

  static removeUrlProtocol(url: string): string {
    return url?.replace(/^https?:\/\//, '');
  }

  static numberRangeArray(start: number, stop: number, step: number): Array<number> {
    return Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
  }

  static base64toBlob(data: string, type: string): Blob {
    return new Blob([this.base64ToArrayBuffer(data)], {type});
  }

  static base64ToArrayBuffer(data: string): Uint8Array {
    const binaryString = window.atob(data);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }

  static base64DataUrlToFile(data: any, filename: any): File {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  static sort(array: Array<string>): Array<string> {
    return array.sort( (a, b) => a.localeCompare(b));
  }

  static equalsDate(date1: Date, date2: Date): boolean{
    const nDate1 = new Date(date1);
    const nDate2 = new Date(date2);
    return nDate1.getFullYear() === nDate2.getFullYear() &&
      nDate1.getMonth() === nDate2.getMonth() &&
      nDate1.getDate() === nDate2.getDate();
  }

  static base64ContentUrl(content: Base64Content){
    return 'data:' + content.httpMediaType + ';base64,' + content.content;
  }

  static openBase64Content(content: Base64Content) {
    const win = window.open();
    win.document.write('<iframe src="' + this.base64ContentUrl(content)  + '" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen></iframe>');
  }

  static goToLocation(latitude: number, longitude: number){
    const gDirectionUrl = 'https://www.google.com/maps/dir/?api=1';
    const gmapUrl = `${gDirectionUrl}&origin=Your+location&destination=${latitude},${longitude}`;
    Browser.open({url:  gmapUrl, windowName: '_blank'});
  }
}
