import { Component } from '@angular/core';
import { LifeCycleService } from 'app/services/lifecycle.service';

@Component({
  selector: 'mohosz-ertekesitesi-pontok',
  templateUrl: './ertekesitesi-pontok.page.html',
  styleUrls: ['./ertekesitesi-pontok.page.scss'],
  providers: [LifeCycleService]
})
export class ErtekesitesiPontokPage {

  constructor(
    private lifeCycleService: LifeCycleService
  ){
  }

  ionViewDidEnter() {
    this.lifeCycleService.propagateDidEnter();
  }
}
