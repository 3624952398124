import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Base64Content, MobilAppControllerService } from 'api';
import { SafeHtmlUrlPipe } from 'app/core/safe-html-url.pipe';
import { StorageService } from '../storage.service';
import { Utils } from '../utils';

@Component({
  selector: 'mohosz-base64-content',
  templateUrl: './base64-content.component.html',
  styleUrls: ['./base64-content.component.scss'],
})
export class Base64ContentComponent implements OnInit, OnChanges {

  @Input() content: Base64Content | undefined;
  @Input() contentId: number | undefined;
  @Input() type: 'image' | 'embed' = 'image';
  @Input() width = '100%';
  @Input() height = '100%';
  @Input() borderRadius: string;

  safeResourceUrl: SafeResourceUrl;

  constructor(
    private safeHtmlUrlPipe: SafeHtmlUrlPipe,
    private mobilAppControllerService: MobilAppControllerService,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.content && this.content) {
      this.safeResourceUrl = this.safeHtmlUrlPipe.transformBase64Content(this.content);
    }
    if (changes.contentId && Utils.hasValue(this.contentId)) {
      const storage = await this.storageService.storage;
      const storageKey = 'htappkep' + String(this.contentId);
      const savedContent = await storage.get(storageKey);
      if(savedContent){
        this.safeResourceUrl = savedContent;
      } else {
        this.mobilAppControllerService.contentBase64Download({contentId: this.contentId}).toPromise()
          .then(result => {
            this.safeResourceUrl = this.safeHtmlUrlPipe.transformBase64Content(result);
            storage.set(storageKey, this.safeResourceUrl);
          });
      }
    }
  }
}
