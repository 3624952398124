export * from './altalanosController.service';
import { AltalanosControllerService } from './altalanosController.service';
export * from './authRestController.service';
import { AuthRestControllerService } from './authRestController.service';
export * from './jogosultsagController.service';
import { JogosultsagControllerService } from './jogosultsagController.service';
export * from './localeChangeController.service';
import { LocaleChangeControllerService } from './localeChangeController.service';
export * from './mobilAppController.service';
import { MobilAppControllerService } from './mobilAppController.service';
export const APIS = [AltalanosControllerService, AuthRestControllerService, JogosultsagControllerService, LocaleChangeControllerService, MobilAppControllerService];
