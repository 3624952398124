import { Component, ViewChild } from '@angular/core';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { ModalController } from '@ionic/angular';
import { ElektronikusFogasNyilvantartas } from 'api';

import { AuthService } from 'app/auth/auth.service';
import { Utils } from 'app/core/utils';
import { HorgaszNapRogzitesModalData } from 'app/fogasi-naplo/horgasz-napok/rogzites-modal/horgasz-nap-rogzites-modal-data';
import { HorgaszNapRogzitesModalPage } from 'app/fogasi-naplo/horgasz-napok/rogzites-modal/horgasz-nap-rogzites-modal-page';
import { ToastService } from 'app/services/toast.service';
import { LastModifiedType } from 'app/shared/last-modified-refresher/last-modified-type';
import { FogasiNaploService } from '../../services/fogasi-naplo.service';

@Component({
  selector: 'mohosz-horgasz-napok',
  templateUrl: './horgasz-napok.page.html',
  styleUrls: ['./horgasz-napok.page.scss'],
})
export class HorgaszNapokPage {

  @ViewChild(MatCalendar) calendar: MatCalendar<Date>;

  eFogasNyilvantartas: ElektronikusFogasNyilvantartas;

  selectedDate = null;
  minDate: Date;
  maxDate: Date;
  horgaszattalToltottNapok: Array<string>;

  protected readonly LastModifiedType = LastModifiedType;

  constructor(
    public modalController: ModalController,
    protected authService: AuthService,
    private fogasiNaploService: FogasiNaploService,
    private toastService: ToastService,
  ) {
  }

  ionViewDidEnter(){
    this.refreshList();
  }

  dateChanged(selectedNap) {
    if (this.horgaszattalToltottNapok) {
      const isTorles = this.horgaszattalToltottNapok.some(hNap => Utils.equalsDate(selectedNap, new Date(hNap)));
      this.openHorgaszNapRogzitesModal(selectedNap, isTorles);
    }
  }

  openHorgaszNapRogzitesModal(selectedNap: string, isTorles: boolean = false) {
    const data: HorgaszNapRogzitesModalData = {
      fogasiNaploId: this.fogasiNaploService.felhasznalo.ervenyesTuristaAllamiJegy.sorszam,
      selectedNap,
      isTorles
    };
    this.modalController.create({
      component: HorgaszNapRogzitesModalPage,
      componentProps: { data },
      cssClass: 'nap-rogzites-modal',
    }).then(modalEl => {
      modalEl.present();
      return modalEl.onDidDismiss();
    }).then(resultData => {
      if (resultData.role === 'backdrop' || resultData.data?.dismissed) {
        this.selectedDate = null;  // levesszuk a nap kijelolest
        this.calendar.activeDate = new Date(selectedNap); // a fokuszt rajtahagyjuk a kijelolt napon
        if (resultData.data?.fogasNyilvantartas) {
          this.refreshList();
        }
      }
    });
  }

  refreshList() {
    this.fogasiNaploService.eFogasiNaploLekerdezes().toPromise()
      .then(eFogasNyilvantartas => {
        this.minDate = Utils.fromServerDate(eFogasNyilvantartas.ervenyessegKezdete);
        this.maxDate = Utils.fromServerDate(eFogasNyilvantartas.ervenyessegVege);
        this.eFogasNyilvantartas = eFogasNyilvantartas;
        this.horgaszattalToltottNapok = eFogasNyilvantartas.horgaszattalToltottNapok;
        this.calendar.updateTodaysDate(); // frissitjuk a naptarban a megjelolt napokat (calendar.dateClass)
      })
      .catch(error => this.toastService.httpError(error));
  }

  dateClass() {
    return (inputDate: Date): MatCalendarCellCssClasses => {
      const highlightDate = this.horgaszattalToltottNapok?.some(hNap => Utils.equalsDate(new Date(hNap), inputDate));
      return highlightDate ? 'moh-horgasz-nap' : undefined;
    };
  }
}
