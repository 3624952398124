/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GPSKoordinata } from './gPSKoordinata';

export interface VedettVizterulet { 
    koordinatak: Array<GPSKoordinata>;
    leiras: string;
    markerKoordinata?: GPSKoordinata;
    megnevezes: string;
    tipus: VedettVizterulet.TipusEnum;
}
export namespace VedettVizterulet {
    export type TipusEnum = 'TILTOTT_VIZTERULET' | 'KORLATOZAS_ALA_VONT_VIZTERULET';
    export const TipusEnum = {
        TILTOTTVIZTERULET: 'TILTOTT_VIZTERULET' as TipusEnum,
        KORLATOZASALAVONTVIZTERULET: 'KORLATOZAS_ALA_VONT_VIZTERULET' as TipusEnum
    };
}