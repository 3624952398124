import { Component, OnInit } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { GPSKoordinata, HorgaszturizmusMapPoi, MobilAppControllerService } from 'api';
import { TimerMutex } from 'app/core/timer-mutex';
import { LifeCycleService } from 'app/services/lifecycle.service';
import { LocationService } from 'app/services/location.service';
import { ToastService } from 'app/services/toast.service';
import { TerkepHelper } from 'app/terkep/shared/terkep-helper';
import * as Leaflet from 'leaflet';
import { ConnectionStateService } from '../../services/connection-state.service';


@Component({
  selector: 'mohosz-terkep-nezet',
  templateUrl: './terkep-nezet.page.html',
  styleUrls: ['./terkep-nezet.page.scss'],
  providers: [LifeCycleService]
})
export class TerkepNezetPage extends OnDestroyMixin implements OnInit {

  locationPos: GPSKoordinata;
  poiList: Array<HorgaszturizmusMapPoi>;
  isLoading = true;

  private timerMutex = new TimerMutex(1);
  private nearByPoisLoaded = false;
  private allPoisLoaded = false;

  constructor(
    private connectionStateService: ConnectionStateService,
    private locationService: LocationService,
    private mobilAppControllerService: MobilAppControllerService,
    private toastService: ToastService,
    private lifeCycleService: LifeCycleService
 ){
    super();
    this.connectionStateService.onlineState.pipe(untilComponentDestroyed(this)).subscribe(state =>
      this.refreshContent()
    );
  }

  ngOnInit(): void {
    this.lifeCycleService.didEnter.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.onViewEnter();
    });
  }

  ionViewDidEnter() {
    this.lifeCycleService.propagateDidEnter();
  }

  onViewEnter(){
    this.nearByPoisLoaded = false;
    this.allPoisLoaded = false;
    this.refreshContent();
  }

  refreshContent(){
    this.timerMutex.runExclusive(() => {
      this.loadPoiList();
    });
  }

  loadPoiList() {
    if(this.connectionStateService.isOnline()){
      if(!this.nearByPoisLoaded) {
        this.isLoading = true;
        this.locationService.getLocation()
          .then(pos => {
            this.locationPos = pos;
            this.mobilAppControllerService.findNearbyMapPois(this.locationPos).toPromise()
              .then(nearbyPois => {
                this.poiList = this.poiList ?? [];
                nearbyPois.forEach(poi => {
                  if (!this.poiList.find(p => TerkepHelper.isEqualGPSKoorinatak(p.koordinata, poi.koordinata))) {
                    this.poiList.push(poi);
                  }
                });
                this.poiList = [...this.poiList];
                this.nearByPoisLoaded = true;
              })
              .catch(error => this.toastService.httpError(error))
              .finally(() => this.isLoading = false);
          })
          .catch(error => this.isLoading = false);
      } else {
        if (!this.allPoisLoaded) {
          this.mobilAppControllerService.findAllPois().toPromise()
            .then(allPois => {
              allPois.forEach(poi => {
                if (!this.poiList.find(p => TerkepHelper.isEqualGPSKoorinatak(p.koordinata, poi.koordinata))) {
                  this.poiList.push(poi);
                }
              });
              this.poiList = [...this.poiList];
              this.allPoisLoaded = true;
            })
            .catch(error => this.toastService.httpError(error));
        }
      }
    } else {
      this.isLoading = false;
    }
  }

  onMapZoomEnd(event: Leaflet.LeafletEvent) {
    this.refreshContent();
  }

  onMapMoveEnd(event: Leaflet.LeafletEvent) {
    this.refreshContent();
  }
}
