import { Pipe, PipeTransform } from '@angular/core';
import { FileUploaderMimeType } from 'app/file-uploader/file-uploader-mime-type.enum';

@Pipe({
  name: 'fileUploaderMimeType'
})
export class FileUploaderMimeTypePipe implements PipeTransform {

  private static transfer(value) {
    switch (value) {
      case FileUploaderMimeType.APK: return 'apk';
      case FileUploaderMimeType.CSV: return 'csv';
      case FileUploaderMimeType.PDF: return 'pdf';
      case FileUploaderMimeType.BMP: return 'bmp';
      case FileUploaderMimeType.JPG: return 'jpg';
      case FileUploaderMimeType.PNG: return 'png';
      case FileUploaderMimeType.XLS: return 'xls';
      default: return value;
    }
  }
  transform(value: any, ...args: Array<any>): any {
    if (value instanceof Array) {
      return value.map(v => FileUploaderMimeTypePipe.transfer(v));
    } else {
      return FileUploaderMimeTypePipe.transfer(value);
    }
  }

}
