import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { KimenoUzenetekPageRoutingModule } from './kimeno-uzenetek-routing.module';

import { KimenoUzenetekPage } from './kimeno-uzenetek.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    KimenoUzenetekPageRoutingModule
  ],
  declarations: [KimenoUzenetekPage]
})
export class KimenoUzenetekPageModule {}
