import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Utils } from 'app/core/utils';

/**
 * @deprecated - base64ContentResource-t kell hasznalni
 */
@Component({
  selector: 'mohosz-image-resource-content',
  templateUrl: './image-resource-content.component.html',
  styleUrls: ['./image-resource-content.component.scss']
})
export class ImageResourceContentComponent implements OnInit, OnChanges {

  @Input() imageResourceId: number;
  @Input() width: string;
  @Input() height: string;
  @Input() borderRadius: string;
  @Input() inaktiv: boolean;

  resourceUrl: string;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //adatlapnak nincs tartalma
    if(changes.imageResourceId){
      this.resourceUrl = undefined;
      if(Utils.hasValue(this.imageResourceId)) {
        //this.resourceUrl = this.apiServiceHelper.getContentUrl(this.imageResourceId);
      }
    }
  }
}
