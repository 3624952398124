/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FlogDetails } from './flogDetails';

export interface Flog { 
    availableCommands?: Array<Flog.AvailableCommandsEnum>;
    details?: FlogDetails;
    fogasIdopont: Date;
    halfajId: number;
    id: number;
    lathatosag?: Flog.LathatosagEnum;
    sajat?: boolean;
    szereploId: number;
    vizteruletId: number;
}
export namespace Flog {
    export type AvailableCommandsEnum = 'SZERKESZTES' | 'TORLES' | 'JOVAHAGYAS' | 'VISSZAUTASITAS';
    export const AvailableCommandsEnum = {
        SZERKESZTES: 'SZERKESZTES' as AvailableCommandsEnum,
        TORLES: 'TORLES' as AvailableCommandsEnum,
        JOVAHAGYAS: 'JOVAHAGYAS' as AvailableCommandsEnum,
        VISSZAUTASITAS: 'VISSZAUTASITAS' as AvailableCommandsEnum
    };
    export type LathatosagEnum = 'PUBLIKUSNAK_JELOLT' | 'PUBLIKUS' | 'ISMEROS' | 'PRIVAT';
    export const LathatosagEnum = {
        PUBLIKUSNAKJELOLT: 'PUBLIKUSNAK_JELOLT' as LathatosagEnum,
        PUBLIKUS: 'PUBLIKUS' as LathatosagEnum,
        ISMEROS: 'ISMEROS' as LathatosagEnum,
        PRIVAT: 'PRIVAT' as LathatosagEnum
    };
}