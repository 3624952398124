import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Base64Content } from 'api';

@Pipe({
  name: 'safeHtmlUrl'
})
export class SafeHtmlUrlPipe implements PipeTransform {

  constructor(private sanitized: DomSanitizer) {
  }

  transform(url: string) {
    return this.sanitized.bypassSecurityTrustResourceUrl(url);
  }

  transformBase64Content(base64Content: Base64Content) {
    return base64Content ? 'data:' + base64Content.httpMediaType + ';base64,' + base64Content.content : undefined;
  }

}
