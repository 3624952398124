/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Halmatrix } from './halmatrix';
import { Horgaszmodszer } from './horgaszmodszer';

export interface HorgasztatasSzolgaltatasTorzsadat { 
    felszereltsegList?: Array<HorgasztatasSzolgaltatasTorzsadat.FelszereltsegListEnum>;
    halmatrixList?: Array<Halmatrix>;
    horgaszmodszerList?: Array<Horgaszmodszer>;
    lepesVegrehajtasiSorrend?: Array<HorgasztatasSzolgaltatasTorzsadat.LepesVegrehajtasiSorrendEnum>;
}
export namespace HorgasztatasSzolgaltatasTorzsadat {
    export type FelszereltsegListEnum = 'BERELHETO_HORGASZHELY' | 'HORGASZTANYA' | 'SOLYAPALYA';
    export const FelszereltsegListEnum = {
        BERELHETOHORGASZHELY: 'BERELHETO_HORGASZHELY' as FelszereltsegListEnum,
        HORGASZTANYA: 'HORGASZTANYA' as FelszereltsegListEnum,
        SOLYAPALYA: 'SOLYAPALYA' as FelszereltsegListEnum
    };
    export type LepesVegrehajtasiSorrendEnum = 'ALAPADATOK' | 'ELHELYEZKEDES' | 'HORGASZREND' | 'FENYKEPEK' | 'JELLEMZOK' | 'HALALLOMANY' | 'OSSZEGZES';
    export const LepesVegrehajtasiSorrendEnum = {
        ALAPADATOK: 'ALAPADATOK' as LepesVegrehajtasiSorrendEnum,
        ELHELYEZKEDES: 'ELHELYEZKEDES' as LepesVegrehajtasiSorrendEnum,
        HORGASZREND: 'HORGASZREND' as LepesVegrehajtasiSorrendEnum,
        FENYKEPEK: 'FENYKEPEK' as LepesVegrehajtasiSorrendEnum,
        JELLEMZOK: 'JELLEMZOK' as LepesVegrehajtasiSorrendEnum,
        HALALLOMANY: 'HALALLOMANY' as LepesVegrehajtasiSorrendEnum,
        OSSZEGZES: 'OSSZEGZES' as LepesVegrehajtasiSorrendEnum
    };
}