import { DomSanitizer } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from 'app/core/utils';


@Pipe({name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitized: DomSanitizer) {
  }

  transform(value) {
    return Utils.hasValue(value) ? this.sanitized.bypassSecurityTrustHtml(value) : '';
  }
}
