<ion-content class="moh-bg-light">

  <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <ion-grid>
    <ion-row>
      <ion-col size-md="6" offset-md="3">

        <ion-list>
          <ion-list-header>
            <ion-title size="large">Újak</ion-title>
          </ion-list-header>
          <ng-container *ngIf="olvasatlanUzenetList else emptyList">
            <ng-container *ngFor="let olvasatlan of olvasatlanUzenetList">
              <mohosz-uzenet-list-item
                (click)="onUzenetListItemClick(olvasatlan)"
                (igenClicked)="onValaszClick(olvasatlan, true)"
                (nemClicked)="onValaszClick(olvasatlan, false)"
                [uzenet]="olvasatlan"
                [selected]="olvasatlan === selectedUzenet"
              ></mohosz-uzenet-list-item>
            </ng-container>
          </ng-container>
          <ng-template #emptyList>
            <p class="note">Nincs új üzenet</p>
          </ng-template>
        </ion-list>

        <ion-list>
          <ion-list-header>
            <ion-title size="large">Korábbiak</ion-title>
          </ion-list-header>
          <ng-container *ngIf="olvasottUzenetList else emptyList">
            <ng-container *ngFor="let olvasott of olvasottUzenetList">
              <mohosz-uzenet-list-item
                (click)="onUzenetListItemClick(olvasott)"
                (igenClicked)="onValaszClick(olvasott, true)"
                (nemClicked)="onValaszClick(olvasott, false)"
                [uzenet]="olvasott"
                [selected]="olvasott === selectedUzenet"
              ></mohosz-uzenet-list-item>
            </ng-container>
          </ng-container>
          <ng-template #emptyList>
            <p class="note">Nincs korábbi üzenet</p>
          </ng-template>
        </ion-list>

      </ion-col>
    </ion-row>

  </ion-grid>

</ion-content>
