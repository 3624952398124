<div class="map-container">
  <div leaflet id="htmap"
     [leafletOptions]="mapOptions"
     (leafletMapReady)="onMapReady($event)"
     (leafletMapZoomEnd)="onMapZoomEnd($event)"
     (leafletMapMoveEnd)="onMapMoveEnd($event)">
  </div>
  <div *ngIf="selectedMarker" class="poi-popup-container scrollbar-strait grey-scrollbar">
    <mohosz-horgaszturizmus-poi-card
      [isLoading]="isLoading"
      [poi]="selectedMarker.poiItem"
      (closePoiPopup)="onClosePopup()">
    </mohosz-horgaszturizmus-poi-card>
  </div>
</div>

