import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { routesConfig } from 'config/routesConfig';
import { AuthGuard } from './auth/auth.guard';
import { AuthPageModule } from './auth/auth.module';
import { TabsPageModule } from './tabs/tabs.module';

const routes: Routes = [
  { path: '', redirectTo: routesConfig.tabs, pathMatch: 'full' },
  { path: routesConfig.bejelentkezes, loadChildren: () => AuthPageModule },
  { path: routesConfig.tabs, loadChildren: () => TabsPageModule, canActivate: [AuthGuard] },
  { path: '**', redirectTo: routesConfig.tabs }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
