import { Injectable, Optional, SkipSelf } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable, Subject } from 'rxjs';


@Injectable()
export class LifeCycleService extends OnDestroyMixin {

  didEnter: Observable<any>;
  private didEnterSubject: Subject<any> = new Subject();

  constructor(
    @Optional() @SkipSelf() lifeCycleService: LifeCycleService
  ) {
    super();
    this.didEnter = this.didEnterSubject.asObservable();
    lifeCycleService?.didEnter.pipe(untilComponentDestroyed(this)).subscribe(() => this.didEnterSubject.next());
  }

  propagateDidEnter() {
    this.didEnterSubject.next();
  }
}
