import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PostafiokItem } from 'api';

@Component({
  selector: 'mohosz-uzenet-list-item',
  templateUrl: './uzenet-list-item.component.html',
  styleUrls: ['../uzenet.scss'],
})
export class UzenetListItemComponent implements OnInit {

  @Input() uzenet: PostafiokItem;
  @Input() selected: boolean;

  @Output() igenClicked: EventEmitter<boolean> = new EventEmitter();
  @Output() nemClicked: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  onIgenClick(event) {
    event.preventDefault();
    event.stopPropagation();
    this.igenClicked.emit(true);
  }

  onNemClick(event) {
    event.preventDefault();
    event.stopPropagation();
    this.nemClicked.emit(true);
  }
}
