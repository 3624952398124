import {Injectable} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Configuration, UploadFileResponse} from 'api';
import {FileUploaderWrapper} from 'app/file-uploader/file-uplader-wrapper';
import {FileUploaderConfiguration} from 'app/file-uploader/file-uploader-configuration';
import {FileUploaderResult} from 'app/file-uploader/file-uploader-result';
import {FileItem, FileLikeObject, FileUploader} from 'ng2-file-upload';
import {Subscription} from 'rxjs';
import {KepSzerkesztoDialogComponent} from '../altalanos/kep-szerkeszto-dialog/kep-szerkeszto-dialog.component';
import {KepSzerkesztoDialogService} from '../altalanos/kep-szerkeszto-dialog/kep-szerkeszto-dialog.service';
import {KepSzerkesztoDialogData} from '../altalanos/kep-szerkeszto-dialog/kep-szerkeszto-models';
import {AuthService} from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploaderService {
  constructor(
    private authService: AuthService,
    private apiConfiguration: Configuration,
    private kepSzerkesztoDialogService: KepSzerkesztoDialogService,
  ) {
   }

   buildFileUploaderWrapper(configuration: FileUploaderConfiguration): FileUploaderWrapper {
     const uploaderWrapper: FileUploaderWrapper = new FileUploaderWrapper(
       new FileUploader({
         url: this.apiConfiguration.basePath + configuration.uploadPrefix,
         maxFileSize: configuration.maxFileSizeInMb * 1024 * 1024,
         additionalParameter: configuration.additionalParameter,
         allowedMimeType: configuration.realAllowedMimeTypes(),
         headers: [{name: 'X-Auth-Token', value: this.authService.fetchAuthToken()}]
       })
     );
     let kepvagoSubscriptions: Array<Subscription> = [];
     let dialogRef: MatDialogRef<KepSzerkesztoDialogComponent, File>;

     uploaderWrapper.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
       const result: FileUploaderResult = new FileUploaderResult();
       result.mimeType = item.type;
       if (!configuration.isAllowedMimeType(item)) {
         result.errors = {wrongType: true};
       } else if (!configuration.isAllowedFileSize(item)) {
         result.errors = {maxFileSize: true};
       } else {
         result.errors = {clientError: true};
       }
       uploaderWrapper.resultSubject.next(result);
     };
     uploaderWrapper.uploader.onAfterAddingFile = (file: FileItem) => {
       if (configuration.kepszerkeszto) {
         dialogRef = this.kepSzerkesztoDialogService.openKepszerkesztoDialog(
           new KepSzerkesztoDialogData(file, configuration.kepszerkeszto.formatum));
         // képvágó hiba
         kepvagoSubscriptions.push(dialogRef.componentInstance.cropperError.subscribe(error => {
           const result: FileUploaderResult = new FileUploaderResult();
           result.errors = {cropperError: error};
           uploaderWrapper.resultSubject.next(result);
           uploaderWrapper.uploader.cancelItem(file);
           dialogRef.close();
         }));
         // képvágó bezárás mentés nélkül
         kepvagoSubscriptions.push(dialogRef.componentInstance.cropperClose
           .subscribe(() => {
             const result: FileUploaderResult = new FileUploaderResult();
             result.canceled = true;
             uploaderWrapper.resultSubject.next(result);
             uploaderWrapper.uploader.cancelItem(file);
             dialogRef.close();
           }));
         // képvágó mentés
         kepvagoSubscriptions.push(dialogRef.componentInstance.kepSaveClicked
           .subscribe(croppedImage => {
             dialogRef.componentInstance.uploadInProgress = true;
             file._file = croppedImage;
             uploaderWrapper.uploader.uploadItem(file);
           }));
         dialogRef.afterClosed().toPromise().finally(() => {
           kepvagoSubscriptions.forEach(subs => subs.unsubscribe());
           kepvagoSubscriptions = [];
         });
       } else {
         uploaderWrapper.uploader.uploadItem(file);
       }
     };
     uploaderWrapper.uploader.onCompleteItem = (item: FileItem, response: any, status: any, headers: any) => {
       const result: FileUploaderResult = new FileUploaderResult();
       result.mimeType = item.file.type;
       if (dialogRef) {
         dialogRef.componentInstance.uploadInProgress = false;
         dialogRef.close();
       }

       uploaderWrapper.uploader.clearQueue();
       const ufResp = response ? JSON.parse(response) : null;
       if (status === 200) {
         result.id = ufResp.id;
         result.name = item.file.name;
         result.uploadedFile = ufResp as UploadFileResponse;
       } else if ((status === 500 || status === 400) && ufResp?.message) {
         result.errors = {serverError: ufResp.message};
       } else {
         result.errors = {unknownServerError: true};
       }
       uploaderWrapper.resultSubject.next(result);
     };
     return uploaderWrapper;
   }
}
