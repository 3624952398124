import { FileUploaderErrors } from 'app/file-uploader/file-uploader-errors';
import { UploadFileResponse } from 'api';

export class FileUploaderResult {
  id?: number;
  name?: string;
  mimeType: string;
  errors?: FileUploaderErrors;
  canceled: boolean;
  uploadedFile: UploadFileResponse;

  isSuccess(): boolean {
    return !this.errors && !this.canceled;
  }
}
