import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { KepSzerkesztoDialogData } from './kep-szerkeszto-models';
import { KepSzerkesztoDialogComponent } from './kep-szerkeszto-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class KepSzerkesztoDialogService {

  constructor(
    private dialog: MatDialog
  ) { }

  public openKepszerkesztoDialog(data: KepSzerkesztoDialogData): MatDialogRef<KepSzerkesztoDialogComponent, File> {
    return this.dialog.open<KepSzerkesztoDialogComponent>(
      KepSzerkesztoDialogComponent,
      {
        data,
        disableClose: true
      });
  }
}
