<ng-container *ngIf="uploadInProgress">
  <ion-spinner></ion-spinner>
</ng-container>
<mat-dialog-content>
  <h4 class="ion-no-margin-top">Kép szerkesztése</h4>
  <image-cropper [maintainAspectRatio]="true" [imageFile]="data.file._file" [cropperMinWidth]="minWidth"
                 [cropperMinHeight]="minHeight"
                 [aspectRatio]="aspectRatio" [format]="type"
                 (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)"
                 class="cropper">
  </image-cropper>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <ion-button fill="outline" [size]="'small'" (click)="onCloseClick()">Mégsem</ion-button>
  <ion-button [size]="'small'" (click)="onKepSave()" [disabled]="!fileToReturn">Mentés</ion-button>
</mat-dialog-actions>
