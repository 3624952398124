/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Base64Content } from './base64Content';

export interface PostafiokItem { 
    cim?: string;
    cimzettValasz?: boolean;
    feladoNev?: string;
    kuldesDatum?: Date;
    leiras?: string;
    monogram?: string;
    olvasott?: boolean;
    profilKep?: Base64Content;
    sourceId?: number;
    uzenetValaszLehetoseg?: boolean;
}