<ion-header class="ion-no-border">
  <ion-toolbar color="secondary">
    <ion-buttons slot="primary" class="ion-margin-top" *ngIf="felhasznalo">
      <mohosz-header-notification></mohosz-header-notification>
    </ion-buttons>
    <ion-title>
      <ion-img src="assets/logo/logo-light-mohosz.svg" alt="MOHOSZ - Magyar Országos Horgász Szövetség"></ion-img>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="secondary" *ngIf="felhasznalo">
   <ion-list>
     <!-- user profil  -->
     <ion-item-group>
      <!-- detail directiva teszi ki a nyilacskát az item vegere -->
       <ion-item class="user-profil-lg" lines="none" button>
         <ion-avatar slot="start">
           <mohosz-base64-content *ngIf="felhasznalo.profilkep; else monogram" [content]="felhasznalo.profilkep" borderRadius="50%"></mohosz-base64-content>
           <ng-template #monogram>
             <span monogram [nev]="felhasznalo.vezeteknev+' '+felhasznalo.keresztnev"></span>
           </ng-template>
         </ion-avatar>
         <ion-label>
           {{felhasznalo.vezeteknev}} {{felhasznalo.keresztnev}}
<!--           <br>-->
<!--           <span><ion-router-link class="underline" routerLink="/tabs/menu">Profil beállítások</ion-router-link></span>-->
         </ion-label>
       </ion-item>
       <ion-item-divider></ion-item-divider>
     </ion-item-group>
     <!-- menu -->
     <ion-item-group *ngFor="let group of menuGroups">
       <ion-menu-toggle auto-hide="false" *ngFor="let item of group">
         <ion-item [routerLink]="item.route" [disabled]="item.disabled" lines="full" detail>
           <ion-label>{{item.label}}</ion-label>
         </ion-item>
       </ion-menu-toggle>
       <ion-item-divider></ion-item-divider>
     </ion-item-group>
     <!-- logout -->
     <ion-item-group>
       <ion-item (click)="onLogout()" lines="full" button detail>
         <ion-label>Kijelentkezés</ion-label>
       </ion-item>
     </ion-item-group>
  </ion-list>
</ion-content>
