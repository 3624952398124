import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Flog } from 'api';
import { ConnectionStateService } from 'app/services/connection-state.service';
import { routesConfig } from 'config/routesConfig';
import { FogasaimService } from '../fogasaim.service';

@Component({
  selector: 'mohosz-flog-card',
  templateUrl: './flog-card.component.html',
  styleUrls: ['./flog-card.component.scss']
})
export class FlogCardComponent implements OnInit {

  @Input() flog: Flog;
  LathatosagEnum = Flog.LathatosagEnum;
  AvailableCommandsEnum = Flog.AvailableCommandsEnum;

  constructor(
    private fogasaimService: FogasaimService,
    private router: Router,
    private connectionStateService: ConnectionStateService
  ) { }

  ngOnInit(): void {
  }

  onFogasSzerkesztesClick() {
    if(this.connectionStateService.isOnline()){
      this.fogasaimService.setKivalasztottFogas(this.flog);
      this.router.navigateByUrl(`/${routesConfig.tabs}/${routesConfig.fogasaim}/${routesConfig.szerkesztes}`);
    }
  }

}
