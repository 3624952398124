<ion-content [fullscreen]="true" class="moh-bg-light">

  <ion-fab slot="fixed" vertical="bottom" horizontal="end" *ngIf="!poiPopupIsOpen">
    <ion-fab-button size="small" (click)="onAddKedvencHorgaszhelyClick()">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <ng-container *ngIf="kedvencHorgaszHelyList; else noContent">
    <mohosz-kedvenc-horgaszhely-terkep #terkep
      [kedvencHorgaszhelyList]="kedvencHorgaszHelyList"
      [locationPos]="locationPos"
      [kivalasztottKedvencHorgaszhely]="kivalasztottKedvencHorgaszhely"
      (markerSelectionChanged)="onMarkerSelectionChanged($event)">
    </mohosz-kedvenc-horgaszhely-terkep>
  </ng-container>

  <ng-template #noContent>
    <mohosz-no-content [isLoading]="isLoading" noContentText="Nincs kedvenc horgászhely"></mohosz-no-content>
  </ng-template>
</ion-content>
