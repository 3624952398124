<ng-container *ngIf="configuration">
  <ng-container *ngIf="configuration.feltoltoGomb; else alapBeallitas">
    <div class="file-kivalasztasa-wrapper" [class.hidden]="disabled">
      <ion-icon name="cloud-upload-outline" class="cloud-icon"></ion-icon>
      <p>
        Válassz ki egy képet a telefonodról, vagy készíts egy fotót!
      </p>
      <p class="hint-upload-wrapper" *ngIf="configuration.showHint">
        <mat-hint>
          Maximum {{configuration.maxFileSizeInMb}} MB méretű fájl tölthető fel, {{configuration.allowedMimeTypes | fileUploaderMimeType}} formátumban.
        </mat-hint>
      </p>
      <ion-button type="button" size="small"
              [disabled]="fileUploaderWrapper.uploader.isUploading"
              (click)="onUploadClick()">

        <ng-container *ngIf="configuration.feltoltoGomb.szoveg">
          {{configuration.feltoltoGomb.szoveg}}
        </ng-container>
        <input type="file" ng2FileSelect #uploaderInput
               [uploader]="fileUploaderWrapper.uploader"
               [accept]="configuration.enableFilterByMimeType ? configuration.allowedMimeTypes : undefined"/>
      </ion-button>

      <ng-container *ngIf="fileUploaderResult && configuration.showResult">
        {{fileUploaderResult.name}}
      </ng-container>
    </div>
  </ng-container>
  <ng-template #alapBeallitas>
    <input id="uploader" type="file" ng2FileSelect
           [accept]="configuration.enableFilterByMimeType ? configuration.allowedMimeTypes : undefined"
           [uploader]="fileUploaderWrapper.uploader"/>

    <div class="hint-upload-wrapper" *ngIf="configuration.showHint">
      <mat-hint>
        Maximum {{configuration.maxFileSizeInMb}} MB méretű fájl tölthető fel, {{configuration.allowedMimeTypes | fileUploaderMimeType}} formátumban.
      </mat-hint>
    </div>
  </ng-template>

  <ng-container *ngIf="configuration.isProgressBar">
    <mat-progress-bar *ngIf="fileUploaderWrapper.uploader.isUploading" mode="determinate" [value]="fileUploaderWrapper.uploader.progress"></mat-progress-bar>
  </ng-container>

  <ng-container *ngIf="configuration.defaultResultMessages">
    <div *ngIf="fileUploaderResult">
      <ng-container *ngIf="fileUploaderResult.errors as error; else uploadSuccess">
        <ion-note slot="error" color="danger">
          Sikertelen művelet,
          <ng-container *ngIf="error.wrongType">hibás file típus ({{fileUploaderResult.mimeType}}), megengedett: {{configuration.allowedMimeTypes | fileUploaderMimeType}}</ng-container>
          <ng-container *ngIf="error.maxFileSize">a file mérete nem megfelelő, max méret: {{configuration.maxFileSizeInMb}} MB</ng-container>
          <ng-container *ngIf="error.cropperError">{{error.cropperError}}</ng-container>
          <ng-container *ngIf="error.clientError">kliens hiba történt a fájlfeltöltés során</ng-container>
          <ng-container *ngIf="error.serverError">{{error.serverError}}</ng-container>
          <ng-container *ngIf="error.unknownServerError">szerver hiba történt a fájlfeltöltés során</ng-container>
        </ion-note>
      </ng-container>
      <ng-template #uploadSuccess>
        <ion-note color="success" *ngIf="!fileUploaderResult.canceled">
          Sikeres feltöltés.
        </ion-note>
      </ng-template>
    </div>
  </ng-container>

</ng-container>
