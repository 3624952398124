import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { VersenyzoiEngedelyPageRoutingModule } from './versenyzoi-engedely-routing.module';

import { VersenyzoiEngedelyPage } from './versenyzoi-engedely.page';
import { AltalanosModule } from 'app/altalanos/altalanos.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    VersenyzoiEngedelyPageRoutingModule,
    AltalanosModule,
  ],
  declarations: [VersenyzoiEngedelyPage]
})
export class VersenyzoiEngedelyPageModule {}
