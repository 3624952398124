/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FlogModositasRequest { 
    csali?: string;
    etetoanyag?: string;
    farasztasPerc?: number;
    fogasIdopont: Date;
    fogasModszer?: FlogModositasRequest.FogasModszerEnum;
    halfajId: number;
    hossz?: number;
    id: number;
    kepId: number;
    kerulet?: number;
    lathatosag: FlogModositasRequest.LathatosagEnum;
    suly: number;
    visszaengedett?: boolean;
    vizteruletId: number;
}
export namespace FlogModositasRequest {
    export type FogasModszerEnum = 'KLASSZIKUS_FENEKEZES' | 'BOJLIZAS_DOBASSAL_BEHUZASSAL' | 'FEEDER_BOTOS_HORGASZAT' | 'RAGADOZO_HALAS_FENEKEZOS_HORGASZAT' | 'SPICCBOTOS_RAKOSBOTOS_HORGASZAT' | 'KLASSZIKUS_USZOZAS' | 'HARCSAZAS_BEHUZASSAL' | 'MATCH_BOTOS_HORGASZAT' | 'USZOS_RAGADOZO_HALAS_HORGASZAT' | 'WOBBLEREZES' | 'VILLANTOZAS' | 'GUMIHALAZAS_TWISTEREZES' | 'MARTOGATAS_TAPOGATAS' | 'KUTTYOGATAS' | 'LEKHORGASZAT_MORMISKAZAS' | 'MULEGYEZO_HORGASZAT';
    export const FogasModszerEnum = {
        KLASSZIKUSFENEKEZES: 'KLASSZIKUS_FENEKEZES' as FogasModszerEnum,
        BOJLIZASDOBASSALBEHUZASSAL: 'BOJLIZAS_DOBASSAL_BEHUZASSAL' as FogasModszerEnum,
        FEEDERBOTOSHORGASZAT: 'FEEDER_BOTOS_HORGASZAT' as FogasModszerEnum,
        RAGADOZOHALASFENEKEZOSHORGASZAT: 'RAGADOZO_HALAS_FENEKEZOS_HORGASZAT' as FogasModszerEnum,
        SPICCBOTOSRAKOSBOTOSHORGASZAT: 'SPICCBOTOS_RAKOSBOTOS_HORGASZAT' as FogasModszerEnum,
        KLASSZIKUSUSZOZAS: 'KLASSZIKUS_USZOZAS' as FogasModszerEnum,
        HARCSAZASBEHUZASSAL: 'HARCSAZAS_BEHUZASSAL' as FogasModszerEnum,
        MATCHBOTOSHORGASZAT: 'MATCH_BOTOS_HORGASZAT' as FogasModszerEnum,
        USZOSRAGADOZOHALASHORGASZAT: 'USZOS_RAGADOZO_HALAS_HORGASZAT' as FogasModszerEnum,
        WOBBLEREZES: 'WOBBLEREZES' as FogasModszerEnum,
        VILLANTOZAS: 'VILLANTOZAS' as FogasModszerEnum,
        GUMIHALAZASTWISTEREZES: 'GUMIHALAZAS_TWISTEREZES' as FogasModszerEnum,
        MARTOGATASTAPOGATAS: 'MARTOGATAS_TAPOGATAS' as FogasModszerEnum,
        KUTTYOGATAS: 'KUTTYOGATAS' as FogasModszerEnum,
        LEKHORGASZATMORMISKAZAS: 'LEKHORGASZAT_MORMISKAZAS' as FogasModszerEnum,
        MULEGYEZOHORGASZAT: 'MULEGYEZO_HORGASZAT' as FogasModszerEnum
    };
    export type LathatosagEnum = 'PUBLIKUSNAK_JELOLT' | 'PUBLIKUS' | 'ISMEROS' | 'PRIVAT';
    export const LathatosagEnum = {
        PUBLIKUSNAKJELOLT: 'PUBLIKUSNAK_JELOLT' as LathatosagEnum,
        PUBLIKUS: 'PUBLIKUS' as LathatosagEnum,
        ISMEROS: 'ISMEROS' as LathatosagEnum,
        PRIVAT: 'PRIVAT' as LathatosagEnum
    };
}