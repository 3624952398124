import { Pipe, PipeTransform } from '@angular/core';
import { VersenyzoiEngedely } from 'api';

@Pipe({
  name: 'versenyzoiEngedelyKorkategoria'
})
export class VersenyzoiEngedelyKorkategoriaPipe implements PipeTransform {

  transform(value: VersenyzoiEngedely.KorkategoriaEnum): string {
    switch (value){
      case VersenyzoiEngedely.KorkategoriaEnum.FELNOTT: return 'Felnőtt';
      case VersenyzoiEngedely.KorkategoriaEnum.IFJUSAGI18ALATTI: return '18 év alatti';
      case VersenyzoiEngedely.KorkategoriaEnum.KISKORU14ALATTI: return '14 év alatti';
      default: return value;
    }
  }

}
