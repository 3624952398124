<ion-card *ngIf="horgaszmodszerAltipusList?.length > 0">
  <ion-card-header>
    <ion-card-title>Horgászmódszerek</ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <ion-list class="horgaszmodszer">
      <ion-item *ngFor="let item of horgaszmodszerAltipusList" lines="none">
        <ion-icon name="checkmark-circle-outline" color="primary" slot="start"></ion-icon>
        <ion-label slot="none">{{item}}</ion-label>
        {{item | horgaszmodszer}}
      </ion-item>
    </ion-list>
  </ion-card-content>
</ion-card>


