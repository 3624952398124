import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { HorgaszEngedelyekPageRoutingModule } from './horgasz-engedelyek-routing.module';

import { HorgaszEngedelyekPage } from './horgasz-engedelyek.page';
import { CoreModule } from 'app/core/core.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CoreModule,
    HorgaszEngedelyekPageRoutingModule
  ],
  declarations: [
    HorgaszEngedelyekPage
  ]
})
export class HorgaszEngedelyekPageModule {}
