import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import { PositionOptions } from '@capacitor/geolocation/dist/esm/definitions';
import { AlertController } from '@ionic/angular';
import { GPSKoordinata } from 'api';


@Injectable({
  providedIn: 'root'
})
export class LocationService {

  positionOptions: PositionOptions = {
    timeout: 1000*10,
    enableHighAccuracy: true,
    maximumAge: 0
  } as PositionOptions;

  constructor(private alertController: AlertController) {
    this.getLocation().then(gps =>
      console.log('GPS received: ' + gps.hosszusagiFok + ', ' + gps.szelessegiFok)
    );
  }

  async getLocation(): Promise<GPSKoordinata> {
    if (Capacitor.isPluginAvailable('Geolocation')) {
      try {
        const pos = await Geolocation.getCurrentPosition(this.positionOptions);
        //return { szelessegiFok: 46.782002, hosszusagiFok: 21.347727 } as GPSKoordinata;//nincs poi talalat
        return { szelessegiFok: pos.coords.latitude, hosszusagiFok: pos.coords.longitude } as GPSKoordinata;
      } catch (error) {
        this.showErrorAlert();
      }
    } else {
      this.showErrorAlert();
    }
  }

  private showErrorAlert() {
    const errorMsg = 'Nem sikerült a helymeghatározás.';
    this.alertController.create({
      header: errorMsg,
      message: 'Engedélyezze a helymeghatározást az eszközön.',
      buttons: ['OK']
    }).then(alertEl => alertEl.present());
    throw new Error(errorMsg);
  }
}
