import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HorgaszkartyaPageRoutingModule } from './horgaszkartya-routing.module';
import { HorgaszkartyaPage } from './horgaszkartya.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    HorgaszkartyaPageRoutingModule
  ],
    declarations: [
      HorgaszkartyaPage,
    ]
})
export class HorgaszkartyaPageModule {}
