import {Pipe, PipeTransform} from '@angular/core';
import {TeruletiJegy} from 'api';

@Pipe({
    name: 'termekIdoszak'
})
export class TermekIdoszakPipe implements PipeTransform {
    transform(value: TeruletiJegy.IdoszakEnum, args?: any): any {
        switch (value) {
            case TeruletiJegy.IdoszakEnum.EVES: return 'Éves';
            case TeruletiJegy.IdoszakEnum.FELEVES: return 'Féléves';
            case TeruletiJegy.IdoszakEnum.EVESRESZLET1: return 'Éves részlet1';
            case TeruletiJegy.IdoszakEnum.EVESRESZLET2: return 'Éves részlet2';
            case TeruletiJegy.IdoszakEnum.NAPI: return 'Napi';
            case TeruletiJegy.IdoszakEnum.ORA24: return '24 órás';
            case TeruletiJegy.IdoszakEnum.ORA48: return '48 órás';
            case TeruletiJegy.IdoszakEnum.ORA72: return '72 órás';
            case TeruletiJegy.IdoszakEnum.NAP2: return '2 napos';
            case TeruletiJegy.IdoszakEnum.NAP3: return '3 napos';
            case TeruletiJegy.IdoszakEnum.NAP7: return '7 napos';
            case TeruletiJegy.IdoszakEnum.NAP10: return '10 napos';
            case TeruletiJegy.IdoszakEnum.EJSZAKAI: return 'Éjszakai';
            default: return value;
        }
    }
}
