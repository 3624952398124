import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AltalanosModule } from 'app/altalanos/altalanos.module';
import { CoreModule } from 'app/core/core.module';
import { SharedModule } from 'app/shared/shared.module';
import { HorgasztatasListItemComponent } from './horgasztatas-list-item/horgasztatas-list-item.component';
import { ListaNezetPageRoutingModule } from './lista-nezet-routing.module';
import { ListaNezetPage } from './lista-nezet.page';




@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ListaNezetPageRoutingModule,
    AltalanosModule,
    SharedModule,
    CoreModule
  ],
  exports: [
  ],
  declarations: [
    ListaNezetPage,
    HorgasztatasListItemComponent
  ]
})
export class ListaNezetPageModule {
}
