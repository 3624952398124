<ng-container *ngIf="control.errors; let errors">
  <span *ngIf="errors.required && (requiredError === 'display' || (requiredError === 'dirty or touched') && (control.dirty || control.touched))">
    Kötelező mező.
  </span>
  <span *ngIf="errors.email">E-mail cím formátuma nem megfelelő</span>
  <span *ngIf="serverError && errors.server">{{errors.server.message}}</span>
  <span *ngIf="minError && errors.min">{{minError}}</span>
  <span *ngIf="maxError && errors.max">{{maxError}}</span>
  <span *ngIf="minLengthError && errors.minLength">{{minLengthError}}</span>
  <span *ngIf="maxLengthError && errors.maxLength">{{maxLengthError}}</span>
  <span *ngIf="mismatchError && errors.mismatch">{{mismatchError}}</span>
</ng-container>
