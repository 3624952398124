import { Pipe, PipeTransform } from '@angular/core';
import { Horgaszmodszer } from 'api';

@Pipe({
  name: 'horgaszmodszer'
})
export class HorgaszmodszerPipe implements PipeTransform {

  transform(value: any, ...args: Array<any>): any {
    switch (value) {
      case Horgaszmodszer.HorgaszmodszerEnum.EGYEBHORGASZMODSZEREK: return 'Egyéb horgászmódszerek';
      case Horgaszmodszer.HorgaszmodszerEnum.FENEKEZOHORGASZAT: return 'Fenekező horgászat';
      case Horgaszmodszer.HorgaszmodszerEnum.MULEGYEZOHORGASZAT: return 'Műlegyező horgászat';
      case Horgaszmodszer.HorgaszmodszerEnum.PERGETOHORGASZAT: return 'Pergető horgászat';
      case Horgaszmodszer.HorgaszmodszerEnum.USZOSHORGASZAT: return 'Úszós horgászat';

      case Horgaszmodszer.HorgaszmodszerAltipusListEnum.BOJLIZASDOBASSALBEHUZASSAL: return 'Bojlizás dobással/behúzással';
      case Horgaszmodszer.HorgaszmodszerAltipusListEnum.KLASSZIKUSUSZOZAS: return 'Klasszikus úszózás';
      case Horgaszmodszer.HorgaszmodszerAltipusListEnum.FEEDERBOTOSHORGASZAT: return 'Feeder botos horgászat';
      case Horgaszmodszer.HorgaszmodszerAltipusListEnum.VILLANTOZAS: return 'Villantózás';
      case Horgaszmodszer.HorgaszmodszerAltipusListEnum.KLASSZIKUSFENEKEZES: return 'Klasszikus fenekezés';
      case Horgaszmodszer.HorgaszmodszerAltipusListEnum.KUTTYOGATAS: return 'Kuttyogatás';
      case Horgaszmodszer.HorgaszmodszerAltipusListEnum.MARTOGATASTAPOGATAS: return 'Mártogatás tapogatás';
      case Horgaszmodszer.HorgaszmodszerAltipusListEnum.MATCHBOTOSHORGASZAT: return 'Match botos horgászat';
      case Horgaszmodszer.HorgaszmodszerAltipusListEnum.LEKHORGASZATMORMISKAZAS: return 'Lékhorgászat / mormiskázás';
      case Horgaszmodszer.HorgaszmodszerAltipusListEnum.RAGADOZOHALASFENEKEZOSHORGASZAT: return 'Ragadozóhalas fenekezős horgászat';
      case Horgaszmodszer.HorgaszmodszerAltipusListEnum.HARCSAZASBEHUZASSAL: return 'Harcsázás behúzással';
      case Horgaszmodszer.HorgaszmodszerAltipusListEnum.SPICCBOTOSRAKOSBOTOSHORGASZAT: return 'Spiccbotos/rakósbotos horgászat';
      case Horgaszmodszer.HorgaszmodszerAltipusListEnum.USZOSRAGADOZOHALASHORGASZAT: return 'Úszós ragadozóhalas horgászat';
      case Horgaszmodszer.HorgaszmodszerAltipusListEnum.WOBBLEREZES: return 'Wobblerezés';
      case Horgaszmodszer.HorgaszmodszerAltipusListEnum.GUMIHALAZASTWISTEREZES: return 'Gumihalazás/twisterezés';
      case Horgaszmodszer.HorgaszmodszerAltipusListEnum.MULEGYEZOHORGASZAT: return 'Műlegyező horgászat';
      default: return value;
    }
  }

}
