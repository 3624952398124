import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NgSelectModule} from '@ng-select/ng-select';

import {CoreModule} from 'app/core/core.module';
import {MaterialModule} from 'app/material/material.module';
import {KedvencHorgaszhelySzerkesztesePageRoutingModule} from './kedvenc-horgaszhely-szerkesztese-routing.module';
import {KedvencHorgaszhelySzerkesztesePage} from './kedvenc-horgaszhely-szerkesztese.page';
import {SharedModule} from 'app/shared/shared.module';
import {FileUploaderModule} from 'app/file-uploader/file-uploader.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    KedvencHorgaszhelySzerkesztesePageRoutingModule,
    CoreModule,
    MaterialModule,
    NgSelectModule,
    ReactiveFormsModule,
    SharedModule,
    FileUploaderModule
  ],
  declarations: [KedvencHorgaszhelySzerkesztesePage]
})
export class KedvencHorgaszhelySzerkesztesePageModule {
}
