import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FogasSzerkesztesePage } from './fogas-szerkesztese.page';

const routes: Routes = [
  { path: '', component: FogasSzerkesztesePage },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FogasSzerkesztesePageRoutingModule {}
