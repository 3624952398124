import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { CoreModule } from '../../core/core.module';

import { TerkepNezetPageRoutingModule } from './terkep-nezet-routing.module';
import { TerkepNezetPage } from './terkep-nezet.page';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        SharedModule,
        TerkepNezetPageRoutingModule,
        CoreModule,
    ],
  declarations: [TerkepNezetPage]
})
export class TerkepNezetPageModule {}
