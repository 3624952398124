<ion-header>
  <ion-toolbar color="secondary">
    <ion-title>Területi jegy vásárlás</ion-title>
    <ion-buttons slot="end" class="ion-margin-top">
      <mohosz-header-notification></mohosz-header-notification>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="moh-bg-light">

  <ion-tabs>
    <ion-tab-bar slot="top">
      <ion-tab-button tab="terkep-nezet">
        <ion-label>Térkép nézet</ion-label>
      </ion-tab-button>

      <ion-tab-button tab="lista-nezet">
        <ion-label>Lista nézet</ion-label>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>

</ion-content>
