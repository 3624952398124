import { Pipe, PipeTransform } from '@angular/core';
import { HorgaszturizmusPoiItem } from 'api';
import { BackgroundColor } from 'app/shared/enums/background-color';



@Pipe({
  name: 'horgaszturizmusPoi'
})
export class HorgaszturizmusPoiPipe implements PipeTransform {

  constructor(
    ) {
  }

  transform(value: HorgaszturizmusPoiItem.TypeEnum, arg?: 'tag-icon-url' | 'details-icon-url' | 'szolgaltatas-icon-url' | 'backgound-color'): string | BackgroundColor {
    if(arg === 'details-icon-url'){
      return 'assets/horgaszturizmus/icons/state/info.svg';
    }
    switch (value) {
      case HorgaszturizmusPoiItem.TypeEnum.VIZTERULET: switch (arg) {
        case 'tag-icon-url': return 'assets/poi/icon/vizterulet.svg';
        case 'szolgaltatas-icon-url': return 'assets/icon/szolgaltatas/horgaszviz.svg';
        case 'backgound-color': return BackgroundColor.VIZTERULET_BGC;
        default: return 'Horgásztatás';
      }
      case HorgaszturizmusPoiItem.TypeEnum.BERBEADAS: switch (arg) {
        case 'tag-icon-url': return 'assets/poi/icon/berbeadas.svg';
        case 'szolgaltatas-icon-url': return 'assets/icon/szolgaltatas/berbeadas.svg';
        case 'backgound-color': return BackgroundColor.BERBEADAS_BGC;
        default: return 'Bérbeadás';
      }
      case HorgaszturizmusPoiItem.TypeEnum.SZOLGALTATO: switch (arg) {
        case 'tag-icon-url': return 'assets/poi/icon/szolgaltato.svg';
        case 'szolgaltatas-icon-url': return 'assets/icon/szolgaltatas/horgasztanya.svg';
        case 'backgound-color': return BackgroundColor.SZOLGALTATO_BGC;
        default: return 'Szolgáltató';
      }
    }
  }

}
