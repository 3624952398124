import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BehaviorSubject, Observable } from 'rxjs';

import { DisplaySize } from './display-size';

@Injectable()
export class DisplayObserverService extends OnDestroyMixin {

  displaySize: Observable<DisplaySize>;
  private displaySizeSubject: BehaviorSubject<DisplaySize> = new BehaviorSubject<DisplaySize>(new DisplaySize());

  constructor(
    private breakpointObserver: BreakpointObserver
  ) {
    super();
    this.displaySize = this.displaySizeSubject.asObservable();
    this.breakpointObserver.observe([Breakpoints.XLarge, Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall])
      .pipe(untilComponentDestroyed(this)).subscribe(result => {
        if (result.breakpoints[Breakpoints.XSmall]) {
          this.displaySizeSubject.next(this.displaySizeSubject.getValue().update(Breakpoints.XSmall));
        } else if(result.breakpoints[Breakpoints.Small]) {
          this.displaySizeSubject.next(this.displaySizeSubject.getValue().update(Breakpoints.Small));
        } else if(result.breakpoints[Breakpoints.Medium]) {
          this.displaySizeSubject.next(this.displaySizeSubject.getValue().update(Breakpoints.Medium));
        } else if(result.breakpoints[Breakpoints.Large]) {
          this.displaySizeSubject.next(this.displaySizeSubject.getValue().update(Breakpoints.Large));
        } else if(result.breakpoints[Breakpoints.XLarge]) {
          this.displaySizeSubject.next(this.displaySizeSubject.getValue().update(Breakpoints.XLarge));
        }
    });
   }
}
