import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mohosz-no-content',
  templateUrl: './no-content.component.html',
  styleUrls: ['./no-content.component.scss'],
})
export class NoContentComponent implements OnInit {

  @Input() noContentText = 'Nincs megjeleníthető adat!';
  @Input() isLoading = true;

  constructor() { }

  ngOnInit() {}

}
