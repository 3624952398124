import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  storage: Promise<Storage>;

  constructor(
    private ionicStorage: Storage
  ) {
    this.storage = this.ionicStorage.create();
  }
}
