<mohosz-poi-popup *ngIf="poi"
  [title]="poi.megnevezes"
  (closePoiPopup)="onCloseClick()"
  [endSectionType]="'custom'"
  iconUrl="assets/poi/icon/vizterulet-popup.svg">
  <!-- <ng-container kepContent>
    <ng-container *ngIf="poi?.kepId; else noImg;">
      <mohosz-base64-content [contentId]="poi.kepId"  type="image" borderRadius="4px"></mohosz-base64-content>
    </ng-container>
    <ng-template #noImg>
      <div class="no-image"></div>
    </ng-template>
  </ng-container>

  <ng-container middleSection>
    <div *ngIf="poi?.szolgaltatasId else noContent" class="text-overflow-line-clamp-3">
      {{poi.altalanosLeiras}}
    </div>
    <ng-template #noContent>
      <div *ngIf="!isLoading">...</div>
    </ng-template>
  </ng-container> -->

  <ng-container endSection>
    <div class="ion-text-center ion-padding">
      <ion-button fill="clear" size="small" (click)="onVizteruletKapcsolasa()">
        <ion-icon name="link" size="small" slot="start"></ion-icon>Vízterület kapcsolása
      </ion-button>
    </div>
  </ng-container>
</mohosz-poi-popup>

