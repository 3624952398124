import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import {
  FigyelmeztetesDialogData
} from 'app/fogasi-naplo/figyelmeztetes-dialog/figyelmeztetes-dialog-data';
import {
  FigyelmeztetesDialogComponent
} from 'app/fogasi-naplo/figyelmeztetes-dialog/figyelmeztetes-dialog.component';

@Injectable()
export class FigyelmeztetesDialogService {

  constructor(
    private dialog: MatDialog
  ) {
  }

  public async openFigyelmeztetesDialog(dialogData: FigyelmeztetesDialogData, customClass?: string): Promise<any> {
    const dialogRef = this.dialog.open<FigyelmeztetesDialogComponent>(
      FigyelmeztetesDialogComponent, {
        data: dialogData,
        panelClass: (customClass) ? customClass : ''
      });
    return await dialogRef.afterClosed().toPromise();
  }
}
