<ion-card class="moh-card-item-details">
  <ion-item [lines]="'full'" class="head">
    <ion-avatar *ngIf="iconUrl" slot="start">
      <ion-icon [src]="iconUrl" role="img" class="top-icon"></ion-icon>
    </ion-avatar>
    <ion-label>
      <h6>{{ title }}</h6>
      <p class="text-overflow-ellipsis line-1">
        {{ subTitle }}
      </p>
    </ion-label>
    <ion-button class="icon-button-32 close-button" fill="clear" slot="end" color="secondary" (click)="onCloseClick()">
      <ion-icon style="font-size: 20px;" slot="icon-only" name="close"></ion-icon>
    </ion-button>
  </ion-item>

  <ion-card-content>
    <div class="image-container">
      <ng-content select="[kepContent]"></ng-content>
    </div>

    <ng-content select="[middleSection]"></ng-content>
  </ion-card-content>

  <ion-item *ngIf="endSectionType === 'edit'" (click)="onEditClick()" class="link" lines="none" button detail>
    <ion-avatar slot="start">
      <ion-icon name="pencil" size="small"></ion-icon>
    </ion-avatar>
    <ion-label color="primary">{{editLabel}}</ion-label>
  </ion-item>
  <div>
    <ng-content select="[endSection]"></ng-content>
  </div>
</ion-card>
