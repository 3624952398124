<mohosz-simple-header [title]="'Üzenet'" [backButton]="true"></mohosz-simple-header>

<ion-content>

  <ion-grid>
    <ion-row>
      <ion-col size-md="6" offset-md="3">

        <ng-container *ngIf="uzenet">
          <ion-item class="ion-padding-bottom">
            <ion-avatar slot="start">
              <mohosz-base64-content *ngIf="uzenet.profilKep; else monogram" [content]="uzenet.profilKep"></mohosz-base64-content>
              <ng-template #monogram>
                <span monogram [nev]="uzenet.feladoNev" [monogramText]="uzenet.monogram"></span>
              </ng-template>
            </ion-avatar>
            <ion-label>
              <h6>{{ uzenet.feladoNev }}</h6>
              <div class="datum">{{uzenet.kuldesDatum | uzenetDateTime}}</div>
              <p>
                {{uzenet.cim}}
              </p>
            </ion-label>
          </ion-item>

          <div class="uzenet-content">
            <p [innerHTML]="uzenet.leiras | safeHtml"></p>

            <ng-container *ngIf="uzenet.uzenetValaszLehetoseg">
              <ion-buttons>
                <ng-container *ngIf="uzenet.cimzettValasz === null; else buttonLike">
                  <ion-button (click)="onIgenClick()" fill="outline" color="primary" class="button-small">Igen</ion-button>
                  <ion-button (click)="onNemClick()" fill="outline" color="primary" class="button-small">Nem</ion-button>
                </ng-container>
              </ion-buttons>
              <ng-template #buttonLike>
                <div class="buttons-row">
                  <div class="button-like-object" [class.selected]="uzenet.cimzettValasz === true">Igen</div>
                  <div class="button-like-object" [class.selected]="uzenet.cimzettValasz === false">Nem</div>
                </div>
              </ng-template>
            </ng-container>
          </div>
        </ng-container>

      </ion-col>
    </ion-row>

  </ion-grid>

</ion-content>
