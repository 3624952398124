import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mohosz-fogasi-naplo',
  templateUrl: './fogasi-naplo.page.html',
  styleUrls: ['./fogasi-naplo.page.scss'],
})
export class FogasiNaploPage implements OnInit {

  constructor() { }

  ngOnInit() { }

}
