<ion-card *ngIf="content">
  <ion-card-header *ngIf="title">
    <ion-card-title>{{title}}</ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <div id="container" [style.height.px]="textContainerHeight">
      <div #textRef>{{content}}</div>
    </div>
    <ng-container *ngIf="isShowMore">
      <a (click)="onShowTextToggleClick()">{{ showMoreTextLabel }}</a>
    </ng-container>
  </ion-card-content>
</ion-card>
