<ion-card>

  <ion-card-header>
    <ion-item>
      <ion-avatar slot="start" class="icon-moh-allami-jegy">
        <ion-icon src="assets/icon/ticket-outline.svg"></ion-icon>
      </ion-avatar>
      <ion-label class="ion-text-wrap">
        <h6>{{allamiJegy?.tipus | allamiJegyTipus}}</h6>
        <p class="allapot" [ngClass]="allamiJegy?.statusz === AllamiJegyStatuszEnum.ERVENYES ? 'active' : 'inactive'">
          {{allamiJegy?.statusz | allamiJegyStatusz}}
        </p>
      </ion-label>
    </ion-item>
  </ion-card-header>

  <ion-card-content class="no-padding-top">
    <ion-list lines="none">
      <ion-item>
        <span slot="start">Sorszám:</span>
        <ion-label><strong>{{allamiJegy?.sorszam}}</strong></ion-label>
      </ion-item>
      <ion-item>
        <span slot="start">Érvényes:</span>
        <ion-label><strong>{{allamiJegy?.ervenyessegDatuma | date: 'shortDate'}}</strong></ion-label>
      </ion-item>
    </ion-list>
  </ion-card-content>

</ion-card>
