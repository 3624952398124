<div class="main">
    <div class="info">
        <div class="title">
            <h4>{{regPont?.nev}}</h4>
        </div>
        <div class="content">
            {{regPont?.varos}}, {{regPont?.kozteruletNev}} {{regPont?.kozteruletTipus}} {{regPont?.hazSzam}}
        </div>
    </div>
    <div class="contacts">
        <div class="title">
            Elérhetőség
        </div>
        <div class="content">
            <a *ngIf="regPont?.email" [href]="'mailto:' + regPont?.email">{{regPont?.email}}</a>
            <span *ngIf="!regPont?.email">Nincs email cím</span>
        </div>
    </div>
    <div *ngIf="regPont?.lat && regPont?.lng" class="actions">
        <a href="javascript:void(0)" (click)="goToLocation()">Útvonal ide</a>
    </div>
</div>
