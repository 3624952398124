import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {CoreModule} from 'app/core/core.module';
import {UzenetListItemComponent} from 'app/postafiok/uzenet-list-item/uzenet-list-item.component';
import {BejovoUzenetekPageRoutingModule} from './bejovo-uzenetek-routing.module';
import {BejovoUzenetekPage} from './bejovo-uzenetek.page';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CoreModule,
    BejovoUzenetekPageRoutingModule,
  ],
  declarations: [
    BejovoUzenetekPage,
    UzenetListItemComponent
  ],
})
export class BejovoUzenetekPageModule {}
