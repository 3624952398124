import { FileUploader } from 'ng2-file-upload';
import { Observable, Subject } from 'rxjs';
import { FileUploaderResult } from 'app/file-uploader/file-uploader-result';

export class FileUploaderWrapper {
  uploader: FileUploader;
  resultSubject: Subject<FileUploaderResult>;
  result$: Observable<FileUploaderResult>;

  constructor(fileUploader: FileUploader) {
    this.uploader = fileUploader;
    this.resultSubject = new Subject<FileUploaderResult>();
    this.result$ = this.resultSubject.asObservable();
  }
}
