import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { HttpErrorResponse } from '@angular/common/http';
import { Utils } from '../core/utils';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastController: ToastController) { }

  async infoMessage(message) {
    const toast = await this.toastController.create({
      message,
      position: 'top',
      color: 'primary',
      duration: 5000
    });
    await toast.present();
  }

  async successMessage(message) {
    const toast = await this.toastController.create({
      message,
      position: 'top',
      color: 'success',
      duration: 1000,
      cssClass: 'toast-success'
    });
    await toast.present();
  }

  async warningMessage(message) {
    const toast = await this.toastController.create({
      message,
      position: 'top',
      color: 'warning',
      duration: 3000,
      cssClass: 'toast-warning'
    });
    await toast.present();
  }

  async errorMessage(message, position: 'top' | 'bottom' | 'middle' = 'top') {
    const toast = await this.toastController.create({
      header: message?.indexOf('Hiba') <= 0 ? '' : 'Hiba',
      message,
      position,
      color: 'danger',
      cssClass: 'toast-error',
      buttons: [
        {
          role: 'cancel',
          icon: 'close'
        }
      ]
    });
    await toast.present();
  }

  async errorMessageAutoClose(message, duration = 2000, position: 'top' | 'bottom' | 'middle' = 'top',) {
    const toast = await this.toastController.create({
      header: message?.indexOf('Hiba') <= 0 ? '' : 'Hiba',
      message,
      position,
      color: 'danger',
      duration,
      cssClass: 'toast-error'
    });
    await toast.present();
  }

  async httpError(httpError: HttpErrorResponse){
    if(httpError.status !== Utils.HTTP_ERROR_CODE_403_ACCESS_DENIED){
      await this.errorMessageAutoClose(httpError.error.message, 2000, 'top');
    }
  }
}
