import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'mohosz-simple-header',
  templateUrl: './simple-header.component.html',
  styleUrls: ['./simple-header.component.scss'],
})
export class SimpleHeaderComponent implements OnInit {

  @Input() title: string;
  @Input() backButton: boolean;
  @Input() notifications = true;

  constructor(
    private navController: NavController
  ) { }

  ngOnInit() {}

  onLocationBack() {
    this.navController.pop();
  }

}
