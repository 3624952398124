/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MobilAppHorgaszkartya { 
    ervenyessegKezdete?: string;
    ervenyessegVege?: string;
    gyartasStatusz?: MobilAppHorgaszkartya.GyartasStatuszEnum;
    horgaszAzonosito?: string;
    igenylesStatusz?: MobilAppHorgaszkartya.IgenylesStatuszEnum;
}
export namespace MobilAppHorgaszkartya {
    export type GyartasStatuszEnum = 'GYARTAS_JOVAHAGYASRA_VAR' | 'GYARTASRA_VAR' | 'GYARTAS_FOLYAMATBAN' | 'GYARTAS_KESZ';
    export const GyartasStatuszEnum = {
        GYARTASJOVAHAGYASRAVAR: 'GYARTAS_JOVAHAGYASRA_VAR' as GyartasStatuszEnum,
        GYARTASRAVAR: 'GYARTASRA_VAR' as GyartasStatuszEnum,
        GYARTASFOLYAMATBAN: 'GYARTAS_FOLYAMATBAN' as GyartasStatuszEnum,
        GYARTASKESZ: 'GYARTAS_KESZ' as GyartasStatuszEnum
    };
    export type IgenylesStatuszEnum = 'UGYFELSZOLGALATI_LETREHOZAS_ALATT' | 'HORGASZ_FIZETESRE_VAR' | 'HORGASZ_FIZETES_FOLYAMATBAN' | 'GYARTAS_ALATT' | 'KESZ';
    export const IgenylesStatuszEnum = {
        UGYFELSZOLGALATILETREHOZASALATT: 'UGYFELSZOLGALATI_LETREHOZAS_ALATT' as IgenylesStatuszEnum,
        HORGASZFIZETESREVAR: 'HORGASZ_FIZETESRE_VAR' as IgenylesStatuszEnum,
        HORGASZFIZETESFOLYAMATBAN: 'HORGASZ_FIZETES_FOLYAMATBAN' as IgenylesStatuszEnum,
        GYARTASALATT: 'GYARTAS_ALATT' as IgenylesStatuszEnum,
        KESZ: 'KESZ' as IgenylesStatuszEnum
    };
}