import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ElektronikusFogas, Halfaj, VizteruletTorzsadat } from 'api';

@Component({
  selector: 'mohosz-fogas-card',
  templateUrl: './fogas-card.component.html',
  styleUrls: ['./fogas-card.component.scss'],
})
export class FogasCardComponent implements OnChanges {

  @Input() fogas: ElektronikusFogas;
  @Input() halfajList: Array<Halfaj>;
  @Input() vizteruletList: Array<VizteruletTorzsadat>;

  halFaj: Halfaj;
  vizterulet: VizteruletTorzsadat;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {

    if(changes.fogas || changes.halfajList) {
      this.halFaj = this.halfajList?.find(hal => hal.id === this.fogas?.halfajId);
    }

    if(changes.fogas || changes.vizteruletList){
      this.vizterulet = this.vizteruletList?.find(vt => vt.id === this.fogas?.vizteruletId);
    }
  }
}
