import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {ModalController} from '@ionic/angular';

import {AbstractFogas, AddFogasRequest} from 'api';
import {SelectableItem} from 'app/core/apienum/selectable-item';
import {nameofFactory, Utils} from 'app/core/utils';
import {FigyelmeztetesDialogService} from 'app/fogasi-naplo/figyelmeztetes-dialog.service';
import {FogasRogzitesModalData} from 'app/fogasi-naplo/fogasok/rogzites-modal/fogas-rogzites-modal-data';
import {ToastService} from 'app/services/toast.service';
import { FogasiNaploService } from '../../../services/fogasi-naplo.service';

const AFR = nameofFactory<AddFogasRequest>();

@Component({
  selector: 'mohosz-rogzites-modal',
  templateUrl: './fogas-rogzites-modal.page.html',
  styleUrls: ['../../rogzites-modal.scss'],
})
export class FogasRogzitesModalPage implements OnInit {

  data: FogasRogzitesModalData;

  fogas: AbstractFogas;

  form: FormGroup;
  halfajok: Array<SelectableItem> = [];
  vizteruletek: Array<SelectableItem> = [];

  AFR = AFR;

  constructor(
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private fogasiNaploService: FogasiNaploService,
    private toastService: ToastService,
    private confirmDialogService: FigyelmeztetesDialogService,
  ) { }

  ngOnInit() {
    this.form = this.buildForm();
    // halfaj valaszto ertekkeszlet
    this.halfajok = this.data.halfajList.map(halfaj => SelectableItem.of(halfaj.id, halfaj.nev));
    // vizterulet valaszto ertekkeszlet
    this.vizteruletek = this.data.vizteruletList.map(vizterulet =>
      SelectableItem.of(vizterulet.id, vizterulet.nev + ' (' + vizterulet.vizterkod + ')')
    );
  }

  buildForm() {
    const now = new Date();
    return this.formBuilder.group({
      [AFR('fogasiNaploId')]: [this.data.fogasiNaploId, {updateOn: 'blur', validators: [Validators.required]}],
      ['idopontDatum']: [new Date(),{ updateOn: 'blur', validators: [Validators.required] }],
      ['idopontOra']: [now.getHours(), { updateOn: 'blur', validators: [Validators.required, Validators.max(23), Validators.min(0)] }],
      ['idopontPerc']: [now.getMinutes(), { updateOn: 'blur', validators: [Validators.required, Validators.max(59), Validators.min(0)] }],
      [AFR('vizteruletId')]: [null, {updateOn: 'blur', validators: [Validators.required]}],
      [AFR('halfajId')]: [null, {updateOn: 'blur', validators: [Validators.required]}],
      [AFR('suly')]: [null, {updateOn: 'change', validators: [Validators.required]}]
    });
  }

  onFogasMentesClick() {
    this.form.markAllAsTouched();
    if(this.form.valid){
      const forecastRequest: AddFogasRequest = this.form.getRawValue();
      this.fogasiNaploService.eFogasiNaploForecastAddFogas(forecastRequest).toPromise()
        .then(responseForecast => {
          if (responseForecast.figyelmeztetoUzenetek.length > 0) {
            const dialogData = {
              figyelmeztetesek: responseForecast.figyelmeztetoUzenetek
            };
            this.confirmDialogService.openFigyelmeztetesDialog(dialogData, 'moh-notification')
              .then(confirmed => {
                if (confirmed) {
                  this.fogasRogzites();
                }
              });
          } else {
            this.fogasRogzites();
          }
        }).catch(error => Utils.parseServerError(this.form, error));
    }
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  private fogasRogzites() {
    const request: AddFogasRequest = this.form.getRawValue();
    const idopontDatum = this.form.get('idopontDatum').value;
    const idopontOra = this.form.get('idopontOra').value;
    const idopontPerc = this.form.get('idopontPerc').value;
    request.idopont = Utils.toServerDateTime(idopontDatum, idopontOra, idopontPerc);

    this.fogasiNaploService.eFogasiNaploAddFogas(request).toPromise()
      .then(response => {
        this.toastService.successMessage('Sikeres fogás rögzítés');
        this.modalController.dismiss({
          dismissed: true,
          fogas: response.fogas
        });
      })
      .catch(error => Utils.parseServerError(this.form, error));
  }

}
