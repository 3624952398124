/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface HorgasztatasSzolgaltatasVegrehajtottLepes { 
    lepes?: HorgasztatasSzolgaltatasVegrehajtottLepes.LepesEnum;
    teljesKitoltottseg?: boolean;
}
export namespace HorgasztatasSzolgaltatasVegrehajtottLepes {
    export type LepesEnum = 'ALAPADATOK' | 'ELHELYEZKEDES' | 'HORGASZREND' | 'FENYKEPEK' | 'JELLEMZOK' | 'HALALLOMANY' | 'OSSZEGZES';
    export const LepesEnum = {
        ALAPADATOK: 'ALAPADATOK' as LepesEnum,
        ELHELYEZKEDES: 'ELHELYEZKEDES' as LepesEnum,
        HORGASZREND: 'HORGASZREND' as LepesEnum,
        FENYKEPEK: 'FENYKEPEK' as LepesEnum,
        JELLEMZOK: 'JELLEMZOK' as LepesEnum,
        HALALLOMANY: 'HALALLOMANY' as LepesEnum,
        OSSZEGZES: 'OSSZEGZES' as LepesEnum
    };
}