<ion-content class="moh-bg-light">
  <ion-grid *ngIf="!isLoading && poiList?.length > 0">
    <ion-row>
      <ion-col class="ion-justify-content-start ion-padding-left">
        <h3>Találatok {{ poiList?.length ? '(' + poiList?.length + ')' : '' }}</h3>
      </ion-col>
      <ion-col class="ion-justify-content-end ion-padding-right">
        <!-- sorrendezes select -->
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-justify-content-start ion-padding-left">
        <p>A tartózkodási hely <b>30 km</b>-es körzetében található horgásztatás szolgáltatások a helyzettől való távolság szerint sorrendezve. Legközelebbi legelől.</p>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ng-container *ngIf="poiList?.length > 0; else noContent">
    <ion-grid>
      <ion-row class="ion-justify-content-evenly">
        <ion-col *ngFor="let item of poiList" size="6" size-lg="2" size-sm="3">
          <mohosz-horgasztatas-list-item
            [poi]="item" class="lista-nezet">
          </mohosz-horgasztatas-list-item>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ng-container>

  <ng-template #noContent>
    <mohosz-no-content [isLoading]="isLoading" noContentText="A tartózkodási hely <b>30 km</b>-es körzetében nincs elérhető vízterület."></mohosz-no-content>
  </ng-template>

</ion-content>
