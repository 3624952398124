/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FizetesiKonstrukcio } from './fizetesiKonstrukcio';

export interface SzolgaltatasDetails { 
    fizetesiKonstrukcioList?: Array<FizetesiKonstrukcio>;
    kapcsolodoSzolgaltatasIdList?: Array<number>;
    kiemelesVege?: string;
    kiemelt?: boolean;
    legnepszerubb?: boolean;
    minositett?: boolean;
    reszletekMegnyitva?: number;
}