import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HorgaszNapokPage } from './horgasz-napok.page';
import { HorgaszNapRogzitesModalPageModule } from './rogzites-modal/horgasz-nap-rogzites-modal.module';

const routes: Routes = [
  {
    path: '', component: HorgaszNapokPage
  },
  {
    path: 'rogzites-modal', loadChildren: () => HorgaszNapRogzitesModalPageModule
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HorgaszNapokPageRoutingModule {}
