/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Halfaj { 
    aktiv?: boolean;
    honossag?: Halfaj.HonossagEnum;
    id?: number;
    nev?: string;
    sorrend?: number;
}
export namespace Halfaj {
    export type HonossagEnum = 'OSHONOS' | 'IDEGENHONOS' | 'EGYEB';
    export const HonossagEnum = {
        OSHONOS: 'OSHONOS' as HonossagEnum,
        IDEGENHONOS: 'IDEGENHONOS' as HonossagEnum,
        EGYEB: 'EGYEB' as HonossagEnum
    };
}