<ion-card *ngIf="felszereltsegList?.length > 0">
  <ion-card-header>
    <ion-card-title>Felszereltség</ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <ion-list class="felszereltseg">
      <ion-item *ngFor="let item of felszereltsegList" lines="none">
        <ion-icon src="{{item | felszereltseg:'icon-url'}}" color="primary"></ion-icon>
        <ion-label>{{item | felszereltseg}}</ion-label>
      </ion-item>
    </ion-list>
  </ion-card-content>
</ion-card>
