<ion-content class="moh-bg-light">
  <ion-grid>
    <ion-row>
      <ion-col size-md="6" offset-md="3">
        <h3>
          Köszönjük a vásárlást!
        </h3>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
