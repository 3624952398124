<ion-header>
  <ion-toolbar color="secondary">
    <ion-title>Korlátozás alá vont területek</ion-title>
    <ion-buttons slot="end" class="ion-margin-top">
      <mohosz-header-notification></mohosz-header-notification>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="moh-bg-light" [ngClass]="{
    'korlatozasokListaToggleLathato' : korlatozasokListaToggleLathato,
    'korlatozasokListaLathato' : korlatozasokListaLathato
  }">

  <mohosz-no-content *ngIf="!vedettVizteruletList" [isLoading]="isLoading"></mohosz-no-content>

  <mohosz-vedett-vizterulet-terkep *ngIf="vedettVizteruletList" #vedettVizteruletMap
    [locationPos]="locationPos"
    [vedettVizteruletList]="vedettVizteruletList"
    [targetGPSKoordinata]="targetGPSKoordinata"
    (mapReady)="onMapReady(vedettVizteruletMap)"
    (mapZoomEnd)="onMapZoomEnd($event)"
    (mapMoveEnd)="onMapMoveEnd($event)">
  </mohosz-vedett-vizterulet-terkep>

  <div *ngIf="korzetbenList">
    <ion-grid>
      <ion-row>
        <ion-col size-md="6" offset-md="3">
          <div class="ion-text-center">

            <ng-container *ngIf="korzetbenList.length > 0">
              <ion-item button detail="false" lines="full" (click)="onListToggleClick()" class="list-block-toggle">
                <ion-label>
                  <h6>{{korzetbenList.length}} korlátozás alá vont terület</h6>
                  <p>{{ korzetKm }} km-es körzetben</p>
                </ion-label>
                <ion-icon name="chevron-down-outline" slot="end" color="primary" *ngIf="korlatozasokListaLathato"></ion-icon>
                <ion-icon name="chevron-up-outline" slot="end" color="primary" *ngIf="!korlatozasokListaLathato"></ion-icon>
              </ion-item>
            </ng-container>

            <ng-container *ngIf="korzetbenList.length === 0">
              <ion-item lines="full" class="list-block-toggle">
                <ion-label>
                  <h6>Nincs korlátozás alá vont terület</h6>
                  <p>{{ korzetKm }} km-es körzetben</p>
                </ion-label>
              </ion-item>
            </ng-container>

            <ng-container *ngIf="korlatozasokListaLathato">
              <cdk-virtual-scroll-viewport class="viewport ion-content-scroll-host" itemSize="72">
                <ion-list lines="full">
                  <ion-item button detail="false"
                            *cdkVirtualFor="let vedettVizterulet of korzetbenList"
                            (click)="onVedettVizteruletListItemClick(vedettVizterulet)">
                    <ion-avatar slot="start"
                                [ngClass]="vedettVizterulet?.tipus === VedettVizteruletTipusEnum.TILTOTTVIZTERULET
                              ? 'icon-moh-location-tiltott' : 'icon-moh-location-korlatozott'">
                      <ion-icon src="assets/icon/location.svg" role="img"></ion-icon>
                    </ion-avatar>
                    <ion-label>
                      <h6>{{ vedettVizterulet.megnevezes }}</h6>
                      <p class="text-overflow-ellipsis line-1">
                        {{ vedettVizterulet.vizteruletNev }}
                      </p>
                    </ion-label>
                  </ion-item>
                </ion-list>
              </cdk-virtual-scroll-viewport>
            </ng-container>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
