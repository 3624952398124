import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FogasokPage } from './fogasok.page';
import { FogasRogzitesModalPageModule } from './rogzites-modal/fogas-rogzites-modal.module';

const routes: Routes = [
  {
    path: '',
    component: FogasokPage,
  },
  {
    path: 'rogzites-modal', loadChildren: () => FogasRogzitesModalPageModule
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FogasokPageRoutingModule {}
