import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FigyelmeztetesDialogData
} from 'app/fogasi-naplo/figyelmeztetes-dialog/figyelmeztetes-dialog-data';

@Component({
  selector: 'mohosz-figyelmeztetes-dialog',
  templateUrl: './figyelmeztetes-dialog.component.html',
  styleUrls: ['./figyelmeztetes-dialog.component.scss']
})
export class FigyelmeztetesDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FigyelmeztetesDialogData
  ) {
  }

}
