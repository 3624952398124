<div class="modal-content" *ngIf="form && data">
  <ion-card class="modal-rogzites">
    <ion-card-header class="ion-text-center">
      <ion-item lines="none" tabindex="-1">
        <ion-label>Fogás rögzítése</ion-label>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()">
            <ion-icon slot="icon-only" name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-item>
      <ion-text color="danger" class="warning">
        <ion-icon name="alert-circle"></ion-icon>
        Rögzítés után nincs lehetőség a fogás törlésére.
      </ion-text>
    </ion-card-header>
    <ion-card-content>

      <mat-error class="align-center">
        <span *ngIf="form.errors?.server">{{form.errors.server.message}}</span>
      </mat-error>

      <form [formGroup]="form">
        <!-- input -->
        <table>
          <tr>
            <td nowrap>
              <mat-form-field class="mandatory-field">
                <input type="text" name="idopontDatum" formControlName="idopontDatum"
                       matInput readonly
                       placeholder="Fogás időpont"
                       [matDatepicker]="pickerFogas"
                       [min]="data.ervenyessegKezdete"
                       [max]="data.ervenyessegVege">
                <mat-datepicker-toggle matSuffix [for]="pickerFogas">
                  <mat-icon matDatepickerToggleIcon svgIcon="moh-icon-calendar"></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #pickerFogas></mat-datepicker>
                <mat-error>
                  <mohosz-form-field-error-content [control]="form.controls.idopontDatum"></mohosz-form-field-error-content>
                </mat-error>
              </mat-form-field>
            </td>
            <td nowrap style="width: 20%;">
              <mat-form-field class="mandatory-field">
                <input matInput type="number" min="0" max="24" name="idopontOra" placeholder="Óra" formControlName="idopontOra">
                <mat-error>
                  <mohosz-form-field-error-content [control]="form.controls.idopontOra"></mohosz-form-field-error-content>
                </mat-error>
              </mat-form-field>
            </td>
            <td nowrap style="width: 20%;">
              <mat-form-field class="mandatory-field">
                <input matInput type="number" min="0" max="60" name="idopontPerc" placeholder="Perc" formControlName="idopontPerc">
                <mat-error>
                  <mohosz-form-field-error-content [control]="form.controls.idopontPerc"></mohosz-form-field-error-content>
                </mat-error>
              </mat-form-field>
            </td>
          </tr>
        </table>

        <!-- input -->
        <ion-item lines="none" class="mandatory-field has-ng-select">
          <ion-label position="stacked">Vízterület</ion-label>
          <div class="ng-select-field">
            <ng-select class="moh-ng-select"
                       [formControlName]="AFR('vizteruletId')"
                       [virtualScroll]="true"
                       [items]="vizteruletek"
            ></ng-select>
          </div>
        </ion-item>
        <ion-note slot="error" color="danger">
          <mohosz-form-field-error-content [control]="form.controls[AFR('vizteruletId')]"></mohosz-form-field-error-content>
        </ion-note>

        <!-- input -->
        <ion-item lines="none" class="mandatory-field has-ng-select">
          <ion-label position="stacked">Hal fajtája</ion-label>
          <div class="ng-select-field">
            <ng-select class="moh-ng-select"
                       [formControlName]="AFR('halfajId')"
                       [virtualScroll]="true"
                       [items]="halfajok"
            ></ng-select>
          </div>
        </ion-item>
        <ion-note slot="error" color="danger">
          <mohosz-form-field-error-content [control]="form.controls[AFR('halfajId')]"></mohosz-form-field-error-content>
        </ion-note>

        <!-- input -->
        <ion-item class="mandatory-field">
          <ion-label position="stacked">Tömeg (kg)</ion-label>
          <ion-input type="number" debounce="300" [formControlName]="AFR('suly')"></ion-input>
        </ion-item>
        <ion-note slot="error" color="danger">
          <mohosz-form-field-error-content [control]="form.controls[AFR('suly')]"></mohosz-form-field-error-content>
        </ion-note>
      </form>

      <!-- mentes button -->
      <div class="ion-text-center ion-padding-top">
        <ion-button class="ion-margin-top"
                    (click)="onFogasMentesClick()"
                    [disabled]="form.invalid">
          Fogás mentése
        </ion-button>
      </div>

    </ion-card-content>
  </ion-card>
</div>
