import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { HorgaszNapRogzitesModalPage } from 'app/fogasi-naplo/horgasz-napok/rogzites-modal/horgasz-nap-rogzites-modal-page';
import { MaterialModule } from 'app/material/material.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MaterialModule,
  ],
  declarations: [HorgaszNapRogzitesModalPage]
})
export class HorgaszNapRogzitesModalPageModule {
}
