/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface HorgaszturizmusPoiItem { 
    altalanosLeiras?: string;
    bigKepId?: number;
    id?: number;
    kepId?: number;
    megnevezes?: string;
    szolgaltatasId?: number;
    termekDarabszam?: number;
    type?: HorgaszturizmusPoiItem.TypeEnum;
}
export namespace HorgaszturizmusPoiItem {
    export type TypeEnum = 'SZOLGALTATO' | 'VIZTERULET' | 'BERBEADAS' | 'HORGASZHELYEK' | 'KIKOTO' | 'ERTEKESITESIPONT' | 'GUIDE' | 'SZALLASHELY' | 'SOLYAPALYA' | 'HORGASZTANYA';
    export const TypeEnum = {
        SZOLGALTATO: 'SZOLGALTATO' as TypeEnum,
        VIZTERULET: 'VIZTERULET' as TypeEnum,
        BERBEADAS: 'BERBEADAS' as TypeEnum,
        HORGASZHELYEK: 'HORGASZHELYEK' as TypeEnum,
        KIKOTO: 'KIKOTO' as TypeEnum,
        ERTEKESITESIPONT: 'ERTEKESITESIPONT' as TypeEnum,
        GUIDE: 'GUIDE' as TypeEnum,
        SZALLASHELY: 'SZALLASHELY' as TypeEnum,
        SOLYAPALYA: 'SOLYAPALYA' as TypeEnum,
        HORGASZTANYA: 'HORGASZTANYA' as TypeEnum
    };
}