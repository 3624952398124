import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {AltalanosModule} from 'app/altalanos/altalanos.module';
import {CoreModule} from 'app/core/core.module';
import {MaterialModule} from 'app/material/material.module';
import {TextCardComponent} from 'app/shared/card/text-card/text-card.component';
import {SharedModule} from 'app/shared/shared.module';
import {HorgaszrendNyelvPipe} from './horgasztatas-reszletek/horgaszrend/horgaszrend-nyelv.pipe';
import {HorgaszrendComponent} from './horgasztatas-reszletek/horgaszrend/horgaszrend.component';
import {HorgasztatasReszletekPage} from './horgasztatas-reszletek/horgasztatas-reszletek-page.component';
import {JegyVasarlasPageRoutingModule} from './jegyvasarlas-routing.module';
import {JegyvasarlasPage} from './jegyvasarlas.page';
import {KeszComponent} from './kesz/kesz.component';


@NgModule({
  imports: [
    JegyVasarlasPageRoutingModule,
    CommonModule,
    FormsModule,
    IonicModule,
    CoreModule,
    CommonModule,
    FormsModule,
    IonicModule,
    AltalanosModule,
    SharedModule,
    MaterialModule
  ],
  declarations: [
    JegyvasarlasPage,
    HorgasztatasReszletekPage,
    TextCardComponent,
    HorgaszrendNyelvPipe,
    HorgaszrendComponent,
    KeszComponent
  ]
})
export class JegyVasarlasPageModule {}
