import { EventEmitter } from '@angular/core';
import { FileUploaderMimeType } from 'app/file-uploader/file-uploader-mime-type.enum';
import { FileLikeObject } from 'ng2-file-upload';
import { KepSzerkesztoConfiguration } from '../altalanos/kep-szerkeszto-dialog/kep-szerkeszto-models';

export class FileUploaderConfiguration {
  showHint = true;
  defaultResultMessages = true;
  maxFileSizeInMb = 10;
  uploadPrefix: string;
  isProgressBar = true;
  allowedMimeTypes?: Array<FileUploaderMimeType> = [];
  feltoltoGomb?: FeltoltoGomb = new FeltoltoGomb();
  showResult = true;
  externalUploadEvent: EventEmitter<boolean>;
  kepszerkeszto: KepSzerkesztoConfiguration | undefined = undefined;
  dropzone = false;
  enableFilterByMimeType = false;
  additionalParameter: { [key: string]: any };

  isAllowedMimeType(fileLikeObject: FileLikeObject): boolean {
    const realAllowedMimeTypes = this.realAllowedMimeTypes();
    return this.allowedMimeTypes ? realAllowedMimeTypes.includes(fileLikeObject.type) : true;
  }
  isAllowedFileSize(fileLikeObject: FileLikeObject): boolean {
    return this.maxFileSizeInMb ? (fileLikeObject.size <= this.maxFileSizeInMb * 1024 * 1024) : true;
  }

  realAllowedMimeTypes(): Array<string> {
    const allowedMimeTypes = [];
    this.allowedMimeTypes.map(mt => mt.valueOf().split(';')).forEach(rmta => rmta.forEach(rmt => allowedMimeTypes.push(rmt)));
    return allowedMimeTypes.length === 0 ? undefined : allowedMimeTypes;
  }

}

export class FeltoltoGomb {
  szoveg?: string = 'Tallózás';
  icon?: string;
}

