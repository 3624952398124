import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { Utils } from 'app/core/utils';

/*
* String tömbből vissza adja a kezdőbetűket
* pl.: [Tóth, Jakab] => TJ
*/
@Directive({
  selector: '[monogram]'
})
export class MonogramDirective implements OnChanges {
  @Input() nev: string | Array<string>;
  @Input() monogramText: string;

  spanElement: any;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.monogramText || changes.nev) {
      if (this.spanElement) {
        this.renderer.removeChild(this.el.nativeElement, this.spanElement);
      }
      this.createMonogram();
    }
  }

  private createMonogram() {
    let monogram = this.getMonogramText();
    if (!monogram) {
      monogram = '--';
    }
    this.spanElement = this.renderer.createElement('span');
    const text = this.renderer.createText(monogram);
    this.renderer.appendChild(this.spanElement, text);
    this.renderer.appendChild(this.el.nativeElement, this.spanElement);
  }

  private getMonogramText(): string {
    let monogram: string = this.monogramText;
    if (!Utils.hasValue(monogram) || monogram === '') {
      let nevArray: Array<string>;
      if (this.nev && this.nev instanceof Array && this.nev.length > 0) {
        nevArray = this.nev;
      }
      if (this.nev && typeof this.nev === 'string' && this.nev.length > 0) {
        nevArray = this.nev.split(' ');
      }
      if (nevArray) {
        monogram = '';
        nevArray.forEach((nevPart) => {
          if (nevPart) {
            monogram = monogram + nevPart.toUpperCase().substring(0, 1);
          }
        });
      }
    }
    return monogram;
  }
}
