import { PipeTransform } from '@angular/core';

export class SelectableItem {
  id: any;
  nev: any;

  constructor(value, pipe: PipeTransform) {
    this.id = value;
    this.nev = pipe.transform(value);
  }

  static of(id: any, nev: any): SelectableItem{
    return { id, nev};
  }
}
