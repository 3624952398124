<div class="ion-page" id="main-content">
  <ion-content>
    <ion-tabs>
      <ion-tab-bar slot="bottom" color="secondary" class="without-label-underline">

        <ion-tab-button [tab]="routesConfig.korlatozottTeruletek" layout="icon-top">
          <ion-icon name="alert-circle-outline"></ion-icon>
          <ion-label>Korlátozások</ion-label>
        </ion-tab-button>

        <ion-tab-button [tab]="routesConfig.horgaszEngedelyek" layout="icon-top">
          <ion-icon src="/assets/icon/ticket-outline.svg"></ion-icon>
          <ion-label>Jegyeim</ion-label>
        </ion-tab-button>

        <ion-tab-button [tab]="routesConfig.teruletiJegyVasarlas" layout="icon-top">
          <ion-icon src="/assets/icon/basket-outline.svg"></ion-icon>
          <ion-label>Jegyvásárlás</ion-label>
        </ion-tab-button>

        <ion-tab-button [tab]="routesConfig.menu" layout="icon-top" color="primary">
          <ion-icon src="/assets/icon/menu-outline.svg"></ion-icon>
          <ion-label>Menü</ion-label>
        </ion-tab-button>

      </ion-tab-bar>

    </ion-tabs>
  </ion-content>
</div>
