import { Pipe, PipeTransform } from '@angular/core';
import { VizteruletCsoportLeiras } from 'api';

@Pipe({
  name: 'horgaszrendNyelv'
})
export class HorgaszrendNyelvPipe implements PipeTransform {

  transform(value: any): any {
    switch (value) {
      case VizteruletCsoportLeiras.NyelvEnum.MAGYAR: return 'Magyar';
      case VizteruletCsoportLeiras.NyelvEnum.NEMET: return 'Deutsch';
      case VizteruletCsoportLeiras.NyelvEnum.ANGOL: return 'English';
      case VizteruletCsoportLeiras.NyelvEnum.EGYEB: return 'Other';
    }
  }
}
