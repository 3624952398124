import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { TerkepConstants } from 'app/terkep/shared/terkep-constants';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { RegPont } from '../reg-pont';
import { RegPontService } from '../reg-pont.service';

@Component({
    selector: 'mohosz-regpont-map-header',
    templateUrl: './reg-pont-header.component.html',
    styleUrls: ['./reg-pont-header.component.less']
})
export class RegPontHeaderComponent implements OnInit {
    @Output() public selectedRegPontChanged = new EventEmitter<RegPont>();

    public regPontIcon: string;

    public variableInput = new FormControl();
    public filteredRegPontok: Observable<Array<RegPont>>;
    private regPontok: Array<RegPont> = [];

    constructor(
      private regPontService: RegPontService
    ){
    }

    public ngOnInit() {
      this.regPontIcon = TerkepConstants.REG_PONT_ICON;
      this.loadRegPontok();
      this.initSearch();
    }

    public displayOptionNev(option: RegPont): string {
      let nev: string;
      if (option) {
        if (option.nev != null) {
          nev = option.nev;
        } else {
          nev = '';
          console.warn('A Keresopont does not have name attribute: ' + option);
        }
      } else {
        nev = '';
      }
      return nev;
    }

    public onSelectionChanged(event: MatAutocompleteSelectedEvent) {
      const talaltRegPont: RegPont = event.option.value;
      this.selectedRegPontChanged.emit(talaltRegPont);
    }

    private initSearch() {
      this.filteredRegPontok = this.variableInput.valueChanges
        .pipe(
          startWith(''),
          map((value) => value ? this.filterRegPontok(value) : this.regPontok.slice())
        );
    }

    private async loadRegPontok() {
      try {
        const result = await this.regPontService.getRegpontObservable().toPromise();
        this.regPontok = this.regPontok.concat(result.filter(regPont => regPont.lat && regPont.lng));
      } catch (err) {
        console.error(err);
      }
    }


    private filterRegPontok(value: string): Array<RegPont> {
      const filterValue = value.toString().toLowerCase();
      return this.regPontok.filter(
        (regPont: RegPont ) => (
          (regPont.tipus === TerkepConstants.REGPONTTIPUS)
          && (regPont.nev.toLowerCase().includes(filterValue)
            || regPont.iranyitoSzam.toString().toLowerCase().includes(filterValue)
            || regPont.varos.toLowerCase().includes(filterValue)
            || regPont.kozteruletNev.toLowerCase().includes(filterValue)
            || regPont.kozteruletTipus.toLowerCase().includes(filterValue)
            || regPont.hazSzam.toLowerCase().includes(filterValue)
          )
        )
      );
    }

}
