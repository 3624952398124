import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PostafiokItem } from 'api';
import { PostafiokService } from 'app/services/postafiok.service';
import { routesConfig } from 'config/routesConfig';



@Component({
  selector: 'mohosz-bejovo-uzenetek',
  templateUrl: './bejovo-uzenetek.page.html',
  styleUrls: ['./bejovo-uzenetek.page.scss'],
})
export class BejovoUzenetekPage extends OnDestroyMixin {

  olvasatlanUzenetList: Array<PostafiokItem>;
  olvasottUzenetList: Array<PostafiokItem>;
  selectedUzenet: PostafiokItem;

  constructor(
    private postafiokService: PostafiokService,
    private router: Router
  ) {
    super();
  }

  ionViewDidEnter(){
    this.postafiokService.uzenetek.pipe(untilComponentDestroyed(this)).subscribe(resp => {
      this.olvasatlanUzenetList = resp.olvasatlanUzenetList;
      this.olvasottUzenetList = resp.olvasottUzenetList;
    });
    this.postafiokService.refreshUzenetList();
  }

  onUzenetListItemClick(uzenet: PostafiokItem) {
    this.selectedUzenet = uzenet;
    if (!uzenet.olvasott) {
      this.postafiokService.uzenetKezbesites(uzenet);
    }
    this.router.navigateByUrl(`/${routesConfig.tabs}/${routesConfig.postafiok}/${routesConfig.uzenet}/${uzenet.sourceId}`);
  }

  onValaszClick(uzenet: PostafiokItem, valasz: boolean) {
    this.postafiokService.uzenetValasz(uzenet, valasz);
  }

  handleRefresh(event) {
    this.postafiokService.refreshUzenetList();
    event.target.complete();
  };

}
