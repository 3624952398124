import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'penzosszeg'
})
export class PenzosszegPipe implements PipeTransform {

  constructor(private currencyPipe: CurrencyPipe) {
  }

  transform(value: number): any {
    return this.currencyPipe.transform(value, 'HUF', 'symbol', '0.0-0');
  }

}
