import { Pipe, PipeTransform } from '@angular/core';
import { AllamiJegy } from 'api';


@Pipe({
    name: 'allamiJegyTipus'
})
export class AllamiJegyTipusPipe implements PipeTransform {
    transform(value: AllamiJegy.TipusEnum): any {
        switch (value) {
            case AllamiJegy.TipusEnum.ALLAMIJEGY: return 'Állami horgászjegy';
            case AllamiJegy.TipusEnum.TURISTAALLAMIJEGY: return 'Turista állami horgászjegy';
            case AllamiJegy.TipusEnum.ETURISTAALLAMIJEGY: return 'Elektronikus turista állami horgászjegy';
            default: return value;
        }
    }
}
