import { Component, OnInit } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { GPSKoordinata, HorgaszturizmusVedettMapPoi, MobilAppControllerService, VedettVizterulet } from 'api';
import { TimerMutex } from 'app/core/timer-mutex';
import { LifeCycleService } from 'app/services/lifecycle.service';
import { LocationService } from 'app/services/location.service';
import { ToastService } from 'app/services/toast.service';
import { VedettVizteruletTerkepComponent } from 'app/terkep/leaflet/vedett-vizterulet-terkep/vedett-vizterulet-terkep.component';
import { TerkepConstants } from 'app/terkep/shared/terkep-constants';
import { TerkepHelper } from 'app/terkep/shared/terkep-helper';
import * as Leaflet from 'leaflet';
import { ConnectionStateService } from '../services/connection-state.service';
import { UiVedettVizterulet } from './ui-vedett.vizterulet';

@Component({
  selector: 'mohosz-vedett-vizterulet',
  templateUrl: './vedett-vizterulet-page.component.html',
  styleUrls: ['./vedett-vizterulet-page.component.scss'],
  providers: [LifeCycleService]
})
export class VedettVizteruletPage extends OnDestroyMixin implements OnInit {

  locationPos: GPSKoordinata;
  korzetbenList: Array<UiVedettVizterulet>;
  vedettVizteruletList: Array<UiVedettVizterulet>;
  isLoading = true;

  korlatozasokListaToggleLathato = false;
  korlatozasokListaLathato = false;
  targetGPSKoordinata: GPSKoordinata;
  VedettVizteruletTipusEnum = VedettVizterulet.TipusEnum;

  allPoisLoaded = false;
  korzetKm = TerkepConstants.DEFAULT_LAYER_RADIUS / 1000;
  private timerMutex = new TimerMutex(1);
  private nearByPoisLoaded = false;


  constructor(
    private connectionStateService: ConnectionStateService,
    private locationService: LocationService,
    private mobilAppControllerService: MobilAppControllerService,
    private toastService: ToastService,
    private lifeCycleService: LifeCycleService
  ) {
    super();

    this.connectionStateService.onlineState.pipe(untilComponentDestroyed(this)).subscribe(state =>
      this.refreshContent()
    );
  }

  ngOnInit(): void {
    this.lifeCycleService.didEnter.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.onViewEnter();
    });
  }

  ionViewDidEnter() {
    this.lifeCycleService.propagateDidEnter();
  }

  onViewEnter(){
    this.targetGPSKoordinata = null;
    this.isLoading = true;
    this.nearByPoisLoaded = false;
    this.allPoisLoaded = false;
    this.refreshContent();
  }

  onMapReady(terkep: VedettVizteruletTerkepComponent) {
    TerkepHelper.invalidateSize(terkep.map);
  }

  onMapZoomEnd(event: Leaflet.LeafletEvent) {
    this.refreshContent();
  }

  onMapMoveEnd(event: Leaflet.LeafletEvent) {
    this.refreshContent();
  }

  onVedettVizteruletListItemClick(vedettVizterulet: UiVedettVizterulet) {
    this.targetGPSKoordinata = {...vedettVizterulet.markerKoordinata};
    this.onListToggleClick();
  }

  onListToggleClick() {
    this.korlatozasokListaLathato = !this.korlatozasokListaLathato;
  }

  private loadPoiList() {
    if(this.connectionStateService.isOnline()){
      this.locationService.getLocation()
        .then(pos => {
          this.locationPos = pos;
          if (!this.nearByPoisLoaded) {
            this.mobilAppControllerService.findVedettNearbyMapPois(this.locationPos).toPromise()
              .then(nearbyPois => {
                this.vedettVizteruletList = this.vedettVizteruletList ?? [];
                this.korzetbenList = this.prepareVedettVizteruletList(nearbyPois);
                this.korzetbenList?.forEach(poi => {
                  if (!this.vedettVizteruletList.find(p => TerkepHelper.isEqualGPSKoorinatak(p.markerKoordinata, poi.markerKoordinata))) {
                    this.vedettVizteruletList.push(poi);
                  }
                });
                this.vedettVizteruletList = [...this.vedettVizteruletList];
                this.nearByPoisLoaded = true;
              })
              .catch(error => this.toastService.httpError(error))
              .finally(() => this.isLoading = false);
          } else {
            if (!this.allPoisLoaded) {
              this.mobilAppControllerService.findAllVedettPois().toPromise()
                .then(allPois => {
                  this.vedettVizteruletList = this.prepareVedettVizteruletList(allPois);
                  this.allPoisLoaded = true;
                })
                .catch(error => this.toastService.httpError(error))
                .finally(() => this.isLoading = false);
            }
          }
        })
        .catch((error) => {
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
  }

  private prepareVedettVizteruletList(poiList: Array<HorgaszturizmusVedettMapPoi>): Array<UiVedettVizterulet> {
    let vedettVizteruletList: Array<UiVedettVizterulet> = [];
    poiList.forEach(poi => {
      vedettVizteruletList = [
        ...vedettVizteruletList,
        ...this.extendVedettVizterulet(poi.korlatozottVizteruletList, poi.nev),
        ...this.extendVedettVizterulet(poi.tiltottVizteruletList, poi.nev),
      ];
    });
    return vedettVizteruletList;
  }

  private extendVedettVizterulet(vedettVizteruletList: Array<VedettVizterulet>, vizteruletNev: string) {
    return vedettVizteruletList.map(kvt => {
      const vt: UiVedettVizterulet = kvt;
      vt.vizteruletNev = vizteruletNev;
      return vt;
    });
  }

  private refreshContent() {
    this.timerMutex.runExclusive(() => {
      this.loadPoiList();
    });
  }
}
