import { Component, OnInit, Input } from '@angular/core';
import { AllamiJegy } from 'api';

@Component({
  selector: 'mohosz-allami-jegy-card',
  templateUrl: './allami-jegy-card.component.html',
  styleUrls: ['../jegyek.page.scss'],
})
export class AllamiJegyCardComponent implements OnInit {

  @Input() allamiJegy: AllamiJegy;

  AllamiJegyStatuszEnum = AllamiJegy.StatuszEnum;

  constructor() { }

  ngOnInit() {}

}
