import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadModule } from 'ng2-file-upload';
import { FileUploaderMimeTypePipe } from './file-uploader-mime-type.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IonicModule } from '@ionic/angular';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MaterialModule } from '../material/material.module';
import { CoreModule } from 'app/core/core.module';
import { FileUploaderComponent } from 'app/file-uploader/file-uploader/file-uploader.component';


@NgModule({
  imports: [
    CommonModule,
    FileUploadModule,
    MaterialModule,
    CoreModule,
    FlexLayoutModule,
    IonicModule,
    MatProgressBarModule,
  ],
  declarations: [
    FileUploaderComponent,
    FileUploaderMimeTypePipe,
  ],
  exports: [
    FileUploaderComponent,
  ]
})
export class FileUploaderModule {}
