<ion-content [fullscreen]="true" class="moh-bg-light" scroll-y="false">

  <mohosz-no-content *ngIf="!poiList" [isLoading]="isLoading"></mohosz-no-content>

  <mohosz-horgaszturizmus-terkep *ngIf="poiList"
    [poiList]="poiList"
    [locationPos]="locationPos"
    (mapZoomEnd)="onMapZoomEnd($event)"
    (mapMoveEnd)="onMapMoveEnd($event)">
  </mohosz-horgaszturizmus-terkep>

</ion-content>
