import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TeruletiJegy } from 'api';
import { ErvenyessegDatumPipe } from 'app/altalanos/pipes/ervenyesseg-datum-pipe';


@Component({
  selector: 'mohosz-teruleti-jegy-card',
  templateUrl: './teruleti-jegy-card.component.html',
  styleUrls: ['../jegyek.page.scss'],
})
export class TeruletiJegyCardComponent implements OnInit {

  @Input() teruletiJegy: TeruletiJegy;
  @Output() kiegeszitoVasarlas = new EventEmitter();

  ervenyessegDatumPipe = new ErvenyessegDatumPipe();

  constructor(
  ) { }

  ngOnInit() {
  }
}
