import { Pipe, PipeTransform } from '@angular/core';
import { Flog } from 'api/model/flog';

@Pipe({
  name: 'flog'
})
export class FlogPipe implements PipeTransform {
  transform(value: Flog.LathatosagEnum): any {
    switch (value) {
      case Flog.LathatosagEnum.PRIVAT: return 'Privát';
      case Flog.LathatosagEnum.PUBLIKUS: return 'Publikus';
      case Flog.LathatosagEnum.ISMEROS: return 'Ismerős';
      case Flog.LathatosagEnum.PUBLIKUSNAKJELOLT: return 'Publikusnak jelölt';
      default: return value;
    }
  }
}
