/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TeruletiJegyOnlineSztornoDetails { 
    afakulcs?: TeruletiJegyOnlineSztornoDetails.AfakulcsEnum;
    ertesitesElkuldve?: boolean;
    idopont?: Date;
    sorszam?: string;
    szamlaszam?: string;
    szamlaszamTulajdonosNev?: string;
    sztornozottSorszamList?: Array<string>;
    teljesAr?: number;
}
export namespace TeruletiJegyOnlineSztornoDetails {
    export type AfakulcsEnum = 'AKMAA' | 'AK27' | 'AK5';
    export const AfakulcsEnum = {
        AKMAA: 'AKMAA' as AfakulcsEnum,
        AK27: 'AK27' as AfakulcsEnum,
        AK5: 'AK5' as AfakulcsEnum
    };
}