import {Pipe, PipeTransform} from '@angular/core';
import {TeruletiKiegeszitoJegy} from 'api';

@Pipe({
  name: 'teruletiJegyKiegeszitoTipus'
})
export class TeruletiJegyKiegeszitoTipusPipe implements PipeTransform {
  transform(value: TeruletiKiegeszitoJegy.TipusEnum, args?: any): any {
    if (args === 'leiras') {
      switch (value) {
        case TeruletiKiegeszitoJegy.TipusEnum.BOJLIS: return 'Érvényes általános vagy parti területi jegy birtokában, bojlis horgászatra jogosít.';
        case TeruletiKiegeszitoJegy.TipusEnum.BEHUZOS: return 'Érvényes általános, parti, vagy bojlis területi jegy birtokában, a szerelék behúzására jogosít.';
        case TeruletiKiegeszitoJegy.TipusEnum.CSONAKHASZNALATI: return 'Érvényes parti területi jegy birtokában, csónak-használatra jogosít.';
        case TeruletiKiegeszitoJegy.TipusEnum.EJSZAKAIHORGASZATI: return 'Érvényes területi jegy birtokában, a helyi horgászrend szerinti éjszakai horgászatra jogosít.';
        case TeruletiKiegeszitoJegy.TipusEnum.HAROMBOTOS: return 'Érvényes általános vagy parti területi jegy birtokában, három horgászkészség használatára jogosít.';
        case TeruletiKiegeszitoJegy.TipusEnum.HELYFOGLALASOS: return 'Érvényes területi jegy birtokában, egy előre megjelölt helyen történő horgászatra jogosít.';
        case TeruletiKiegeszitoJegy.TipusEnum.SPECIALISVIZTERULETI: return 'Érvényes területi jegy birtokában egy speciális halállományú, nem önálló víztérkódú vízterületen történő horgászatra jogosít.';
        case TeruletiKiegeszitoJegy.TipusEnum.TOBBLETHALELVITELI: return 'Érvényes általános vagy parti területi jegy birtokában, többlet halelvitelre jogosít.';
        case TeruletiKiegeszitoJegy.TipusEnum.VERSENYRESZVETELI: return 'Érvényes területi jegy birtokában, horgász-verseny nevezési díjának megváltására jogosít.';
        default: return value;
      }
    } else {
      switch (value) {
        case TeruletiKiegeszitoJegy.TipusEnum.BOJLIS: return 'Bojlis';
        case TeruletiKiegeszitoJegy.TipusEnum.BEHUZOS: return 'Behúzós';
        case TeruletiKiegeszitoJegy.TipusEnum.CSONAKHASZNALATI: return 'Csónak/lajtstromszámos vízijármű használat';
        case TeruletiKiegeszitoJegy.TipusEnum.EJSZAKAIHORGASZATI: return 'Éjszakai horgászat';
        case TeruletiKiegeszitoJegy.TipusEnum.HAROMBOTOS: return 'Hárombotos';
        case TeruletiKiegeszitoJegy.TipusEnum.HELYFOGLALASOS: return 'Helyfoglalásos';
        case TeruletiKiegeszitoJegy.TipusEnum.SPECIALISVIZTERULETI: return 'Speciális vízterület';
        case TeruletiKiegeszitoJegy.TipusEnum.TOBBLETHALELVITELI: return 'Többlet halelvitel';
        case TeruletiKiegeszitoJegy.TipusEnum.VERSENYRESZVETELI: return 'Versenyrészvétel';
        default: return value;
      }
    }
  }
}
