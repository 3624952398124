import { VedettVizterulet } from 'api';

export class VedettVizteruletTerkepHelper {

  private static KORLATOZOTT_VIZTERULET_POI = 'assets/poi/marker/korlatozott-vizterulet.svg';
  private static TILTOTT_VIZTERULET_POI = 'assets/poi/marker/tiltott-vizterulet.svg';

  private static readonly KORLATOZOTT_TERULET_COLOR = '#F19908'; // orange-peel
  private static readonly TILTOTT_TERULET_COLOR = '#FF3952'; // danger-color
  private static readonly ACTIVE_TERULET_COLOR = '#0894FF'; // dodger-blue

  private static SHARED_LAYER_OPTIONS = {
    fill: true,
    fillColor: null,
    fillOpacity: 0.5,
    opacity: 0.8,
    stroke: true,
    weight: 4,
    noClip: true
  };

  private static readonly DRAW_LAYER_OPTIONS = {
    clickable: true,
    color: VedettVizteruletTerkepHelper.ACTIVE_TERULET_COLOR,
    ...VedettVizteruletTerkepHelper.SHARED_LAYER_OPTIONS
  };
  private static readonly DISPLAY_KORLATOZOTT_LAYER_OPTIONS = {
    clickable: false,
    color: VedettVizteruletTerkepHelper.KORLATOZOTT_TERULET_COLOR,
    ...VedettVizteruletTerkepHelper.SHARED_LAYER_OPTIONS
  };
  private static readonly DISPLAY_VEDETT_LAYER_OPTIONS = {
    clickable: false,
    color: VedettVizteruletTerkepHelper.TILTOTT_TERULET_COLOR,
    ...VedettVizteruletTerkepHelper.SHARED_LAYER_OPTIONS
  };

  public static getDrawLayerOptions() {
    return VedettVizteruletTerkepHelper.DRAW_LAYER_OPTIONS;
  }

  public static getDisplayLayerOptions(tipus: VedettVizterulet.TipusEnum) {
    switch (tipus) {
      case VedettVizterulet.TipusEnum.KORLATOZASALAVONTVIZTERULET: return VedettVizteruletTerkepHelper.DISPLAY_KORLATOZOTT_LAYER_OPTIONS;
      case VedettVizterulet.TipusEnum.TILTOTTVIZTERULET: return VedettVizteruletTerkepHelper.DISPLAY_VEDETT_LAYER_OPTIONS;
    }
  }

  public static getMarkerIcon(tipus: VedettVizterulet.TipusEnum) {
    switch (tipus) {
      case VedettVizterulet.TipusEnum.KORLATOZASALAVONTVIZTERULET: return VedettVizteruletTerkepHelper.KORLATOZOTT_VIZTERULET_POI;
      case VedettVizterulet.TipusEnum.TILTOTTVIZTERULET: return VedettVizteruletTerkepHelper.TILTOTT_VIZTERULET_POI;
    }
  }
}
