<div class="map-container">
  <ng-container *ngIf="locationPos">
    <div leaflet id="kedvenceditmap" #map
      [leafletOptions]="mapOptions"
      [leafletLayers]="layerList"
      (leafletMapReady)="onMapReady($event)"
      (leafletClick)="onMapClick($event)">
    </div>
    <div class="poi-popup-container" *ngIf="selectedKozelbenMarker">
      <mohosz-vizterulet-kapcsolas-popup-card
        [poi]="selectedKozelbenMarker.mapPoi"
        (vizteruletKapcsolas)="onVizteruletKapcsolas($event)"
        (closePoiPopup)="onClosePoiPopupClick()">
      </mohosz-vizterulet-kapcsolas-popup-card>
    </div>
  </ng-container>
</div>
