import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { routesConfig } from 'config/routesConfig';
import { AuthGuard } from '../auth/auth.guard';
import { HorgaszEngedelyekPage } from './horgasz-engedelyek.page';
import { HorgaszkartyaPageModule } from './horgaszkartya/horgaszkartya.module';
import { ErtekesitesiPontokPage } from './jegyek/ertekesitesi-pontok/ertekesitesi-pontok.page';
import { JegyekPageModule } from './jegyek/jegyek.module';
import { VersenyzoiEngedelyPageModule } from './versenyzoi-engedely/versenyzoi-engedely.module';


const routes: Routes = [
  {
    path: '',
    component: HorgaszEngedelyekPage,
    children: [
      {
        path: routesConfig.horgaszkartya, loadChildren: () => HorgaszkartyaPageModule, canActivate: [AuthGuard]
      },
      {
        path: routesConfig.jegyek, loadChildren: () => JegyekPageModule, canActivate: [AuthGuard]
      },
      {
        path: routesConfig.versenyzoiEngedely, loadChildren: () => VersenyzoiEngedelyPageModule, canActivate: [AuthGuard]
      },
      {
        path: '', redirectTo: `${routesConfig.jegyek}`, pathMatch: 'prefix'
      }
    ]
  },
  { path: routesConfig.ertekesitesiPontok,
    component: ErtekesitesiPontokPage
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HorgaszEngedelyekPageRoutingModule {}
