/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { FelhasznaloMeghivasRequest } from '../model/felhasznaloMeghivasRequest';
import { Jogosultsag } from '../model/jogosultsag';
import { JogosultsagTorlesRequest } from '../model/jogosultsagTorlesRequest';
import { MegbizoDetails } from '../model/megbizoDetails';
import { MegbizoJogosultsagBundle } from '../model/megbizoJogosultsagBundle';
import { MegbizoKivalasztasRequest } from '../model/megbizoKivalasztasRequest';
import { SssUser } from '../model/sssUser';
import { SzereploRequest } from '../model/szereploRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class JogosultsagControllerService {

    protected basePath = 'http://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public felhasznaloMeghivas(body: FelhasznaloMeghivasRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public felhasznaloMeghivas(body: FelhasznaloMeghivasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public felhasznaloMeghivas(body: FelhasznaloMeghivasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public felhasznaloMeghivas(body: FelhasznaloMeghivasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling felhasznaloMeghivas.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/jogosultsag/jogosultsag-felhasznalomeghivas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public felhasznaloMeghivasAktivalas(body: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public felhasznaloMeghivasAktivalas(body: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public felhasznaloMeghivasAktivalas(body: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public felhasznaloMeghivasAktivalas(body: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling felhasznaloMeghivasAktivalas.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/jogosultsag/jogosultsag-felhasznalomeghivas-aktivalas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public jogosultsagLekerdezes(body: SzereploRequest, observe?: 'body', reportProgress?: boolean): Observable<MegbizoJogosultsagBundle>;
    public jogosultsagLekerdezes(body: SzereploRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MegbizoJogosultsagBundle>>;
    public jogosultsagLekerdezes(body: SzereploRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MegbizoJogosultsagBundle>>;
    public jogosultsagLekerdezes(body: SzereploRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling jogosultsagLekerdezes.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MegbizoJogosultsagBundle>('post',`${this.basePath}/api/mohosz/jogosultsag/jogosultsag-bundle-lekerdezes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public jogosultsagModositas(body: Jogosultsag, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public jogosultsagModositas(body: Jogosultsag, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public jogosultsagModositas(body: Jogosultsag, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public jogosultsagModositas(body: Jogosultsag, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling jogosultsagModositas.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/jogosultsag/jogosultsag-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public jogosultsagTorles(body: JogosultsagTorlesRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public jogosultsagTorles(body: JogosultsagTorlesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public jogosultsagTorles(body: JogosultsagTorlesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public jogosultsagTorles(body: JogosultsagTorlesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling jogosultsagTorles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/jogosultsag/jogosultsag-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public megbizoKivalasztas(body: MegbizoKivalasztasRequest, observe?: 'body', reportProgress?: boolean): Observable<SssUser>;
    public megbizoKivalasztas(body: MegbizoKivalasztasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SssUser>>;
    public megbizoKivalasztas(body: MegbizoKivalasztasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SssUser>>;
    public megbizoKivalasztas(body: MegbizoKivalasztasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling megbizoKivalasztas.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SssUser>('post',`${this.basePath}/api/mohosz/jogosultsag/megbizokivalasztas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public megbizok(observe?: 'body', reportProgress?: boolean): Observable<Array<MegbizoDetails>>;
    public megbizok(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MegbizoDetails>>>;
    public megbizok(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MegbizoDetails>>>;
    public megbizok(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<MegbizoDetails>>('post',`${this.basePath}/api/mohosz/jogosultsag/megbizok`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ugyfelszolgalatiJogosultsagLekerdezes(body: SzereploRequest, observe?: 'body', reportProgress?: boolean): Observable<MegbizoJogosultsagBundle>;
    public ugyfelszolgalatiJogosultsagLekerdezes(body: SzereploRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MegbizoJogosultsagBundle>>;
    public ugyfelszolgalatiJogosultsagLekerdezes(body: SzereploRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MegbizoJogosultsagBundle>>;
    public ugyfelszolgalatiJogosultsagLekerdezes(body: SzereploRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ugyfelszolgalatiJogosultsagLekerdezes.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MegbizoJogosultsagBundle>('post',`${this.basePath}/api/mohosz/jogosultsag/ugyfelszolgalati-jogosultsag-bundle-lekerdezes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ugyfelszolgalatiJogosultsagModositas(body: Jogosultsag, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public ugyfelszolgalatiJogosultsagModositas(body: Jogosultsag, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public ugyfelszolgalatiJogosultsagModositas(body: Jogosultsag, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public ugyfelszolgalatiJogosultsagModositas(body: Jogosultsag, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ugyfelszolgalatiJogosultsagModositas.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/jogosultsag/ugyfelszolgalati-jogosultsag-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ugyfelszolgalatiJogosultsagTorles(body: JogosultsagTorlesRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public ugyfelszolgalatiJogosultsagTorles(body: JogosultsagTorlesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public ugyfelszolgalatiJogosultsagTorles(body: JogosultsagTorlesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public ugyfelszolgalatiJogosultsagTorles(body: JogosultsagTorlesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ugyfelszolgalatiJogosultsagTorles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/jogosultsag/ugyfelszolgalati-jogosultsag-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
