import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/auth.guard';
import { routesConfig } from 'config/routesConfig';
import { KedvencHorgaszhelySzerkesztesePageModule } from './kedvenc-horgaszhely-szerkesztese/kedvenc-horgaszhely-szerkesztese.module';
import { KedvencHorgaszhelyekPage } from './kedvenc-horgaszhelyek.page';
import { ListaNezetPageModule } from './lista-nezet/lista-nezet.module';
import { TerkepNezetPageModule } from './terkep-nezet/terkep-nezet.module';

const routes: Routes = [
  {
    path: routesConfig.szerkesztes, loadChildren: () => KedvencHorgaszhelySzerkesztesePageModule
  },
  {
    path: '', component: KedvencHorgaszhelyekPage, children: [
      {
        path: routesConfig.terkepNezet, loadChildren: () => TerkepNezetPageModule, canActivate: [AuthGuard]
      },
      {
        path: routesConfig.listaNezet, loadChildren: () => ListaNezetPageModule, canActivate: [AuthGuard]
      },
      {
        path: '',
        redirectTo: `${routesConfig.terkepNezet}`,
        pathMatch: 'prefix'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class KedvencHorgaszhelyekPageRoutingModule {}
