import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HorgaszturizmusPoiItem } from 'api';

@Component({
  selector: 'mohosz-horgasztatas-list-item',
  templateUrl: './horgasztatas-list-item.component.html',
  styleUrls: ['./horgasztatas-list-item.component.scss'],
})
export class HorgasztatasListItemComponent implements OnInit {

  @Input() poi: HorgaszturizmusPoiItem;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {}

  onMegnyitasClick() {
    if (this.poi?.szolgaltatasId) {
      this.router.navigateByUrl(`/tabs/jegyvasarlas/horgasztatas/${this.poi.szolgaltatasId}`);
    }
  }

}
