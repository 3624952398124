import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { GPSKoordinata, KedvencHorgaszhely } from 'api';
import { Utils } from 'app/core/utils';
import { LifeCycleService } from 'app/services/lifecycle.service';
import { PoiMarkerDataKedvencHorgaszhely } from 'app/terkep/shared/poi-marker-data-kedvenc-horgaszhely';
import { TerkepConstants } from 'app/terkep/shared/terkep-constants';
import { TerkepHelper } from 'app/terkep/shared/terkep-helper';
import * as Leaflet from 'leaflet';
import { Observable, ReplaySubject } from 'rxjs';


@Component({
  selector: 'mohosz-kedvenc-horgaszhely-terkep',
  templateUrl: './kedvenc-horgaszhely-terkep.component.html',
  styleUrls: ['./kedvenc-horgaszhely-terkep.component.scss'],
})
export class KedvencHorgaszhelyTerkepComponent extends OnDestroyMixin implements OnInit, OnChanges {

  @Input() kivalasztottKedvencHorgaszhely: KedvencHorgaszhely;
  @Input() locationPos: GPSKoordinata;
  @Input() kedvencHorgaszhelyList: Array<KedvencHorgaszhely>;

  @Output() mapReady: EventEmitter<boolean> = new EventEmitter();
  @Output() markerSelectionChanged: EventEmitter<KedvencHorgaszhely> = new EventEmitter();

  locationMarker: Leaflet.Marker;
  mapOptions: Leaflet.MapOptions = TerkepConstants.getDefaultOptions();
  map: Leaflet.Map;
  markerLayerList: Array<Leaflet.Marker> = [];
  markers: Array<PoiMarkerDataKedvencHorgaszhely> = [];
  selectedMarker: PoiMarkerDataKedvencHorgaszhely;

  private simpleChangesSubject = new ReplaySubject<SimpleChanges>();
  private simpleChangesObservable: Observable<SimpleChanges>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private zone: NgZone,
    private lifeCycleService: LifeCycleService
  ) {
    super();
    this.simpleChangesObservable = this.simpleChangesSubject.asObservable();
  }

  ngOnInit(): void {
    this.lifeCycleService.didEnter.pipe(untilComponentDestroyed(this)).subscribe(() => {
      if (this.map) {
        TerkepHelper.invalidateSize(this.map);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.simpleChangesSubject.next(changes);
  }

  async onMapReady(map: Leaflet.Map) {
    this.map = map;

    //start processing changes
    this.simpleChangesObservable.pipe(untilComponentDestroyed(this)).subscribe(changes => {
      let mustRefreshMapPosition = false;
      if (changes.kedvencHorgaszhelyList || changes.locationPos) {
        mustRefreshMapPosition = true;
      }
      if (changes.kedvencHorgaszhelyList) {
        this.refreshPOIs();
      }
      if (changes.kivalasztottKedvencHorgaszhely) {
        this.selectKedvencHorgaszhely(this.kivalasztottKedvencHorgaszhely);
      }
      if (changes.locationPos && Utils.hasValue(this.locationPos)) {
        this.refreshLocationLayer();
      }

      if (mustRefreshMapPosition) {
        //csak egyszer frissitjuk
        this.refreshMapPosition();
      }
      TerkepHelper.invalidateSize(this.map);
    });
  }

  onMapClick(e: Leaflet.LeafletMouseEvent) {
    this.onClosePoiPopupClick();
  }

  onClosePoiPopupClick() {
    this.clearMarkerSelection();
    this.markerSelectionChanged.emit(null);
  }

  moveToTarget(kedvencHorgaszhely: KedvencHorgaszhely) {
    this.selectKedvencHorgaszhely(kedvencHorgaszhely, true);
  }

  private clearMarkerSelection() {
    if (this.selectedMarker) {
      this.selectedMarker.marker.setIcon(this.createIcon(false));
      this.selectedMarker = undefined;
      this.changeDetectorRef.detectChanges();
    }
  }

  private createIcon(selected: boolean): Leaflet.Icon {
    return new Leaflet.Icon({
      iconUrl: TerkepConstants.KEDVENC_HORGASZHELY_POI,
      iconSize: selected ? [57, 80] : [38, 53],
      iconAnchor: selected ? [28, 80] : [19, 53]
    });
  }

  private async refreshMapPosition() {
    if (this.kedvencHorgaszhelyList?.length > 0) {
      if (!this.kivalasztottKedvencHorgaszhely) {
        this.map.fitBounds(TerkepHelper.createBounds(this.kedvencHorgaszhelyList.map(item => item.koordinata)),
          {maxZoom: TerkepConstants.DEFAULT_MAP_ZOOM});
      }
    } else {
      if (this.locationMarker) {
        this.map.setView(this.locationMarker.getLatLng(), TerkepConstants.ZOOM_LEVEL_KOZELBEN);
      } else {
        this.map.setView(TerkepConstants.DEFAULT_MAP_CENTER, TerkepConstants.ZOOM_LEVEL_HUNGARY);
      }
    }
  }

  private async refreshPOIs() {
    this.markerLayerList = [];
    this.markers = [];

    const kedvencHorgaszhelyPoiList = this.kedvencHorgaszhelyList ?? [];
    if (this.kedvencHorgaszhelyList?.length > 0) {
      kedvencHorgaszhelyPoiList.forEach(
        poi => {
          if (poi.koordinata) {
            const latLng = new Leaflet.LatLng(poi.koordinata.szelessegiFok, poi.koordinata.hosszusagiFok);
            const marker = new Leaflet.Marker(latLng, {
              icon: this.createIcon(false),
            }).on('click', () => {
              this.zone.run(() => {
                this.selectMarker(marker);
                this.markerSelectionChanged.emit(poi);
              });
            });
            this.markers.push({marker, poi});
          } else {
            console.log(['Koordináta nélküli poi!', poi]);
          }
        }
      );
      this.markerLayerList = this.markers.map(pont => pont.marker);
    }
    this.changeDetectorRef.detectChanges();
  }

  private selectKedvencHorgaszhely(kedvencHorgaszhely: KedvencHorgaszhely, moveTo?: boolean) {
    this.clearMarkerSelection();
    if (kedvencHorgaszhely) {
      const targetMarker = this.markers.find(m => TerkepHelper.isEqualGPSKoorinatak(m.poi.koordinata, kedvencHorgaszhely.koordinata));
      if (targetMarker?.poi) {
        this.selectMarker(targetMarker.marker).then(() => {
          if (moveTo) {
            this.map.fitBounds(TerkepHelper.createBounds([kedvencHorgaszhely.koordinata]), {maxZoom: TerkepConstants.DEFAULT_MAP_ZOOM});
            this.map.panTo(targetMarker.marker.getLatLng());
          }
        });
      }
    }
  }

  private async selectMarker(marker: Leaflet.Marker) {
    if (this.selectedMarker) {
      this.selectedMarker.marker.setIcon(this.createIcon(false));
    }
    this.selectedMarker = this.markers.find(item => item.marker === marker);
    marker.setIcon(this.createIcon(true));
    //this.map.panTo(marker.getLatLng());
    //this.map.fitBounds(TerkepHelper.createBounds([this.selectedMarker.poi.koordinata]), {maxZoom: TerkepConstants.DEFAULT_MAP_ZOOM});
  }

  private refreshLocationLayer() {
    // lokáció marker
    this.locationMarker?.removeFrom(this.map);
    this.locationMarker = TerkepHelper.createLocationMarker(this.locationPos);
    this.locationMarker.addTo(this.map);
  }
}
