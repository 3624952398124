export interface FixVizteruletLeiras {
  nyelv: string;
  szoveg: string;
}

export class HorgaszrendErvenyessegLeiras {

  protected static readonly fixLeirasok: Array<FixVizteruletLeiras> = [
    {
      nyelv: 'MAGYAR',
      szoveg: 'A horgászrend december 31.-ig érvényes, kérjük december 31. után tájékozódjon az új horgászrendről!'
    },
    {
      nyelv: 'ANGOL',
      szoveg: 'The fishing order is valid until December 31, please find out about the new fishing order after December 31!'
    },
    {
      nyelv: 'NEMET',
      szoveg: 'Die Fangbestellung ist bis zum 31. Dezember gültig. Informieren Sie sich nach dem 31. Dezember über die neue Fangbestellung!'
    }
  ];

  static findLeiras(nyelv: string) {
    const leiras = HorgaszrendErvenyessegLeiras.fixLeirasok.find(item => item.nyelv === nyelv);
    return leiras ? leiras.szoveg : '';
  }

}
