import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ElektronikusFogas } from 'api';

@Pipe({
  name: 'fogasDate'
})
export class FogasDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {
  }

  transform(fogas: ElektronikusFogas): any {
    return this.datePipe.transform(fogas.idopont, 'yyyy MMM dd HH:mm');
  }
}
