import { VizteruletCsoportLeiras } from 'api';
import { Utils } from 'app/core/utils';
import { HorgaszrendErvenyessegLeiras } from './horgaszrend-ervenyesseg-leiras';


export class HorgaszrendData {
  horgaszrendContentId: number;
  leirasList: Array<VizteruletCsoportLeiras>;

  constructor(horgaszrendContentId: number, leirasList: Array<VizteruletCsoportLeiras>) {
    this.horgaszrendContentId = horgaszrendContentId;
    this.leirasList = leirasList;
  }

  isEmpty(): boolean {
    return !Utils.hasValue(this.horgaszrendContentId) && !this.leirasList?.find(leiras => Utils.isNonEmptyString(leiras.szoveg));
  }

  dispalyedLeirasList(): Array<VizteruletCsoportLeiras> {
    const leirasList = JSON.parse(JSON.stringify(this.leirasList));
    return leirasList?.filter(leiras => Utils.isNonEmptyString(leiras.szoveg)).map(leiras => ({
        nyelv: leiras.nyelv,
        szoveg: leiras.szoveg += '<br><p>' + HorgaszrendErvenyessegLeiras.findLeiras(leiras.nyelv.toString()) + '</p>'
      })) ?? [];
  }

  hasPDFContent() {
    return Utils.hasValue(this.horgaszrendContentId);
  }
}
