/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { HorgasztatasSzolgaltatasRegisztracio } from './horgasztatasSzolgaltatasRegisztracio';
import { SzolgaltatasDetails } from './szolgaltatasDetails';
import { SzolgaltatasListItem } from './szolgaltatasListItem';

export interface HorgasztatasSzolgaltatasBundle { 
    availableCommands?: Array<HorgasztatasSzolgaltatasBundle.AvailableCommandsEnum>;
    details?: SzolgaltatasDetails;
    ownerSzereploId?: number;
    regisztracio?: HorgasztatasSzolgaltatasRegisztracio;
    statusz?: HorgasztatasSzolgaltatasBundle.StatuszEnum;
    szolgaltatasId?: number;
    szolgaltatasListItem?: SzolgaltatasListItem;
    vanJovahagyottRegisztracio?: boolean;
}
export namespace HorgasztatasSzolgaltatasBundle {
    export type AvailableCommandsEnum = 'REGISZTRACIO_VEGLEGESITES' | 'RESZLETEK_MEGNYITAS' | 'ELONEZET_MEGNYITAS' | 'TERMEKEK_MEGNYITAS' | 'REGISZTRACIO_MEGNYITAS' | 'ELBIRALAS' | 'MODOSITAS' | 'INAKTIVALAS' | 'AKTIVALAS' | 'FELUGYELOI_INAKTIVALAS' | 'FELUGYELOI_AKTIVALAS' | 'FIZIKAI_TORLES' | 'AKTIVACIOS_FIZETES' | 'NAPIDIJ_FIZETES';
    export const AvailableCommandsEnum = {
        REGISZTRACIOVEGLEGESITES: 'REGISZTRACIO_VEGLEGESITES' as AvailableCommandsEnum,
        RESZLETEKMEGNYITAS: 'RESZLETEK_MEGNYITAS' as AvailableCommandsEnum,
        ELONEZETMEGNYITAS: 'ELONEZET_MEGNYITAS' as AvailableCommandsEnum,
        TERMEKEKMEGNYITAS: 'TERMEKEK_MEGNYITAS' as AvailableCommandsEnum,
        REGISZTRACIOMEGNYITAS: 'REGISZTRACIO_MEGNYITAS' as AvailableCommandsEnum,
        ELBIRALAS: 'ELBIRALAS' as AvailableCommandsEnum,
        MODOSITAS: 'MODOSITAS' as AvailableCommandsEnum,
        INAKTIVALAS: 'INAKTIVALAS' as AvailableCommandsEnum,
        AKTIVALAS: 'AKTIVALAS' as AvailableCommandsEnum,
        FELUGYELOIINAKTIVALAS: 'FELUGYELOI_INAKTIVALAS' as AvailableCommandsEnum,
        FELUGYELOIAKTIVALAS: 'FELUGYELOI_AKTIVALAS' as AvailableCommandsEnum,
        FIZIKAITORLES: 'FIZIKAI_TORLES' as AvailableCommandsEnum,
        AKTIVACIOSFIZETES: 'AKTIVACIOS_FIZETES' as AvailableCommandsEnum,
        NAPIDIJFIZETES: 'NAPIDIJ_FIZETES' as AvailableCommandsEnum
    };
    export type StatuszEnum = 'ROGZITESALATT' | 'JOVAHAGYASRAVAR' | 'AKTIVACIOS_FIZETESRE_VAR' | 'AKTIVACIOS_FIZETES_FOLYAMATBAN' | 'AKTIV' | 'INAKTIV' | 'FELUGYELOI_INAKTIV' | 'TOROLT';
    export const StatuszEnum = {
        ROGZITESALATT: 'ROGZITESALATT' as StatuszEnum,
        JOVAHAGYASRAVAR: 'JOVAHAGYASRAVAR' as StatuszEnum,
        AKTIVACIOSFIZETESREVAR: 'AKTIVACIOS_FIZETESRE_VAR' as StatuszEnum,
        AKTIVACIOSFIZETESFOLYAMATBAN: 'AKTIVACIOS_FIZETES_FOLYAMATBAN' as StatuszEnum,
        AKTIV: 'AKTIV' as StatuszEnum,
        INAKTIV: 'INAKTIV' as StatuszEnum,
        FELUGYELOIINAKTIV: 'FELUGYELOI_INAKTIV' as StatuszEnum,
        TOROLT: 'TOROLT' as StatuszEnum
    };
}