import { Pipe, PipeTransform } from '@angular/core';
import { HorgasztatasSzolgaltatasRegisztracio } from 'api';

@Pipe({
  name: 'vizteruletTipus'
})
export class VizteruletTipusPipe implements PipeTransform {

  transform(value: any, ...args: Array<any>): any {
    switch (value) {
      case HorgasztatasSzolgaltatasRegisztracio.VizteruletTipusEnum.CSATORNA: return 'Csatorna';
      case HorgasztatasSzolgaltatasRegisztracio.VizteruletTipusEnum.FOLYO: return 'Folyó';
      case HorgasztatasSzolgaltatasRegisztracio.VizteruletTipusEnum.MENTETLENOLDALIHOLTAG: return 'Mentetlen oldali (ártéri) holtág';
      case HorgasztatasSzolgaltatasRegisztracio.VizteruletTipusEnum.MENTETTOLDALIHOLTAG: return 'Mentett oldali holtág';
      case HorgasztatasSzolgaltatasRegisztracio.VizteruletTipusEnum.PATAK: return 'Patak, kisvízfolyás';
      case HorgasztatasSzolgaltatasRegisztracio.VizteruletTipusEnum.TO: return 'Tó';
      case HorgasztatasSzolgaltatasRegisztracio.VizteruletTipusEnum.VIZTAROZO: return 'Víztározó';
      case HorgasztatasSzolgaltatasRegisztracio.VizteruletTipusEnum.FOCSATORNA: return 'Főcsatorna';
      case HorgasztatasSzolgaltatasRegisztracio.VizteruletTipusEnum.BANYATO: return 'Bányató';
      default: return value;
    }
  }

}
