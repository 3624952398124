<mohosz-simple-header [title]="'Horgásztatás'" [backButton]="true"></mohosz-simple-header>

<ion-content class="moh-bg-light">
  <div *ngIf="isSmallDisplay" class="jegyvasarlas-container-mobile">
    <ion-grid>
      <ion-row>
        <ion-col size-md="6" offset-md="3">
          <ng-container [ngTemplateOutlet]="jegyvasarlasButton"></ng-container>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <ng-container *ngIf="!isLoading && horgasztatasBundle; else noContentTpl">
    <ion-grid>
      <ion-row>
        <ion-col size-md="6" offset-md="3">
            <ion-card>
              <!-- focim -->
              <ion-card-header>
                <ion-card-title class="focim">
                  {{ horgasztatasBundle?.regisztracio.nev }}
                </ion-card-title>

                <ion-grid class="fejadat">
                  <ion-row class="ion-justify-content-evenly">
                    <ion-col>
                      <ion-text>
                        <div class="ion-text-nowrap ion-padding-end">
                          <ion-icon name="location-sharp" color="primary"></ion-icon>
                          <!-- <a (click)="onMutasdClick()"><span>Mutasd a térképen</span></a>-->
                          {{ horgasztatasBundle?.regisztracio.megyeKod | megyeKodResolveName:megyeLista }}
                        </div>
                        <div class="ion-padding-end">
                          <span class="item">Szolgáltató: </span>
                          <span>{{szolgaltatoNevjegy?.nev}}</span>
                        </div>
                        <div class="ion-text-nowrap" *ngIf="displayedHonlapCim">
                          <span class="item">Weboldal címe: </span>
                          <span class="value ion-text-wrap"><a href="javascript:void(0)" (click)="onHonlapCimClick()">{{displayedHonlapCim}}</a></span>
                        </div>
                        <div class="ion-text-nowrap" *ngIf="hasHorgaszrend">
                          <a href="javascript:void(0)" (click)="openHorgaszrend()">Horgászrend </a>
                        </div>
                      </ion-text>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-header>

              <!-- kep -->
              <ion-thumbnail>
                <ng-container *ngIf="horgasztatasBundle?.regisztracio?.bigThumbnailKepId; else noImg">
                  <mohosz-base64-content type="image" [contentId]="horgasztatasBundle?.regisztracio?.bigThumbnailKepId"></mohosz-base64-content>
                </ng-container>
                <ng-template #noImg>
                  <div class="no-image"></div>
                </ng-template>
              </ion-thumbnail>
            </ion-card>

            <!-- vizterulet adatok -->
            <mohosz-ngtpl-card [contentTpl]="vizteruletTpl"></mohosz-ngtpl-card>

            <ng-template #vizteruletTpl>
              <ion-grid class="vizterulet-adatok">
                <ion-row class="ion-nowrap ion-justify-content-between">
                  <ion-col class="ion-nowrap">
                    Vízterület típusa<br>
                    <ion-label>{{(horgasztatasBundle?.regisztracio?.vizteruletTipus | vizteruletTipus) || '-'}}</ion-label>
                  </ion-col>
                  <ion-col class="ion-nowrap">
                    Átlagos vízmélység (m)<br>
                    <ion-label>{{horgasztatasBundle?.regisztracio?.atlagosVizmelyseg || '-'}}</ion-label>
                  </ion-col>
                  <ion-col class="ion-nowrap">
                    Terület (ha)<br>
                    <ion-label>{{horgasztatasBundle?.regisztracio?.terulet || '-'}}</ion-label>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ng-template>

            <mohosz-text-card
              [content]="horgasztatasBundle?.regisztracio?.altalanosLeiras"
              [maxTextHeight]="55">
            </mohosz-text-card>

            <mohosz-felszereltseg-card
              [felszereltsegList]="horgasztatasBundle?.regisztracio?.felszereltsegList">
            </mohosz-felszereltseg-card>

            <mohosz-horgaszmodszer-card
              [horgaszmodszerAltipusList]="horgasztatasBundle?.regisztracio?.horgaszmodszerAltipusList">
            </mohosz-horgaszmodszer-card>

            <mohosz-halallomany-card
              [halmatrixList]="halmatrixList"
              [halallomanyList]="horgasztatasBundle?.regisztracio?.halallomanyList">
            </mohosz-halallomany-card>

            <mohosz-fogasirekord-card
              [halmatrixList]="halmatrixList"
              [fogasiRekordList]="horgasztatasBundle?.regisztracio?.fogasiRekordList">
            </mohosz-fogasirekord-card>

            <mohosz-text-card
              title="Megközelíthetőség"
              [content]="horgasztatasBundle?.regisztracio?.megkozelithetoseg"
              [maxTextHeight]="55">
            </mohosz-text-card>

            <mohosz-ngtpl-card
              title="Vízterület fenntartó elérhetőségei"
              [contentTpl]="vizteruletFenntartoTpl">
            </mohosz-ngtpl-card>

            <ng-template #vizteruletFenntartoTpl>
              <ion-list class="elerhetosegek">
                <ion-item>
                  <ion-label>Telefonszám</ion-label>
                  <a href="tel:{{horgasztatasBundle?.regisztracio?.halgazdasagiHasznositoTelefonszam}}">
                    {{horgasztatasBundle?.regisztracio?.halgazdasagiHasznositoTelefonszam}}
                  </a>
                </ion-item>
                <ion-item>
                  <ion-label>Email cím</ion-label>
                  <a href="mailto:{{horgasztatasBundle?.regisztracio?.halgazdasagiHasznositoEmail}}">
                    {{horgasztatasBundle?.regisztracio?.halgazdasagiHasznositoEmail}}
                  </a>
                </ion-item>
                <ion-item lines="none">
                  <ion-label>Ellenőr telefonszám</ion-label>
                  <a href="tel:{{horgasztatasBundle?.regisztracio?.halgazdasagiHasznositoTelefonszam}}">
                    {{horgasztatasBundle?.regisztracio?.halgazdasagiHasznositoTelefonszam}}
                  </a>
                </ion-item>
              </ion-list>
            </ng-template>
        </ion-col>

        <ion-col *ngIf="!isSmallDisplay && horgasztatasBundle">
          <div class="sticky-box">
            <ion-card class="felfedezes-card">
              <ion-card-header>
                <ion-card-title>
                  <ion-card-title>Jegyvásárlás</ion-card-title>
                </ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <ng-container [ngTemplateOutlet]="jegyvasarlasButton"></ng-container>
              </ion-card-content>
            </ion-card>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ng-container>

  <ng-template #noContentTpl>
    <mohosz-no-content></mohosz-no-content>
  </ng-template>

  <ng-template #jegyvasarlasButton>
    <div class="ion-text-center">
      <ion-button (click)="onJegyvasarlasClick(horgasztatasBundle.szolgaltatasId)">
        Jegyvásárlás
      </ion-button>
    </div>
  </ng-template>
</ion-content>
