import { Component, NgZone, OnInit, ViewContainerRef } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Utils } from 'app/core/utils';
import { LifeCycleService } from 'app/services/lifecycle.service';
import { TerkepConstants } from 'app/terkep/shared/terkep-constants';
import * as Leaflet from 'leaflet';
import { LocationService } from '../../../services/location.service';
import { TerkepHelper } from '../../shared/terkep-helper';
import { RegPontInfoPanelComponent } from './info-panel/reg-pont-info-panel.component';
import { RegPont } from './reg-pont';
import { RegPontService } from './reg-pont.service';

interface MarkerData {
  egyediKod: string;
  marker: Leaflet.Marker;
}

@Component({
  selector: 'mohosz-regpont-terkep',
  templateUrl: './regpont-terkep.component.html',
  styleUrls: ['./regpont-terkep.component.scss']
})
export class RegpontTerkepComponent extends OnDestroyMixin implements OnInit {

  mapOptions: Leaflet.MapOptions = TerkepConstants.getDefaultOptions();
  map: Leaflet.Map;
  markers: Array<MarkerData> = [];
  constructor(
    private locationService: LocationService,
    private regPontService: RegPontService,
    private zone: NgZone,
    private viewContainerRef: ViewContainerRef,
    private lifeCycleService: LifeCycleService
  ) {
    super();
  }

  ngOnInit(): void {
    this.lifeCycleService.didEnter.pipe(untilComponentDestroyed(this)).subscribe(() => {
      if (this.map) {
        TerkepHelper.invalidateSize(this.map);
      }
    });
  }

  onRegpontHeaderSelection(regPont: RegPont) {
    this.map.setView(new Leaflet.LatLng(regPont.lat, regPont.lng), 16);
    const marker = this.markers.find(pont => pont.egyediKod === regPont.azonosito).marker;
    if(Utils.hasValue(marker)){
      const popup = this.createPopup(regPont, marker);
      marker.bindPopup(popup).openPopup();
    }
  }

  onMapReady(map: Leaflet.Map) {
    this.map = map;
    this.initRegPonts().then(() => {
      const mapMarkerGroup = Leaflet.markerClusterGroup();
      this.map.addLayer(mapMarkerGroup);
      this.markers.forEach(markerData => markerData.marker.addTo(mapMarkerGroup));

      this.locationService.getLocation().then(pos => {
        if(pos){
          this.map.panTo(new Leaflet.LatLng(pos.szelessegiFok, pos.hosszusagiFok));
        } else {
          this.map.panTo(TerkepConstants.DEFAULT_MAP_CENTER);
        }
        TerkepHelper.invalidateSize(this.map);
      });
    });
  }

  private async initRegPonts() {
    try {
      const regPontok = await this.regPontService.getRegpontObservable().toPromise();
      regPontok.forEach(
        regPont => {
          if (regPont.lat && regPont.lng) {
            const latLng = new Leaflet.LatLng(regPont.lat, regPont.lng);
            const marker = new Leaflet.Marker(latLng, {
              icon: new Leaflet.Icon({
                iconUrl: TerkepConstants.REG_PONT_ICON,
                iconAnchor: [16, 44], // point of the icon which will correspond to marker's location
                popupAnchor: [0, -44] // point from which the popup should open relative to the iconAnchor
              }),
              title: regPont.nev
            }).on('click', event => {
              this.zone.run(() => {
                const popup = this.createPopup(regPont, marker);
                marker.bindPopup(popup).openPopup();
              });
            });

            this.markers.push({ marker, egyediKod: regPont.azonosito });
          } else {
            //console.log(["Koordináta nélküli regisztrációs pont!", regPont])
          }
        }
      );
    } catch (err) {
      console.error(err);
    }
  }

  private createPopup(regPont: RegPont, marker: Leaflet.Marker) {
    //Letrehozzuk a RegPontInfoPanelCompnentet
    const component = this.viewContainerRef.createComponent(RegPontInfoPanelComponent);
    //Inputkent atadjuk a regPont-ot
    component.instance.regPont = regPont;
    component.changeDetectorRef.detectChanges();
    const popupContent = component.location.nativeElement;
    //Letrehozzuk a popupot, belerakjuk a tartalmat
    const popup = Leaflet.popup({ autoPan: true, className: 'marker-popup' }).setContent(popupContent);
    //Popup bezarasakor takaritunk
    popup.on('remove', () => {
      component.destroy();
      marker.unbindPopup();
    });
    return popup;
  }
}
