import { Injectable } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Flog, MobilAppControllerService, MobilAppFelhasznalo } from 'api';
import { AuthService } from 'app/auth/auth.service';
import { TimerMutex } from 'app/core/timer-mutex';
import { Utils } from 'app/core/utils';
import { ToastService } from 'app/services/toast.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ConnectionStateService } from '../services/connection-state.service';

@Injectable({
  providedIn: 'root'
})
export class FogasaimService extends OnDestroyMixin {

  felhasznalo: MobilAppFelhasznalo;
  fogasaimList: Observable<Array<Flog>>;
  kivalasztottFogas: Observable<Flog>;

  bundleLoading: Observable<boolean>;
  private bundleLoadingSubject: BehaviorSubject<boolean>;


  private fogasaimListSubject: BehaviorSubject<Array<Flog>>;
  private kivalasztottFogasSubject: BehaviorSubject<Flog>;
  private timerMutex = new TimerMutex(1);

  constructor(
    private connectionStateService: ConnectionStateService,
    private authService: AuthService,
    private mobilAppControllerService: MobilAppControllerService,
    private toastService: ToastService,
  ) {
    super();

    this.bundleLoadingSubject = new BehaviorSubject<boolean>(true);
    this.bundleLoading = this.bundleLoadingSubject.asObservable();

    this.authService.authentication.pipe(untilComponentDestroyed(this)).subscribe(auth => {
      this.felhasznalo = auth.felhasznalo;
      this.refreshContent();
    });

    this.connectionStateService.onlineState.pipe(untilComponentDestroyed(this)).subscribe(state => {
        this.refreshContent();
    });

    this.fogasaimListSubject = new BehaviorSubject<Array<Flog>>(undefined);
    this.fogasaimList = this.fogasaimListSubject.asObservable().pipe(filter(result => Utils.hasValue(result)));

    this.kivalasztottFogasSubject = new BehaviorSubject<Flog>(undefined);
    this.kivalasztottFogas = this.kivalasztottFogasSubject.asObservable();
  }

  refreshContent() {
    if(this.connectionStateService.isOnline()){
      if (this.felhasznalo) {
        this.timerMutex.runExclusive(() => {
          this.bundleLoadingSubject.next(true);
          this.mobilAppControllerService.fogasListazas().toPromise()
            .then(result => {
              this.fogasaimListSubject.next(result);
              if (this.kivalasztottFogasSubject.getValue()) {
                this.setKivalasztottFogas(this.fogasaimListSubject.getValue().find(item => item.id === this.kivalasztottFogasSubject.getValue().id));
              }
            })
            .catch((error) => this.toastService.httpError(error))
            .finally(() => this.bundleLoadingSubject.next(false));
        });
      }
    } else {
      this.bundleLoadingSubject.next(false);
    }
  }

  setKivalasztottFogas(fogas: Flog) {
    this.kivalasztottFogasSubject.next(fogas);
  }

  resetKivalasztottFogas() {
    this.kivalasztottFogasSubject.next(undefined);
  }

  getKivalasztottFogas(): Flog {
    return this.kivalasztottFogasSubject.getValue();
  }
}
