import {DatePipe, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localeHu from '@angular/common/locales/hu';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouteReuseStrategy} from '@angular/router';
import {Deeplinks} from '@ionic-native/deeplinks/ngx';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {IonicStorageModule} from '@ionic/storage-angular';
import {ApiModule} from 'api';
import {AltalanosModule} from 'app/altalanos/altalanos.module';
import {AuthInterceptor} from 'app/auth/auth.interceptor';
import {CoreModule} from 'app/core/core.module';
import {MaterialModule} from 'app/material/material.module';
import {DisplayObserverService} from 'app/shared/display-observer.service';
import {SharedModule} from 'app/shared/shared.module';
import {ApiModuleConfiguration} from 'config/ApiModuleConfiguration';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

registerLocaleData(localeHu);

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    AltalanosModule,
    SharedModule,
    ApiModule.forRoot(ApiModuleConfiguration.default),
    MaterialModule.forRoot(),
    BrowserAnimationsModule,
    CoreModule,
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: LOCALE_ID, useValue: 'hu-HU'},
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'HUF'},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: MAT_DATE_LOCALE, useValue: 'hu-HU'},
    // {provide: DateAdapter, useClass: CustomDateAdapter},
    DisplayObserverService,
    DatePipe,
    Deeplinks
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
