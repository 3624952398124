import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { routesConfig } from 'config/routesConfig';
import { FogasiNaploGuard } from 'app/fogasi-naplo/fogasi-naplo.guard';
import { FogasiNaploPage } from 'app/fogasi-naplo/fogasi-naplo.page';
import { FogasokPageModule } from './fogasok/fogasok.module';
import { HorgaszNapokPageModule } from './horgasz-napok/horgasz-napok.module';

const routes: Routes = [
  {
    path: '',
    component: FogasiNaploPage,
    children: [
      {
        path: routesConfig.fogasok, loadChildren: () => FogasokPageModule, canActivate: [FogasiNaploGuard]
      },
      {
        path: routesConfig.horgaszNapok, loadChildren: () => HorgaszNapokPageModule, canActivate: [FogasiNaploGuard]
      },
      {
        path: '', redirectTo: `${routesConfig.fogasok}`, pathMatch: 'prefix'
      }
    ]
  },
  /*
  {
    path: 'fogasok',
    loadChildren: () => import('./fogasok/fogasok.module').then( m => m.FogasokPageModule)
  },
  {
    path: 'horgasz-napok',
    loadChildren: () => import('./horgasz-napok/horgasz-napok.module').then( m => m.HorgaszNapokPageModule)
  },
   */

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FogasiNaploPageRoutingModule {}
