import { FileItem } from 'ng2-file-upload';

export type Kepformatum = 'GALERIA';

export const KEPFORMATUM = {
  GALERIA: 'GALERIA' as Kepformatum,
  PROFILKEP: 'PROFILKEP' as Kepformatum
};
export interface KepformatumProperties {
  resizeToWidth: number;
  aspectRatio: number;
  minWidth: number;
}

export const KEPFORMATUM_PROPERTIES_MAP: Map<Kepformatum, KepformatumProperties> =  new Map([
  [KEPFORMATUM.GALERIA, {resizeToWidth: 500, aspectRatio: 4/3, minWidth: 400}],
  [KEPFORMATUM.PROFILKEP, {resizeToWidth: 100, aspectRatio: 1, minWidth: 100}]
]);

export class KepSzerkesztoDialogData {
  formatum: Kepformatum = KEPFORMATUM.GALERIA;
  file: FileItem;

  constructor(file: FileItem, formatum?: Kepformatum) {
    this.file = file;
    if (formatum) {
      this.formatum = formatum;
    }
  }
}

export interface KepSzerkesztoConfiguration {
  formatum?: Kepformatum;
}
