import { Injectable } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { GPSKoordinata, KedvencHorgaszhely, KedvencHorgaszhelyFilter, MobilAppControllerService, MobilAppFelhasznalo } from 'api';
import { AuthService } from 'app/auth/auth.service';
import { TimerMutex } from 'app/core/timer-mutex';
import { Utils } from 'app/core/utils';
import { ToastService } from 'app/services/toast.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ConnectionStateService } from '../services/connection-state.service';

@Injectable({
  providedIn: 'root'
})
export class KedvencHorgaszhelyService extends OnDestroyMixin {

  felhasznalo: MobilAppFelhasznalo;
  locationPos: GPSKoordinata;
  kedvencHorgaszhelyList: Observable<Array<KedvencHorgaszhely>>;
  kivalasztottHely: Observable<KedvencHorgaszhely>;
  requiredMoveToKivalsztottHely: boolean;

  bundleLoading: Observable<boolean>;
  private bundleLoadingSubject: BehaviorSubject<boolean>;


  private kedvencHorgaszhelyListSubject: BehaviorSubject<Array<KedvencHorgaszhely>>;
  private kivalasztottHelySubject: BehaviorSubject<KedvencHorgaszhely>;
  private timerMutex = new TimerMutex(1);

  private isLocationUpdated = false;

  constructor(
    private connectionStateService: ConnectionStateService,
    private authService: AuthService,
    private mobilAppControllerService: MobilAppControllerService,
    private toastService: ToastService,
  ) {
    super();

    this.bundleLoadingSubject = new BehaviorSubject<boolean>(true);
    this.bundleLoading = this.bundleLoadingSubject.asObservable();

    this.authService.authentication.pipe(untilComponentDestroyed(this)).subscribe(auth => {
      this.felhasznalo = auth.felhasznalo;
      this.refreshContent();
    });

    this.connectionStateService.onlineState.pipe(untilComponentDestroyed(this)).subscribe(state => {
        this.refreshContent();
    });

    this.kedvencHorgaszhelyListSubject = new BehaviorSubject<Array<KedvencHorgaszhely>>(undefined);
    this.kedvencHorgaszhelyList = this.kedvencHorgaszhelyListSubject.asObservable().pipe(filter(result => Utils.hasValue(result)));

    this.kivalasztottHelySubject = new BehaviorSubject<KedvencHorgaszhely>(undefined);
    this.kivalasztottHely = this.kivalasztottHelySubject.asObservable();
  }

  // lokáció alapján sorrendezett a lista. Ezért itt tároljuk a listához tartozó lokációt
  refreshContentByLocation(locationPos?: GPSKoordinata){
    this.isLocationUpdated = true;
    if (locationPos) {
      this.locationPos = locationPos;
    }
    this.refreshContent();
  }

  refreshContent() {
    if(this.connectionStateService.isOnline()){
      if (this.isLocationUpdated && this.felhasznalo) {
        this.timerMutex.runExclusive(() => {
          this.bundleLoadingSubject.next(true);
          const listFilter: KedvencHorgaszhelyFilter = {
            szemelyId: this.felhasznalo.szemelyId,
            aktualisKoordinata: this.locationPos
          };
          this.mobilAppControllerService.kedvencHorgaszhelyListazas(listFilter).toPromise()
            .then(result => {
              this.kedvencHorgaszhelyListSubject.next(result);
              if (this.kivalasztottHelySubject.getValue()) {
                this.setKivalasztottHely(this.kedvencHorgaszhelyListSubject.getValue().find(item => item.id === this.kivalasztottHelySubject.getValue().id));
              }
            })
            .catch((error) => this.toastService.httpError(error))
            .finally(() => this.bundleLoadingSubject.next(false));
        });
      }
    } else {
      this.bundleLoadingSubject.next(false);
    }
  }

  setKivalasztottHely(hely: KedvencHorgaszhely) {
    this.kivalasztottHelySubject.next(hely);
  }

  resetKivalasztottHely() {
    this.kivalasztottHelySubject.next(undefined);
  }

  getKivalasztottHely(): KedvencHorgaszhely {
    return this.kivalasztottHelySubject.getValue();
  }

  requireMoveToKivasztotthely() {
    this.requiredMoveToKivalsztottHely = true;
  }

  moveToKivalasztottHelyDone() {
    this.requiredMoveToKivalsztottHely = false;
  }
}
