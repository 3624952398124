<div class="modal-content" *ngIf="data">
  <ion-card class="modal-rogzites">
    <ion-card-header>
      <ion-item lines="none">
        <ion-label>
          <ng-container *ngIf="isTorles">Rögzített nap</ng-container>
          <ng-container *ngIf="!isTorles">Nap rögzítése</ng-container>
        </ion-label>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()">
            <ion-icon slot="icon-only" name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-item>
    </ion-card-header>

    <ion-card-content>
      <!-- input -->
      <mat-form-field class="mandatory-field ion-margin-bottom">
        <mat-label>Dátum</mat-label>
        <input type="text" matInput name="datum" [matDatepicker]="pickerDatum"
               [(ngModel)]="selectedNap" [value]="(selectedNap ? (selectedNap | date: 'longDate') : '')"
               [disabled]="true">
        <mat-datepicker-toggle matSuffix [for]="pickerDatum">
          <mat-icon matDatepickerToggleIcon svgIcon="moh-icon-calendar" class="disabled"></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #pickerDatum></mat-datepicker>
      </mat-form-field>

      <div class="ion-text-center">
        <!-- torles button -->
        <ng-container *ngIf="isTorles">
          <ion-button fill="outline" color="danger" (click)="onNapTorlesClick()">
            <ion-icon slot="start" name="trash-outline"></ion-icon>
            Horgászattal töltött nap törlése
          </ion-button>
        </ng-container>

        <!-- rogzites button -->
        <ng-container *ngIf="!isTorles">
          <ion-button (click)="onNapRogzitesClick()">
            Horgászattal töltött nap mentése
          </ion-button>
        </ng-container>
      </div>
    </ion-card-content>
  </ion-card>
</div>
