import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from 'app/core/utils';
import * as moment from 'moment/moment';

@Pipe({
  name: 'uzenetDateTime'
})
export class UzenetDateTimePipe implements PipeTransform {

  transform(date: Date): string {
    let dateTimeString = '';
    const ma = new Date();
    const tegnap = new Date();
    tegnap.setDate(ma.getDate() -1);
    if (Utils.hasValue(date)) {
      const m: moment.Moment = moment(date);
      if (Utils.equalsDate(date, tegnap)) {
        dateTimeString = 'Tegnap, ' + m.format(' HH:mm');
      } else if (Utils.equalsDate(date, ma)) {
        dateTimeString = 'Ma, ' + m.format(' HH:mm');
      } else {
        dateTimeString = m.format('YYYY.MM.DD. HH:mm');
      }
    }
    return dateTimeString;
  }

}
