import { Megye } from 'api';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'megyeKodResolveName'
})
export class MegyeKodResolveNamePipe implements PipeTransform {
    transform(value: string, megyek?: Array<Megye>): any {
        if (megyek && megyek.length > 0) {
            for (const megye of megyek) {
                if (megye.kod === value) {
                    return megye.nev;
                }
            }
        }
        return value;
    }
}
