import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,

    OnInit,

    Output,
    SimpleChanges
} from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { GPSKoordinata, HorgaszturizmusMapPoi, KedvencHorgaszhely } from 'api';
import { LifeCycleService } from 'app/services/lifecycle.service';
import { LocationService } from 'app/services/location.service';
import { PoiMarkerData } from 'app/terkep/shared/poi-marker-data';
import { TerkepConstants } from 'app/terkep/shared/terkep-constants';
import { TerkepHelper } from 'app/terkep/shared/terkep-helper';
import * as Leaflet from 'leaflet';
import { Observable, ReplaySubject } from 'rxjs';


@Component({
  selector: 'mohosz-kedvenc-horgaszhely-terkep-editor',
  templateUrl: './kedvenc-horgaszhely-terkep-editor.component.html',
  styleUrls: ['./kedvenc-horgaszhely-terkep-editor.component.scss'],
})
export class KedvencHorgaszhelyTerkepEditorComponent extends OnDestroyMixin implements OnInit, OnChanges {

  @Input() kedvencHorgaszhelyKoordinata: GPSKoordinata;
  @Input() kozelbenPoiList: Array<HorgaszturizmusMapPoi>;

  @Output() mapReady: EventEmitter<boolean> = new EventEmitter();
  @Output() changeKoordinata = new EventEmitter<GPSKoordinata>();
  @Output() vizteruletKapcsolas: EventEmitter<HorgaszturizmusMapPoi> = new EventEmitter();

  locationPos: GPSKoordinata;
  locationMarker: Leaflet.Marker;
  mapOptions: Leaflet.MapOptions = TerkepConstants.getDefaultOptions();
  map: Leaflet.Map;
  kozelbenCircleLayer: Leaflet.Layer;
  layerList: Array<Leaflet.Layer> = [];
  kedvencHorgaszhely: KedvencHorgaszhely;
  kedvencHorgaszhelyMarker: Leaflet.Marker;
  kozelbenMarkers: Array<PoiMarkerData> = [];
  selectedKozelbenMarker: PoiMarkerData;

  private simpleChangesSubject = new ReplaySubject<SimpleChanges>();
  private simpleChangesObservable: Observable<SimpleChanges>;
  private isCentered = false;

  constructor(
    private locationService: LocationService,
    private changeDetectorRef: ChangeDetectorRef,
    private zone: NgZone,
    private lifeCycleService: LifeCycleService
  ) {
    super();
    this.simpleChangesObservable = this.simpleChangesSubject.asObservable();
  }

  ngOnInit(): void {
    this.locationService.getLocation().then(pos => {
      this.locationPos = pos;
    });
    this.lifeCycleService.didEnter.pipe(untilComponentDestroyed(this)).subscribe(() => {
      if (this.map) {
        TerkepHelper.invalidateSize(this.map);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.simpleChangesSubject.next(changes);
  }

  async onMapReady(map: Leaflet.Map) {
    this.map = map;
    this.refreshLocationLayer();
    TerkepHelper.invalidateSize(this.map);

    //start processing changes
    this.simpleChangesObservable.pipe(untilComponentDestroyed(this)).subscribe(changes => {

      if (changes.kedvencHorgaszhelyKoordinata) {
        this.updateKedvencHorgaszhely();
      }
      if (changes.kozelbenPoiList) {
        this.refreshKozelbenPOIs();
      }
    });
  }

  onMapClick(e: Leaflet.LeafletMouseEvent) {
    if (!this.selectedKozelbenMarker) {
      const latLng = e.latlng;
      this.kedvencHorgaszhelyKoordinata = { szelessegiFok: latLng.lat, hosszusagiFok: latLng.lng };
      this.changeKoordinata.emit(this.kedvencHorgaszhelyKoordinata);
    }
  }

  onClosePoiPopupClick() {
    this.clearMarkerSelection();
  }

  onVizteruletKapcsolas(item: HorgaszturizmusMapPoi) {
    this.vizteruletKapcsolas.emit(item);
    this.clearMarkerSelection();
  }

  private async refreshMapPosition() {
    if (!this.isCentered && TerkepHelper.isValidGPSKoorinata(this.kedvencHorgaszhelyKoordinata)) {
      this.map.fitBounds(TerkepHelper.createBounds([this.kedvencHorgaszhelyKoordinata]),{maxZoom: TerkepConstants.DEFAULT_MAP_ZOOM});
      this.isCentered = true;
    }
  }

  private updateKedvencHorgaszhely() {
    this.removeKozelbenLayerFromMap();
    if (TerkepHelper.isValidGPSKoorinata(this.kedvencHorgaszhelyKoordinata)) {
      this.kedvencHorgaszhely = TerkepHelper.createNewKedvencHorgaszhely(this.kedvencHorgaszhelyKoordinata.szelessegiFok,this.kedvencHorgaszhelyKoordinata.hosszusagiFok);
      this.addKozelbenLayerToMap(TerkepHelper.koordinataToLatLng(this.kedvencHorgaszhelyKoordinata));
    } else {
      this.kedvencHorgaszhely = null;
    }
    this.refreshKedvencHorgaszhelyPOI();
    this.changeDetectorRef.detectChanges();
  }

  private async refreshKedvencHorgaszhelyPOI() {
    this.kedvencHorgaszhelyMarker = null;

    if (this.kedvencHorgaszhely?.koordinata) {
      const latLng = new Leaflet.LatLng(this.kedvencHorgaszhely.koordinata.szelessegiFok, this.kedvencHorgaszhely.koordinata.hosszusagiFok);
      this.kedvencHorgaszhelyMarker = new Leaflet.Marker(latLng, {
        icon: new Leaflet.Icon({
          iconUrl: TerkepConstants.KEDVENC_HORGASZHELY_POI,
          iconSize: [38, 53],
          iconAnchor: [19, 53]
        })
      }).on('click', () => {
          this.zone.run(() => {
            this.map.fitBounds(TerkepHelper.createBounds([this.kedvencHorgaszhely.koordinata]),{maxZoom: TerkepConstants.DEFAULT_MAP_ZOOM});
          });
        });
    }
    this.updateLayerList();
    this.refreshMapPosition();
    this.changeDetectorRef.detectChanges();
  }

  private async refreshKozelbenPOIs() {
    this.kozelbenMarkers = [];
    const poiList = this.kozelbenPoiList ?? [];
    if (poiList?.length > 0) {
      poiList.forEach(
        poi => {
          if (poi.koordinata) {
            const latLng = new Leaflet.LatLng(poi.koordinata.szelessegiFok, poi.koordinata.hosszusagiFok);
            const marker = new Leaflet.Marker(latLng, {
              icon: this.createIcon(poi, false),
            }).on('click', () => {
              this.zone.run(() => {
                if (poi.type) {
                  this.clearMarkerSelection();
                  this.selectMarker(marker);
                }
              });
            });
            this.kozelbenMarkers.push({marker, mapPoi: poi});
          } else {
            console.log(['Koordináta nélküli poi!', poi]);
          }
        }
      );
    }
    this.updateLayerList();
    this.changeDetectorRef.detectChanges();
  }

  private addKozelbenLayerToMap(centerLatLng: Leaflet.LatLng) {
    this.kozelbenCircleLayer?.removeFrom(this.map);
    this.kozelbenCircleLayer = Leaflet.circle(centerLatLng, TerkepConstants.DEFAULT_LAYER_RADIUS);
    this.kozelbenCircleLayer.addTo(this.map);
  }

  private removeKozelbenLayerFromMap() {
    if (this.kozelbenCircleLayer) {
      this.map.removeLayer(this.kozelbenCircleLayer);
    }
  }

  private refreshLocationLayer() {
    // lokáció marker
    this.locationMarker?.removeFrom(this.map);
    this.locationMarker = TerkepHelper.createLocationMarker(this.locationPos);
    this.locationMarker.addTo(this.map);
    this.map.setView(TerkepHelper.koordinataToLatLng(this.locationPos), TerkepConstants.ZOOM_LEVEL_KOZELBEN);
  }

  private updateLayerList() {
    this.layerList = [...this.kozelbenMarkers.map(m => m.marker)];
    if (this.kedvencHorgaszhelyMarker) {
      this.layerList.push(this.kedvencHorgaszhelyMarker);
    }
  }

  private createIcon(poi: HorgaszturizmusMapPoi, selected: boolean): Leaflet.Icon {
    return new Leaflet.Icon({
      iconUrl: poi.type ? TerkepConstants.getPoiIconUrl(poi.type) : TerkepConstants.KEDVENC_HORGASZHELY_POI,
      iconSize: selected ? [57, 80] : [38, 53],
      iconAnchor: selected ? [28, 80] : [19, 53]
    });
  }

  private async selectMarker(marker: Leaflet.Marker) {
    if (this.selectedKozelbenMarker) {
      this.selectedKozelbenMarker.marker.setIcon(this.createIcon(this.selectedKozelbenMarker.mapPoi, false));
    }
    this.selectedKozelbenMarker = this.kozelbenMarkers.find(item => item.marker === marker);
    marker.setIcon(this.createIcon(this.selectedKozelbenMarker.mapPoi, true));
  }

  private clearMarkerSelection() {
    if (this.selectedKozelbenMarker) {
      this.selectedKozelbenMarker.marker.setIcon(this.createIcon(this.selectedKozelbenMarker.mapPoi, false));
      this.selectedKozelbenMarker = undefined;
      this.changeDetectorRef.detectChanges();
    }
  }
}
