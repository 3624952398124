import { Pipe, PipeTransform } from '@angular/core';
import { HorgasztatasSzolgaltatasRegisztracio } from 'api';

@Pipe({
  name: 'felszereltseg'
})
export class FelszereltsegPipe implements PipeTransform {

  transform(value: any, arg?: 'icon-url'): any {
    switch (value) {
      case HorgasztatasSzolgaltatasRegisztracio.FelszereltsegListEnum.BERELHETOHORGASZHELY: {
        if (arg === 'icon-url') {
          return 'assets/icon/horgaszhely.svg';
        } else {
          return 'Bérelhető horgászhely';
        }
      }
      case HorgasztatasSzolgaltatasRegisztracio.FelszereltsegListEnum.HORGASZTANYA: {
        if (arg === 'icon-url') {
          return 'assets/icon/horgasztanya.svg';
        } else {
          return 'Horgásztanya';
        }
      }
      case HorgasztatasSzolgaltatasRegisztracio.FelszereltsegListEnum.SOLYAPALYA: {
        if (arg === 'icon-url') {
          return 'assets/icon/solyapalya.svg';
        } else {
          return 'Sólyapálya';
        }
      }
      default: return value;
    }
  }

}
