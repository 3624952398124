import { HorgaszturizmusPoiItem } from 'api';
import * as Leaflet from 'leaflet';
import { cloneDeep } from 'lodash';

export class TerkepConstants {
  public static REG_PONT_ICON = 'assets/poi/marker/ertekesitesipont.png';

  public static ELLENOR_PIROS_ICON = 'assets/image/map-icons/ellenorpiros.png';
  public static ELLENOR_SARGA_ICON = 'assets/image/map-icons/ellenorsarga.png';
  public static ELLENOR_ZOLD_ICON = 'assets/image/map-icons/ellenorzold.png';
  public static ELLENOR_KEK_ICON = 'assets/image/map-icons/ellenorkek.png';

  //horgaszturizmus
  public static BERBEADAS_POI = 'assets/poi/marker/berbeadas.svg';
  public static SZOLGALTATOIADATLAP_POI = 'assets/poi/marker/szolgaltatoiadatlap.svg';
  public static GUIDE_POI = 'assets/poi/marker/guide.svg';
  public static HORGASZBOLT_POI = 'assets/poi/marker/horgaszbolt.svg';
  public static HORGASZHELY_POI = 'assets/poi/marker/horgaszhely.svg';
  public static HORGASZTANYA_POI = 'assets/poi/marker/horgasztanya.svg';
  public static SOLYAPALYA = 'assets/poi/marker/solyapalya.svg';
  public static SZALLAS = 'assets/poi/marker/szallas.svg';
  public static VIZTERULET_POI = 'assets/poi/marker/vizterulet.svg';
  public static LOCATION_MARKER = 'assets/poi/marker/location.svg';

  public static KEDVENC_HORGASZHELY_POI = 'assets/poi/marker/kedvenc-horgaszhely.svg';

  public static POLYGON = 'assets/horgaszturizmus/terkep/polygon.png';

  public static DJP_ICON = 'https://www.google.com/maps/vt/icon/name=assets/icon/poi/tactile/pinlet_shadow-2-medium.png,assets/icon/poi/tactile/pinlet_outline_v2-2-medium.png,assets/icon/poi/tactile/pinlet-2-medium.png,assets/icon/poi/quantum/pinlet/fishing_pinlet-2-medium.png&highlight=ff00ff00,ffffff,aa00ff,ffffff&color=ff00ff00?scale=1';
  public static DJPTIPUS = 'djp';
  public static REGPONTTIPUS = 'regpont';
  public static REG_PONT_URL = 'assets/data/reg-pontok.json';
  public static DJP_PONT_URL = 'assets/data/djp.json';

  //0 kilométerkő
  public static DEFAULT_MAP_CENTER = new Leaflet.LatLng(47.49788681161061, 19.040247291941167);
  public static ZOOM_LEVEL_HUNGARY = 7;
  public static ZOOM_LEVEL_KOZELBEN = 10;

  public static DEFAULT_MAP_ZOOM = 14;
  public static DEFAULT_MAP_MAXZOOM = 22;
  public static DEFAULT_MAP_MINZOOM = 6;

  public static MAP_NATIVE_MAXZOOM = 19;

  public static DEFAULT_LAYER_RADIUS = 30000;

  public static OSM_CREDIT = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';
  public static OSM_URLTPL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

  // attributionControl: leveszi a ukrajna terkepet
  private static DEFAULT_MAP_OPTIONS: Leaflet.MapOptions = {
    attributionControl: false,
    preferCanvas: true,
    // zoomAnimation: false,
    // markerZoomAnimation: false,
    // fadeAnimation: false,
    zoom: TerkepConstants.DEFAULT_MAP_ZOOM,
    maxZoom: TerkepConstants.DEFAULT_MAP_MAXZOOM,
    minZoom: TerkepConstants.DEFAULT_MAP_MINZOOM,
    //center: TerkepConstants.DEFAULT_MAP_CENTER,
    layers: [
      Leaflet.tileLayer(TerkepConstants.OSM_URLTPL,{
        attribution: TerkepConstants.OSM_CREDIT,
        maxZoom: TerkepConstants.DEFAULT_MAP_MAXZOOM,
        maxNativeZoom: TerkepConstants.MAP_NATIVE_MAXZOOM
      })
    ]
  };

  private static iconUrlMap: Map<HorgaszturizmusPoiItem.TypeEnum, string> = new Map([
    [HorgaszturizmusPoiItem.TypeEnum.VIZTERULET, TerkepConstants.VIZTERULET_POI],
    [HorgaszturizmusPoiItem.TypeEnum.BERBEADAS, TerkepConstants.BERBEADAS_POI],
    [HorgaszturizmusPoiItem.TypeEnum.SZOLGALTATO, TerkepConstants.SZOLGALTATOIADATLAP_POI],
  ]);

  public static getPoiIconUrl(tipus: HorgaszturizmusPoiItem.TypeEnum): string {
    return TerkepConstants.iconUrlMap.get(tipus) ?? TerkepConstants.DJP_PONT_URL;
  }

  public static getDefaultOptions(): Leaflet.MapOptions {
    return cloneDeep(TerkepConstants.DEFAULT_MAP_OPTIONS);
  }

}
