<ion-header>
  <ion-toolbar color="secondary">
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/tabs/kedvenc-horgaszhelyek/terkep-nezet"></ion-back-button>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
    <ion-buttons slot="end" class="ion-margin-top">
      <mohosz-header-notification></mohosz-header-notification>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="moh-bg-light" *ngIf="form">
  <ion-grid>
    <ion-row>
      <ion-col size-md="6" offset-md="3" [style.background]="'white'">

        <form [formGroup]="form">

          <mat-error class="align-center">
            <span *ngIf="form.errors?.server">{{form.errors.server.message}}</span>
          </mat-error>

          <div>
            <mohosz-file-uploader
              #uploader
              [configuration]="fileUploaderConfig"
              [disabled]="!!form.controls.kepId.value"
              (uploadResult)="onSuccessUpload($event)">
            </mohosz-file-uploader>
          </div>

          <ng-container *ngIf="form.controls.kepId.value">
            <div class="photo-container">
              <ion-fab-button size="small" (click)="onEditPhotoClick()" class="fab-kep-szerkesztes">
                <ion-icon size="small" name="pencil"></ion-icon>
              </ion-fab-button>
              <ion-fab-button size="small" (click)="onDeletePhotoClick()" class="fab-kep-torles">
                <ion-icon size="small" name="trash"></ion-icon>
              </ion-fab-button>
              <div *ngIf="base64Content">
                <mohosz-base64-content [content]="base64Content"></mohosz-base64-content>
              </div>
            </div>
          </ng-container>

          <mat-error class="align-center">
            <mohosz-form-field-error-content [control]="form.controls.kepId"></mohosz-form-field-error-content>
          </mat-error>

          <ion-list>
            <ion-item class="mandatory-field">
              <ion-label position="stacked">Elnevezés</ion-label>
              <ion-input formControlName="elnevezes"></ion-input>
            </ion-item>
            <ion-note slot="error" color="danger">
              <mohosz-form-field-error-content [control]="form.controls.elnevezes"></mohosz-form-field-error-content>
            </ion-note>

            <ion-item class="mandatory-field">
              <ion-label position="stacked">Leírás</ion-label>
              <ion-textarea [autoGrow]="true" value="" formControlName="leiras"></ion-textarea>
            </ion-item>
            <ion-note slot="error" color="danger">
              <mohosz-form-field-error-content [control]="form.controls.leiras"></mohosz-form-field-error-content>
            </ion-note>
          </ion-list>

          <h4 #lokacio>Lokáció</h4>
          <p>Bökj a térképre, ahova szeretnéd elhelyezni a kedvenc horgászhelyedet.</p>

          <mohosz-kedvenc-horgaszhely-terkep-editor #kedvencHorgaszhelyMap
            [kedvencHorgaszhelyKoordinata]="form.get([KHLR('koordinata')]).value"
            [kozelbenPoiList]="kozelbenPoiList"
            (vizteruletKapcsolas)="onVizteruletKapcsolas($event)"
            (changeKoordinata)="onKoordinataChanged($event)"
          ></mohosz-kedvenc-horgaszhely-terkep-editor>

          <ion-grid class="koordinata" formGroupName="koordinata">
            <ion-row class="ion-align-items-center ion-justify-content-between">
              <ion-col>
                <ion-item class="mandatory-field">
                  <ion-label position="stacked">Északi szélesség</ion-label>
                  <ion-input type="number" debounce="300" formControlName="szelessegiFok"></ion-input>
                </ion-item>
                <ion-note slot="error" color="danger">
                  <mohosz-form-field-error-content [control]="form.controls.koordinata.get('szelessegiFok')"></mohosz-form-field-error-content>
                </ion-note>
              </ion-col>
              <ion-col>
                <ion-item class="mandatory-field">
                  <ion-label position="stacked">Keleti hosszúság</ion-label>
                  <ion-input type="number" debounce="300" formControlName="hosszusagiFok"></ion-input>
                </ion-item>
                <ion-note slot="error" color="danger">
                  <mohosz-form-field-error-content [control]="form.controls.koordinata.get('hosszusagiFok')"></mohosz-form-field-error-content>
                </ion-note>
              </ion-col>
              <ion-col size="auto">
                <ion-button fill="clear" color="danger" size="small" (click)="onKoordinataTorles()">
                  <ion-icon slot="icon-only" name="trash-outline" title="Törlés"></ion-icon>
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-item lines="none" class="has-ng-select">
            <ion-label position="stacked">Vízterület</ion-label>
            <div class="ng-select-field">
              <ng-select class="moh-ng-select"
                         formControlName="horgasztatasId"
                         [virtualScroll]="true"
                         [items]="horgasztatasok"
              ></ng-select>
            </div>
          </ion-item>
          <ion-note slot="error" color="danger">
            <mohosz-form-field-error-content [control]="form.controls.horgasztatasId"></mohosz-form-field-error-content>
          </ion-note>

        </form>

        <div class="ion-text-center ion-margin-top">
          <ng-container *ngIf="isLetrehozas; else modositasTpl">
            <ion-button (click)="onKedvencHorgaszhelyMentesClick()" [disabled]="form.invalid">
              Létrehozás
            </ion-button>
          </ng-container>
          <ng-template #modositasTpl>
            <ion-button fill="outline" color="danger" style="margin-right: 10px" (click)="onKedvencHorgaszhelyTorlesClick()">
              Törlés
            </ion-button>
            <ion-button [disabled]="form.invalid" (click)="onKedvencHorgaszhelyMentesClick()">
              Módosítás
            </ion-button>
          </ng-template>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
