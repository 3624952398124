<ion-header>
  <ion-toolbar color="secondary">
    <ion-title>Postafiók</ion-title>
    <ion-buttons slot="end" class="ion-margin-top">
      <mohosz-header-notification></mohosz-header-notification>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-tabs>
    <ion-tab-bar slot="top" selected-tab="bejovo-uzenetek">
      <ion-tab-button tab="bejovo-uzenetek">
        <ion-label>Bejövő üzeneteim {{kezbesitetlenUzenetekSzama ? '('+kezbesitetlenUzenetekSzama+')' : ''}}</ion-label>
      </ion-tab-button>
      <!--
      <ion-tab-button tab="kimeno-uzenetek">
        <ion-label>Kimenő üzeneteeim</ion-label>
      </ion-tab-button>
      -->
      <ion-tab-button>
        <ion-label></ion-label>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
</ion-content>
